import { IonCard, IonContent, IonPage } from "@ionic/react";
import React, { useGlobal } from "reactn";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link, Redirect } from "react-router-dom";

const SuccessfullOrder = () => {
  const [succesOrder, setSuccessOrder] = useGlobal("successOrder");

  return (
    <IonPage>
      <IonContent>
        {succesOrder ? (
          <div className="success-page">
            <IonCard className="success-card">
              <p className="sc-header">Thank you for purchasing our package</p>
              <div className="ion-text-center">
                <AiOutlineCheckCircle className="order-confirm-logo" />
              </div>
              <div className="mini-details-box ion-text-left">
                <p>
                  Order Id:<span>{succesOrder.orderId}</span>
                </p>
                <p>
                  Project Code:<span>{succesOrder.orderProjectCode}</span>
                </p>
                <p>
                  Order Status:
                  <span style={{ color: "#fff" }} className="offer-pill">
                    {succesOrder.orderStatus}
                  </span>
                </p>
              </div>
              <div className="track-btn-box ion-text-center">
                <Link
                  className="btn-track"
                  to={`/order/${succesOrder.orderId}`}
                >
                  TRACK STATUS
                </Link>
              </div>
            </IonCard>
          </div>
        ) : (
          <Redirect to="/" />
        )}
      </IonContent>
    </IonPage>
  );
};

export default SuccessfullOrder;

import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonHeader,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState, useGlobal } from "reactn";
import { ToastWarning } from "../../components/Toaster";
import { orderLists } from "./ApisUsers";
import moment from "moment";
import { Link } from "react-router-dom";
import { MdUpdate } from "react-icons/md";
const Orders = ({ menu }) => {
  const [userData, setUserData] = useGlobal("userData");
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrders = () => {
    const userId = userData.user._id;
    const token = userData.token;
    setLoading(true);

    orderLists({ userId, token }).then((data) => {
      if (data.error) {
        console.log(data.error);
        ToastWarning(data.error);
        setLoading(false);
      } else {
        setOrderList(data.order);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    if (userData) {
      getOrders();
    }
  }, []);
  return (
    <div className="order-list">
      {orderList && (
        <>
          {loading ? (
            <div className="ion-text-center">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <IonGrid>
              <hr
                style={{
                  height: "2px",
                  margin: "3px 10px",
                  background: "#b5b5b5",
                }}
              ></hr>

              <IonRow>
                {orderList.map((o, i) => (
                  <IonCol key={i} size={12}>
                    <IonCard className="o-d-card">
                      <IonCardHeader className="o-d-header">
                        <div className="o-d-flex">
                          <div>
                            <IonAvatar>
                              <img
                                src={o.purchasedPackage.packageImage.url}
                                alt={o.purchasedPackage.packageName}
                              />
                            </IonAvatar>
                          </div>

                          <div>
                            <p>{o.purchasedPackage.packageName}</p>
                            <div className="o-d-flex">
                              <div className="offer-pill">{o.status}</div>
                              <div className="variant-pill">
                                {o.variantSelected.plotSize} SQ.FT
                              </div>
                            </div>
                          </div>
                        </div>
                      </IonCardHeader>
                      <IonCardContent className="o-d-body">
                        <div className="o-d-inner">
                          <div className="o-d-details">
                            <p>
                              ORDER ID: <span>{o._id}</span>
                            </p>
                            <p>
                              PROJECT CODE: <span>{o.projectCode}</span>
                            </p>

                            <p>
                              ORDERED DATE:{" "}
                              <span>
                                {moment(o.createdAt).format("MMM Do YYYY")}
                              </span>
                            </p>
                          </div>
                        </div>

                        <div
                          style={{
                            marginTop: "8px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          className="ion-text-right"
                        >
                          <p
                            style={{
                              margin: "0",
                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            <span>
                              <MdUpdate
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "-4px",
                                  marginRight: "3px",
                                }}
                              />
                            </span>

                            {moment(o.updatedAt).startOf("hour").fromNow()}
                          </p>
                          <Link
                            className="btn-o-d-track"
                            to={`/order/${o._id}`}
                          >
                            TRACK LIVE
                          </Link>
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import React, { useState, useEffect, useGlobal, getGlobal } from "reactn";
import { ToastDanger, ToastWarning } from "../../components/Toaster";
import { orderById } from "./ApisUsers";
import { ImPhone } from "react-icons/im";
import { FaHistory } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BiBadgeCheck } from "react-icons/bi";
import {
  createAddonOrder,
  createAddons,
  createRazorpayRemaingOrder,
  paymentUpdate,
} from "./ApisUsers";
import moment from "moment";
import { RAZORPAY_KEY } from "../../Config";

const Razorpay = window.Razorpay;
const SingleOrder = (props) => {
  const [user, setUser] = useGlobal("userData");
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(false);
  const [paymentPart, setPaymentPart] = useGlobal("paymentPart");
  const initOrder = (orderId) => {
    const userId = user.user._id;
    const token = user.token;
    setLoading(true);
    orderById({ userId, token, orderId }).then((data) => {
      if (data.error) {
        ToastDanger(data.error, 4000);
        console.log(data.error);
        setLoading(false);
      } else {
        setOrder(data.order);
        setLoading(false);
      }
    });
  };

  const getOptions = ({ orderData, addonData }) => {
    const orderId = props.match.params.orderId;
    const userId = user.user._id;
    const token = user.token;
    const options = {
      key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: orderData.amount ? orderData.amount : 50000, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Floorplan Bazaar",
      description: addonData.packName,
      image:
        "https://res.cloudinary.com/djnv06fje/image/upload/v1598709680/Copy_of_Copy_of_A1_1_hvhw6m.png",
      order_id: orderData.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        const orderPaymentId = orderData.id;
        if (response.razorpay_payment_id) {
          setLoading(true);
          createAddons({
            addonData,
            userId,
            token,
            orderPaymentId,
            orderId,
          }).then((data) => {
            if (data.error) {
              ToastDanger(data.error);
              console.log(data.error);
              setLoading(false);
            } else {
              if (data.success) {
                setLoading(false);
                initOrder(orderId);
              }
            }
          });
        }
      },

      prefill: {
        name: user.user.fullName || "",
        email: user.user.email || "",
        contact: user.user.mobileNumber || "",
      },
      modal: {
        ondismiss: function () {
          setLoading(false);
        },
      },
      theme: {
        color: "#3399cc",
      },
    };
    return options;
  };

  const createAddonPayment = (addonData) => {
    const userId = user.user._id;
    const token = user.token;
    const amount = addonData.price;
    setLoading(true);
    createAddonOrder({ amount, userId, token }).then((data) => {
      if (data.error) {
        ToastDanger(data.error, 4000);
        setLoading(false);
      } else {
        const orderData = data.orderPayment;
        const payButton = new Razorpay(getOptions({ orderData, addonData }));
        payButton.open();
        setLoading(false);
      }
    });
  };

  const getPaymentData = ({ orderData, paymentData }) => {
    const orderId = props.match.params.orderId;
    const userId = user.user._id;
    const token = user.token;
    const options = {
      key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: orderData.amount ? orderData.amount : 50000, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Floorplan Bazaar",
      description: paymentData.paymentName,

      image:
        "https://res.cloudinary.com/djnv06fje/image/upload/v1598709680/Copy_of_Copy_of_A1_1_hvhw6m.png",
      order_id: orderData.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        const orderPaymentId = orderData.id;
        if (response.razorpay_payment_id) {
          const paymentId = response.razorpay_payment_id;
          paymentUpdate({
            paymentData,
            userId,
            token,
            orderPaymentId,
            orderId,
            paymentId,
          }).then((data) => {
            if (data.error) {
              console.log(data.error);
            } else {
              initOrder(orderId);
            }
          });
        }
      },

      prefill: {
        name: user.user.fullName || "",
        email: user.user.email || "",
        contact: user.user.mobileNumber || "",
      },
      modal: {
        ondismiss: function () {
          setLoading(false);
        },
      },
      theme: {
        color: "#3399cc",
      },
    };
    return options;
  };

  const partPaymentHandler = (data) => async (event) => {
    const userId = user.user._id;
    const token = user.token;
    const amount = data.amount;
    setPaymentPart(data);
    const getPaymentOrder = await createRazorpayRemaingOrder({
      amount,
      userId,
      token,
    });
    if (getPaymentOrder.success) {
      const orderData = getPaymentOrder.orderPayment;
      const paymentData = getGlobal().paymentPart;
      const payButton = new Razorpay(
        getPaymentData({ orderData, paymentData })
      );
      payButton.open();
    } else {
      ToastDanger("Please try again", 4000);
    }
  };

  useEffect(() => {
    async function fetchOrderId() {
      const orderId = await props.match.params.orderId;
      return orderId;
    }
    fetchOrderId().then((data) => {
      initOrder(data);
    });
  }, []);

  const firstBlockCard = (order) => {
    return (
      <IonCard className="s-o-cards">
        <IonCardHeader color="primary">
          <IonCardTitle>ORDER DETAILS</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <div className="s-o-body">
            <div className="s-o-body-flex">
              <p>Purchased Package:</p>
              <p>{order.purchasedPackage.packageName}</p>
            </div>

            <div className="s-o-body-flex">
              <p>Plot Size:</p>
              <p>{order.variantSelected.plotSize} SQ.FT</p>
            </div>
            <div className="s-o-body-flex">
              <p> Numbers of Floors:</p>
              <p>{order.variantSelected.floorCount}</p>
            </div>

            <div className="s-o-body-flex">
              <p>Project Code:</p>
              <p>{order.projectCode}</p>
            </div>
            <div className="s-o-body-flex">
              <p>Order Updated:</p>
              <p>{moment(order.updatedAt).calendar()}</p>
            </div>

            <div className="project-manager-box">
              <p className="project-manager-quote">
                <q>
                  Project Manager assigned to you will be the point of contact
                  for all conversations & discussions, at all stages of your
                  project. Feel free to Call or WhatsApp him for conveying your
                  messages
                </q>
              </p>
              <div className="project-manager-name">
                <div className="back-logo">
                  <img
                    width="90"
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1613056422/famous_1_zcqsqq.png"
                    alt=" "
                  />
                </div>
                <p>Senior Engineer at Floorplan Bazaar</p>
                <h5>Er.{order.projectManger.projectManager}</h5>
                <h6>
                  <a href={`tel:${order.projectManger.projectManagerNumber}`}>
                    <ImPhone
                      style={{ marginBottom: "-3px", fontSize: "18px" }}
                    />{" "}
                    +91- {order.projectManger.projectManagerNumber}
                  </a>
                </h6>
              </div>
            </div>

            <div className="assigned-box">
              <div className="s-o-body-flex border-flex">
                <p>Project Architect:</p>
                <p>
                  {order.assignement.projectArchitect
                    ? order.assignement.projectArchitect
                    : "Not assigned yet"}
                </p>
              </div>
              {order.purchasedPackage.paymentType === "only-1" ? (
                ""
              ) : (
                <div className="s-o-body-flex border-flex">
                  <p>Project Engineer:</p>
                  <p>
                    {order.assignement.projectEngineer
                      ? order.assignement.projectEngineer
                      : "Not assigned yet"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </IonCardContent>
      </IonCard>
    );
  };

  const secondBlockCard = (order) => {
    if (order.purchasedPackage.paymentType === "only-4") {
      return (
        <div className="s-o-second-block ion-text-center">
          <h4>LIVE TRACKER</h4>
          <div className="order-status-block">
            {order.orderStatus.cancelled ? (
              <>
                <div
                  style={{ position: "relative", border: "2px solid #eb445a" }}
                  className="status-box"
                >
                  <div style={{ color: "#eb445a" }} className="status-header">
                    ORDER HAS BEEN CANCELLED
                  </div>
                  <p>
                    Your order has been cancelled. For refund or any payment
                    related query. Please contact with your project manager for
                    further steps.
                  </p>
                </div>
              </>
            ) : (
              <>
                {order.orderStatus.orderConfirmed &&
                order.firstPayment.paymentStatus ? (
                  <div className="order-status-text">
                    <p>
                      We have just received the payment of ₹
                      {order.firstPayment.amount}.
                    </p>

                    <p>
                      We will soon assign you a Architect for making your
                      architecture floor plans. Once a architect is assigned,
                      your work will start as per schedule.
                    </p>

                    <p>
                      You will soon get a phone call from your project manager
                      for details regarding your plot dimensions, orientation
                      and your design requirements.
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.engineerAssigned ? (
                  <div style={{ position: "relative" }} className="status-box">
                    <div style={{ zIndex: "0" }} className="back-logo">
                      <img
                        width="90"
                        src="https://res.cloudinary.com/djnv06fje/image/upload/v1613056423/worker_qu1hp8.png"
                        alt=" "
                      />
                    </div>
                    <div className="status-header">ARCHITECT ASSIGNED</div>
                    <p>
                      Ar. {order.assignement.projectArchitect} (B.Arch)
                      <br /> 05 years experience in the field of architecture
                      planning &amp; designing of residential projects.
                      <br /> We will notify you once the designs are ready.
                    </p>
                  </div>
                ) : (
                  <div className="status-pending">
                    <div className="status-box-flex">
                      <div>
                        <h5>ARCHITECT ASSIGNED</h5>
                      </div>
                      <div>
                        <p>PENDING</p>
                      </div>
                      <div className="status-logo">
                        <FaHistory />
                      </div>
                    </div>
                  </div>
                )}

                {order.orderStatus.floorPlanReady ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>FLOOR PLAN</h5>
                      </div>
                      <div>
                        <p>READY</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>FLOOR PLAN FINALIZED</h5>
                      </div>
                      <div>
                        <p>CONFIRMED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                !order.secondPayment.paymentStatus ? (
                  <div className="status-pending">
                    <div className="status-box-flex">
                      <div>
                        <h5>ENGINEER ASSIGNED</h5>
                      </div>
                      <div>
                        <p>PAYMENT PENDING</p>
                      </div>
                      <div className="status-logo">
                        <FaHistory />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus ? (
                  <div style={{ position: "relative" }} className="status-box">
                    <div className="status-header">ENGINEER ASSIGNED</div>
                    <p>
                      Er. {order.assignement.projectEngineer} (B.Tech) 05 years
                      experience in the field of building construction and
                      structure planning of residential projects.
                      <br />
                      You can download the project file pdf down below using the
                      download button. We will notify you once the file is
                      ready.
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileWorkStarted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>PROJECT FILE</h5>
                      </div>
                      <div>
                        <p>WORK STARTED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileWorkStarted &&
                order.orderStatus.projectFileCompleted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>PROJECT FILE</h5>
                      </div>
                      <div>
                        <p>COMPLETED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileWorkStarted &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkStarted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>3D WORK</h5>
                      </div>
                      <div>
                        <p>STARTED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkStarted &&
                order.orderStatus.threeDworkCompleted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>3D WORK</h5>
                      </div>
                      <div>
                        <p>COMPLETED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkCompleted &&
                !order.thirdPayment.paymentStatus ? (
                  <div className="status-pending">
                    <div className="status-box-flex">
                      <div>
                        <h5>3D INTERIOR DESIGNS</h5>
                      </div>
                      <div>
                        <p>PAYMENT PENDING</p>
                      </div>
                      <div className="status-logo">
                        <FaHistory />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.interiorWorkStarted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>3D INTERIOR DESIGNS</h5>
                      </div>
                      <div>
                        <p>STARTED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.interiorWorkCompleted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>3D INTERIOR DESIGNS</h5>
                      </div>
                      <div>
                        <p>COMPLETED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.interiorWorkCompleted &&
                !order.fourthPayment.paymentStatus ? (
                  <div className="status-pending">
                    <div className="status-box-flex">
                      <div>
                        <h5>WALK THRU</h5>
                      </div>
                      <div>
                        <p>PAYMENT PENDING</p>
                      </div>
                      <div className="status-logo">
                        <FaHistory />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.interiorWorkCompleted &&
                order.fourthPayment.paymentStatus &&
                order.orderStatus.walkThruStarted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>WALK THRU</h5>
                      </div>
                      <div>
                        <p>STARTED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.interiorWorkCompleted &&
                order.fourthPayment.paymentStatus &&
                order.orderStatus.walkThruStarted &&
                order.orderStatus.walkThruCompleted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>WALK THRU</h5>
                      </div>
                      <div>
                        <p>COMPLETED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.orderStatus.threeDworkCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.interiorWorkCompleted &&
                order.fourthPayment.paymentStatus &&
                order.orderStatus.walkThruStarted &&
                order.orderStatus.walkThruCompleted &&
                order.orderStatus.projectCompleted ? (
                  <div style={{ position: "relative" }} className="status-box">
                    <div className="status-header">PROJECT COMPLETED</div>
                    <p>
                      Congratulation, your project has been completed by us.
                    </p>
                    <img
                      width="90"
                      src="https://res.cloudinary.com/djnv06fje/image/upload/v1613056423/rate_wa26ig.png"
                      alt=""
                    />
                    <p>Now, you can download the final project file.</p>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      );
    } else if (order.purchasedPackage.paymentType === "only-3") {
      return (
        <div className="s-o-second-block ion-text-center">
          <h4>LIVE TRACKER</h4>
          <div className="order-status-block">
            {order.orderStatus.cancelled ? (
              <>
                <div
                  style={{ position: "relative", border: "2px solid #eb445a" }}
                  className="status-box"
                >
                  <div style={{ color: "#eb445a" }} className="status-header">
                    ORDER HAS BEEN CANCELLED
                  </div>
                  <p>
                    Your order has been cancelled. For refund or any payment
                    related query. Please contact with your project manager for
                    further steps.
                  </p>
                </div>
              </>
            ) : (
              <>
                {order.orderStatus.orderConfirmed &&
                order.firstPayment.paymentStatus ? (
                  <div className="order-status-text">
                    <p>
                      We have just received the payment of ₹
                      {order.firstPayment.amount}.
                    </p>

                    <p>
                      We will soon assign you a Architect for making your
                      architecture floor plans. Once a architect is assigned,
                      your work will start as per schedule.
                    </p>

                    <p>
                      You will soon get a phone call from your project manager
                      for details regarding your plot dimensions, orientation
                      and your design requirements.
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.engineerAssigned ? (
                  <div style={{ position: "relative" }} className="status-box">
                    <div style={{ zIndex: "0" }} className="back-logo">
                      <img
                        width="90"
                        src="https://res.cloudinary.com/djnv06fje/image/upload/v1613056423/worker_qu1hp8.png"
                        alt=" "
                      />
                    </div>
                    <div className="status-header">ARCHITECT ASSIGNED</div>
                    <p>
                      Ar. {order.assignement.projectArchitect} (B.Arch)
                      <br /> 05 years experience in the field of architecture
                      planning &amp; designing of residential projects.
                      <br /> We will notify you once the designs are ready.
                    </p>
                  </div>
                ) : (
                  <div className="status-pending">
                    <div className="status-box-flex">
                      <div>
                        <h5>ARCHITECT ASSIGNED</h5>
                      </div>
                      <div>
                        <p>PENDING</p>
                      </div>
                      <div className="status-logo">
                        <FaHistory />
                      </div>
                    </div>
                  </div>
                )}

                {order.orderStatus.floorPlanReady ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>FLOOR PLAN</h5>
                      </div>
                      <div>
                        <p>READY</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>FLOOR PLAN FINALIZED</h5>
                      </div>
                      <div>
                        <p>CONFIRMED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                !order.secondPayment.paymentStatus ? (
                  <div className="status-pending">
                    <div className="status-box-flex">
                      <div>
                        <h5>ENGINEER ASSIGNED</h5>
                      </div>
                      <div>
                        <p>PAYMENT PENDING</p>
                      </div>
                      <div className="status-logo">
                        <FaHistory />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus ? (
                  <div style={{ position: "relative" }} className="status-box">
                    <div className="status-header">ENGINEER ASSIGNED</div>
                    <p>
                      Er. {order.assignement.projectEngineer} (B.Tech) 05 years
                      experience in the field of building construction and
                      structure planning of residential projects.
                      <br />
                      You can download the project file pdf down below using the
                      download button. We will notify you once the file is
                      ready.
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileWorkStarted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>PROJECT FILE</h5>
                      </div>
                      <div>
                        <p>WORK STARTED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>PROJECT FILE</h5>
                      </div>
                      <div>
                        <p>COMPLETED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* PENDING PAYEMNT */}

                {order.orderStatus.floorPlanFinalized &&
                order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                !order.thirdPayment.paymentStatus ? (
                  <div className="status-pending">
                    <div className="status-box-flex">
                      <div>
                        <h5>3D WORK</h5>
                      </div>
                      <div>
                        <p>PAYMENT PENDING</p>
                      </div>
                      <div className="status-logo">
                        <FaHistory />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.threeDworkStarted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>3D WORK</h5>
                      </div>
                      <div>
                        <p>STARTED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.threeDworkStarted &&
                order.orderStatus.threeDworkCompleted ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>3D WORK</h5>
                      </div>
                      <div>
                        <p>COMPLETED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {order.secondPayment.paymentStatus &&
                order.orderStatus.projectFileCompleted &&
                order.thirdPayment.paymentStatus &&
                order.orderStatus.threeDworkCompleted &&
                order.orderStatus.projectCompleted ? (
                  <div style={{ position: "relative" }} className="status-box">
                    <div className="status-header">PROJECT COMPLETED</div>
                    <p>
                      Congratulation, your project has been completed by us.
                    </p>
                    <img
                      width="90"
                      src="https://res.cloudinary.com/djnv06fje/image/upload/v1613056423/rate_wa26ig.png"
                      alt=""
                    />
                    <p>Now, you can download the final project file.</p>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      );
    } else if (order.purchasedPackage.paymentType === "only-1") {
      return (
        <div className="s-o-second-block ion-text-center">
          <h4>LIVE TRACKER</h4>
          <div className="order-status-block">
            {order.orderStatus.cancelled ? (
              <>
                <div
                  style={{ position: "relative", border: "2px solid #eb445a" }}
                  className="status-box"
                >
                  <div style={{ color: "#eb445a" }} className="status-header">
                    ORDER HAS BEEN CANCELLED
                  </div>
                  <p>
                    Your order has been cancelled. For refund or any payment
                    related query. Please contact with your project manager for
                    further steps.
                  </p>
                </div>
              </>
            ) : (
              <>
                {order.orderStatus.orderConfirmed &&
                order.firstPayment.paymentStatus ? (
                  <div className="order-status-text">
                    <p>
                      We have just received the payment of ₹
                      {order.firstPayment.amount}.
                    </p>

                    <p>
                      We will soon assign you a Architect for making your
                      architecture floor plans. Once a architect is assigned,
                      your work will start as per schedule.
                    </p>

                    <p>
                      You will soon get a phone call from your project manager
                      for details regarding your plot dimensions, orientation
                      and your design requirements.
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.engineerAssigned ? (
                  <div style={{ position: "relative" }} className="status-box">
                    <div style={{ zIndex: "0" }} className="back-logo">
                      <img
                        width="90"
                        src="https://res.cloudinary.com/djnv06fje/image/upload/v1613056423/worker_qu1hp8.png"
                        alt=" "
                      />
                    </div>
                    <div className="status-header">ARCHITECT ASSIGNED</div>
                    <p>
                      Ar. {order.assignement.projectArchitect} (B.Arch)
                      <br /> 05 years experience in the field of architecture
                      planning &amp; designing of residential projects.
                      <br /> We will notify you once the designs are ready.
                    </p>
                  </div>
                ) : (
                  <div className="status-pending">
                    <div className="status-box-flex">
                      <div>
                        <h5>ARCHITECT ASSIGNED</h5>
                      </div>
                      <div>
                        <p>PENDING</p>
                      </div>
                      <div className="status-logo">
                        <FaHistory />
                      </div>
                    </div>
                  </div>
                )}

                {order.orderStatus.floorPlanReady ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>FLOOR PLAN</h5>
                      </div>
                      <div>
                        <p>READY</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized ? (
                  <div className="status-box">
                    <div className="status-box-flex">
                      <div>
                        <h5>FLOOR PLAN FINALIZED</h5>
                      </div>
                      <div>
                        <p>CONFIRMED</p>
                      </div>
                      <div className="status-logo-success">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {order.orderStatus.floorPlanFinalized &&
                order.orderStatus.projectCompleted ? (
                  <div style={{ position: "relative" }} className="status-box">
                    <div className="status-header">PROJECT COMPLETED</div>
                    <p>
                      Congratulation, your project has been completed by us.
                    </p>
                    <img
                      width="90"
                      src="https://res.cloudinary.com/djnv06fje/image/upload/v1613056423/rate_wa26ig.png"
                      alt=""
                    />
                    <p>Now, you can download the final project file.</p>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      );
    }
  };

  const thirdBlockCard = (order) => {
    if (
      order.purchasedPackage.paymentType === "only-4" ||
      order.purchasedPackage.paymentType === "only-3"
    ) {
      return (
        <IonCard className="s-o-cards">
          <IonCardHeader color="primary">
            <IonCardTitle>FILES DOWNLOAD</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div className="s-o-body">
              <div style={{ marginBottom: "5px" }} className="s-o-body-flex">
                <p>FLOOR PLAN FILE:</p>

                <a
                  className={
                    order.orderStatus.floorPlanReady
                      ? "btn-files-download"
                      : "btn-files-download-dis"
                  }
                  target="_blank"
                  href={
                    order.orderStatus.floorPlanReady &&
                    order.filesList.floorPlanFiles !== "null"
                      ? order.filesList.floorPlanFiles
                      : null
                  }
                >
                  CHECK FILES
                </a>
              </div>
              <div style={{ marginBottom: "5px" }} className="s-o-body-flex">
                <p>Project File:</p>
                <a
                  className={
                    order.orderStatus.projectFileWorkStarted
                      ? "btn-files-download"
                      : "btn-files-download-dis"
                  }
                  target="_blank"
                  href={
                    order.orderStatus.projectFileWorkStarted &&
                    order.filesList.projectFile !== "null"
                      ? order.filesList.projectFile
                      : null
                  }
                >
                  CHECK FILES
                </a>
              </div>
              <div style={{ marginBottom: "5px" }} className="s-o-body-flex">
                <p>3D WORK FILES:</p>
                <a
                  className={
                    order.orderStatus.threeDworkStarted
                      ? "btn-files-download"
                      : "btn-files-download-dis"
                  }
                  target="_blank"
                  href={
                    order.orderStatus.threeDworkStarted &&
                    order.filesList.threeDElevation !== "null"
                      ? order.filesList.threeDElevation
                      : null
                  }
                >
                  CHECK FILES
                </a>
              </div>

              <div className="s-o-body-flex">
                <p>COMPLETED PROJECT FILES:</p>
                <a
                  className={
                    order.orderStatus.projectCompleted
                      ? "btn-files-download"
                      : "btn-files-download-dis"
                  }
                  target="_blank"
                  href={
                    order.orderStatus.projectCompleted &&
                    order.filesList.completedProjectFile !== "null"
                      ? order.filesList.completedProjectFile
                      : null
                  }
                >
                  CHECK FILES
                </a>
              </div>

              {order.orderStatus.floorPlanReady ? (
                <div className="small-box-updated-file-count">
                  {order.orderStatus.floorPlanReady ? (
                    <div
                      style={{ marginBottom: "2px" }}
                      className="s-o-body-flex"
                    >
                      <p style={{ fontSize: "11px" }}>
                        <span>
                          <AiOutlineInfoCircle
                            style={{
                              color: "#f5aa47",
                              marginRight: "3px",
                              fontSize: "15px",
                              marginBottom: "-3px",
                            }}
                          />
                        </span>
                        FLOOR PLANS UPDATED:
                      </p>
                      <p style={{ fontSize: "11px" }}>
                        {order.floorPlanUpdateLimit} Times
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {order.orderStatus.threeDworkStarted ? (
                    <div
                      style={{ marginBottom: "2px" }}
                      className="s-o-body-flex"
                    >
                      <p style={{ fontSize: "11px" }}>
                        <span>
                          <AiOutlineInfoCircle
                            style={{
                              color: "#f5aa47",
                              marginRight: "3px",
                              fontSize: "15px",
                              marginBottom: "-3px",
                            }}
                          />
                        </span>
                        front Elevation UPDATED:
                      </p>
                      <p style={{ fontSize: "11px" }}>
                        {order.frontElevationUpdateLimit} Times
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {order.orderStatus.interiorWorkStarted ? (
                    <div
                      style={{ marginBottom: "2px" }}
                      className="s-o-body-flex"
                    >
                      <p style={{ fontSize: "11px" }}>
                        <span>
                          <AiOutlineInfoCircle
                            style={{
                              color: "#f5aa47",
                              marginRight: "3px",
                              fontSize: "15px",
                              marginBottom: "-3px",
                            }}
                          />
                        </span>
                        interior Designs UPDATED:
                      </p>
                      <p style={{ fontSize: "11px" }}>
                        {order.interiorDesignsUpdateLimit} Times
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </IonCardContent>
        </IonCard>
      );
    } else if (order.purchasedPackage.paymentType === "only-1") {
      return (
        <IonCard className="s-o-cards">
          <IonCardHeader color="primary">
            <IonCardTitle>FILES DOWNLOAD</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div className="s-o-body">
              <div style={{ marginBottom: "5px" }} className="s-o-body-flex">
                <p>FLOOR PLAN FILE:</p>

                <a
                  className={
                    order.orderStatus.floorPlanReady
                      ? "btn-files-download"
                      : "btn-files-download-dis"
                  }
                  target="_blank"
                  href={
                    order.orderStatus.floorPlanReady &&
                    order.filesList.floorPlanFiles !== "null"
                      ? order.filesList.floorPlanFiles
                      : null
                  }
                >
                  CHECK FILES
                </a>
              </div>

              <div className="s-o-body-flex">
                <p>COMPLETED PROJECT FILES:</p>
                <a
                  className={
                    order.orderStatus.projectCompleted
                      ? "btn-files-download"
                      : "btn-files-download-dis"
                  }
                  target="_blank"
                  href={
                    order.orderStatus.projectCompleted &&
                    order.filesList.completedProjectFile !== "null"
                      ? order.filesList.completedProjectFile
                      : null
                  }
                >
                  CHECK FILES
                </a>
              </div>

              <div className="small-box-updated-file-count">
                {order.orderStatus.floorPlanReady ? (
                  <div
                    style={{ marginBottom: "2px" }}
                    className="s-o-body-flex"
                  >
                    <p style={{ fontSize: "11px" }}>
                      <span>
                        <AiOutlineInfoCircle
                          style={{
                            color: "#f5aa47",
                            marginRight: "3px",
                            fontSize: "15px",
                            marginBottom: "-3px",
                          }}
                        />
                      </span>
                      FLOOR PLANS UPDATED:
                    </p>
                    <p style={{ fontSize: "11px" }}>
                      {order.floorPlanUpdateLimit} Times
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      );
    }
  };

  const fourthBlockCard = () => {
    if (order.purchasedPackage.paymentType === "only-4") {
      return (
        <IonCard className="s-o-cards">
          <IonCardHeader color="primary">
            <IonCardTitle>PAYMENT SHEDULES</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div className="s-o-body">
              <div
                className={
                  order.firstPayment.paymentStatus
                    ? "payment-flex-success"
                    : "payment-flex"
                }
              >
                <p>
                  FIRST PAYMENT <sup>(15%)</sup>
                </p>
                <div>
                  {order.firstPayment.paymentStatus ? (
                    <div className="payment-status-success">Successfull</div>
                  ) : (
                    <div className="payment-status-pending">Pending</div>
                  )}
                </div>

                <div>
                  {order.firstPayment.paymentStatus ? (
                    <BiBadgeCheck className="payment-succes-icon" />
                  ) : (
                    <button className="btn-pay-payment">
                      PAY NOW <span>(₹{order.firstPayment.amount})</span>
                    </button>
                  )}
                </div>
              </div>

              <div
                className={
                  order.secondPayment.paymentStatus
                    ? "payment-flex-success"
                    : "payment-flex"
                }
              >
                <p>
                  SECOND PAYMENT <sup>(30%)</sup>
                </p>
                <div>
                  {order.secondPayment.paymentStatus ? (
                    <div className="payment-status-success">Successfull</div>
                  ) : (
                    <div className="payment-status-pending">Pending</div>
                  )}
                </div>

                <div>
                  {order.secondPayment.paymentStatus ? (
                    <BiBadgeCheck className="payment-succes-icon" />
                  ) : (
                    <button
                      onClick={partPaymentHandler({
                        paymentCode: "LP-2",
                        paymentName: "Second Payment",
                        amount: order.secondPayment.amount,
                      })}
                      className="btn-pay-payment"
                    >
                      PAY NOW <span>(₹{order.secondPayment.amount})</span>
                    </button>
                  )}
                </div>
              </div>

              <div
                className={
                  order.thirdPayment.paymentStatus
                    ? "payment-flex-success"
                    : "payment-flex"
                }
              >
                <p>
                  THIRD PAYMENT <sup>(40%)</sup>
                </p>
                <div>
                  {order.thirdPayment.paymentStatus ? (
                    <div className="payment-status-success">Successfull</div>
                  ) : (
                    <div className="payment-status-pending">Pending</div>
                  )}
                </div>

                <div>
                  {order.thirdPayment.paymentStatus ? (
                    <BiBadgeCheck className="payment-succes-icon" />
                  ) : (
                    <button
                      onClick={partPaymentHandler({
                        paymentCode: "LP-3",
                        paymentName: "Third Payment",
                        amount: order.thirdPayment.amount,
                      })}
                      className="btn-pay-payment"
                    >
                      PAY NOW <span>(₹{order.thirdPayment.amount})</span>
                    </button>
                  )}
                </div>
              </div>

              <div
                className={
                  order.fourthPayment.paymentStatus
                    ? "payment-flex-success"
                    : "payment-flex"
                }
              >
                <p>
                  FOURTH PAYMENT <sup>(25%)</sup>
                </p>
                <div>
                  {order.fourthPayment.paymentStatus ? (
                    <div className="payment-status-success">Successfull</div>
                  ) : (
                    <div className="payment-status-pending">Pending</div>
                  )}
                </div>

                <div>
                  {order.fourthPayment.paymentStatus ? (
                    <BiBadgeCheck className="payment-succes-icon" />
                  ) : (
                    <button
                      onClick={partPaymentHandler({
                        paymentCode: "LP-4",
                        paymentName: "Fourth Payment",
                        amount: order.fourthPayment.amount,
                      })}
                      className="btn-pay-payment"
                    >
                      PAY NOW <span>(₹{order.fourthPayment.amount})</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      );
    } else if (order.purchasedPackage.paymentType === "only-3") {
      return (
        <IonCard className="s-o-cards">
          <IonCardHeader color="primary">
            <IonCardTitle>PAYMENT SHEDULES</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div className="s-o-body">
              <div
                className={
                  order.firstPayment.paymentStatus
                    ? "payment-flex-success"
                    : "payment-flex"
                }
              >
                <p>
                  FIRST PAYMENT <sup>(25%)</sup>
                </p>
                <div>
                  {order.firstPayment.paymentStatus ? (
                    <div className="payment-status-success">Successfull</div>
                  ) : (
                    <div className="payment-status-pending">Pending</div>
                  )}
                </div>

                <div>
                  {order.firstPayment.paymentStatus ? (
                    <BiBadgeCheck className="payment-succes-icon" />
                  ) : (
                    <button className="btn-pay-payment">
                      PAY NOW <span>(₹{order.firstPayment.amount})</span>
                    </button>
                  )}
                </div>
              </div>

              <div
                className={
                  order.secondPayment.paymentStatus
                    ? "payment-flex-success"
                    : "payment-flex"
                }
              >
                <p>
                  SECOND PAYMENT <sup>(60%)</sup>
                </p>
                <div>
                  {order.secondPayment.paymentStatus ? (
                    <div className="payment-status-success">Successfull</div>
                  ) : (
                    <div className="payment-status-pending">Pending</div>
                  )}
                </div>

                <div>
                  {order.secondPayment.paymentStatus ? (
                    <BiBadgeCheck className="payment-succes-icon" />
                  ) : (
                    <button
                      onClick={partPaymentHandler({
                        paymentCode: "MP-2",
                        paymentName: "Second Payment",
                        amount: order.secondPayment.amount,
                      })}
                      className="btn-pay-payment"
                    >
                      PAY NOW <span>(₹{order.secondPayment.amount})</span>
                    </button>
                  )}
                </div>
              </div>

              <div
                className={
                  order.thirdPayment.paymentStatus
                    ? "payment-flex-success"
                    : "payment-flex"
                }
              >
                <p>
                  THIRD PAYMENT <sup>(15%)</sup>
                </p>
                <div>
                  {order.thirdPayment.paymentStatus ? (
                    <div className="payment-status-success">Successfull</div>
                  ) : (
                    <div className="payment-status-pending">Pending</div>
                  )}
                </div>

                <div>
                  {order.thirdPayment.paymentStatus ? (
                    <BiBadgeCheck className="payment-succes-icon" />
                  ) : (
                    <button
                      onClick={partPaymentHandler({
                        paymentCode: "MP-3",
                        paymentName: "Third Payment",
                        amount: order.thirdPayment.amount,
                      })}
                      className="btn-pay-payment"
                    >
                      PAY NOW <span>(₹{order.thirdPayment.amount})</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      );
    } else if (order.purchasedPackage.paymentType === "only-1") {
      return (
        <IonCard className="s-o-cards">
          <IonCardHeader color="primary">
            <IonCardTitle>PAYMENT SHEDULES</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div className="s-o-body">
              <div
                className={
                  order.firstPayment.paymentStatus
                    ? "payment-flex-success"
                    : "payment-flex"
                }
              >
                <p>
                  FULL PAYMENT <sup>(100%)</sup>
                </p>
                <div>
                  {order.firstPayment.paymentStatus ? (
                    <div className="payment-status-success">Successfull</div>
                  ) : (
                    <div className="payment-status-pending">Pending</div>
                  )}
                </div>

                <div>
                  {order.firstPayment.paymentStatus ? (
                    <BiBadgeCheck className="payment-succes-icon" />
                  ) : (
                    <button className="btn-pay-payment">
                      PAY NOW <span>(₹{order.firstPayment.amount})</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      );
    }
  };

  const fivethBlockCard = (order) => {
    if (order.purchasedPackage.paymentType === "only-4") {
      return (
        <div className="addon-pack-box">
          {order.purchasedPack.status ? (
            <div className="purchased-packs-box">
              <div className="purchased-pack-header">ACTIVE ADDON PACKS</div>
              <div className="active-pack-box">
                <div className="pack-head">
                  <p>{order.purchasedPack.activePack[0].packName}</p>
                </div>
                <div className="active-pack-flex">
                  <div>
                    <p>{order.purchasedPack.activePack[0].title}</p>
                  </div>
                  <div className="payment-status-success">ACTIVE</div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="addon-header">
            <h4>ADDON PACKS</h4>
          </div>
          <div className="addon-inner-box">
            <div className="addon-sub-header">
              <h5>Exterior 3D Elevation Pack</h5>
            </div>
            <IonGrid style={{ padding: "0" }}>
              <IonRow>
                {order.addOnPacks.exterior3DElevationPack.map((ex, i) => (
                  <>
                    <IonCol key={i} size={12}>
                      <div className="addon-flex">
                        <div className="addon-left-side">
                          <p>{ex.title}</p>
                        </div>

                        <div>
                          <button
                            onClick={(e) => createAddonPayment(ex)}
                            className="btn-pay-payment-addon"
                          >
                            BUY NOW (₹{ex.price})
                          </button>
                        </div>
                      </div>
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </IonGrid>
          </div>

          <div className="addon-inner-box">
            <div className="addon-sub-header">
              <h5>Interior 3D Visualization Pack</h5>
            </div>
            <IonGrid style={{ padding: "0" }}>
              <IonRow>
                {order.addOnPacks.interior3DVisualizationPack.map((ex, i) => (
                  <>
                    <IonCol key={i} size={12}>
                      <div className="addon-flex">
                        <div className="addon-left-side">
                          <p>{ex.title}</p>
                        </div>

                        <div>
                          <button
                            onClick={(e) => createAddonPayment(ex)}
                            className="btn-pay-payment-addon"
                          >
                            BUY NOW (₹{ex.price})
                          </button>
                        </div>
                      </div>
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </IonGrid>
          </div>
        </div>
      );
    } else if (order.purchasedPackage.paymentType === "only-3") {
      return (
        <div className="addon-pack-box">
          {order.purchasedPack.status ? (
            <div className="purchased-packs-box">
              <div className="purchased-pack-header">ACTIVE ADDON PACKS</div>
              <div className="active-pack-box">
                <div className="pack-head">
                  <p>{order.purchasedPack.activePack[0].packName}</p>
                </div>
                <div className="active-pack-flex">
                  <div>
                    <p>{order.purchasedPack.activePack[0].title}</p>
                  </div>
                  <div className="payment-status-success">ACTIVE</div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="addon-header">
            <h4>ADDON PACKS</h4>
          </div>
          <div className="addon-inner-box">
            <div className="addon-sub-header">
              <h5>Exterior 3D Elevation Pack</h5>
            </div>
            <IonGrid style={{ padding: "0" }}>
              <IonRow>
                {order.addOnPacks.exterior3DElevationPack.map((ex, i) => (
                  <>
                    <IonCol key={i} size={12}>
                      <div className="addon-flex">
                        <div className="addon-left-side">
                          <p>{ex.title}</p>
                        </div>

                        <div>
                          <button className="btn-pay-payment-addon">
                            BUY NOW (₹{ex.price})
                          </button>
                        </div>
                      </div>
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </IonGrid>
          </div>
        </div>
      );
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{ marginTop: "0px" }} className="header">
          <IonButtons slot="start">
            <IonBackButton color="dark" defaultHref="/" />
          </IonButtons>
          <IonTitle>ORDER TRACKER</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <IonLoading
            cssClass="my-custom-class"
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={"Loading...."}
          />
        ) : (
          <>
            {order && (
              <div className="s-o-main-box">
                {firstBlockCard(order)}
                {secondBlockCard(order)}
                {thirdBlockCard(order)}
                {fourthBlockCard(order)}
                <div className="orders-divder"></div>
                {fivethBlockCard(order)}
              </div>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SingleOrder;

import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
} from "@ionic/react";
import React from "react";

const TermAndCondition = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" defaultHref="/" />
          </IonButtons>
          <IonTitle>TERM OF USE AND SERVICE</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="default-page-size">
          <div className="default-inner-box">
            <div className="term-and-condition-box">
              <h4>
                <strong>TERM OF USE AND SERVICE</strong>
              </h4>
              <p>
                Your use of this Website (www.floorplanbazaar.com) means you
                agree to the following terms of use (TOU) described below.
              </p>
              <p>
                If you proceed to pay for our products, offerings or services
                provided by us on our Website (www.floorplanbazaar.com). Your
                purchase of such product(s), offering(s) or service(s) are
                governed by the following terms of service (TOS) described
                below. By paying for our product(s), offering(s) or service(s)
                provided by us, it&rsquo;s understood and believed that you have
                read our &ldquo;Terms of Use&rdquo; and &ldquo;Terms of
                Service&rdquo; carefully and you totally agree with it.
              </p>
              <p>
                PLEASE READ THESE TERMS OF USE (HEREINAFTER REFERRED TO AS
                "TOU") AND ALSO READ THE TERMS OF SERVICE (HEREINAFTER REFERRED
                TO AS "TOS") CAREFULLY.
              </p>
              <p>
                THIS AGREEMENT GOVERNS YOUR USE OF WWW.FLOORPLANBAZAAR.COM
                (HEREINAFTER REFERRED TO AS "WEBSITE" or &ldquo;Website&rdquo;)
                AS WELL AS&nbsp;IN OTHER SUB-DOMAINS OR MICROSITES CONTROLLED BY
                "NAKSHA BAZAAR (OPC) PRIVATE LIMITED" (HEREINAFTER REFERRED TO
                AS "FPB").
              </p>
              <p>
                YOUR USE OF THIS WEBSITE MEANS YOU AGREE TO THE FOLLOWING TERMS
                OF USE AND SERVICE DESCRIBED HEREIN AND BY ALL TERMS, POLICIES
                AND GUIDELINES INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO
                ALL OR ANY OF THESE TERMS OF USE OR TERMS OF SERVICE, DO NOT USE
                THIS WEBSITE, LEAVE THIS WEBSITE IMMEDIATELY, NOT USE THE FILES
                HEREIN, AND/OR NOT SUBSCRIBE TO THIS SERVICE.
              </p>
              <p>
                PLEASE NOTE THAT THIS WEBSITE MAY INCLUDE LINKS TO WEBSITES OF
                THIRD PARTIES WHOSE TERMS OF USE AND PRIVACY POLICES MAY DIFFER
                FROM THOSE OF FPB.
              </p>
              <p>
                By using this website, you represent and warrant that you are
                over the age of 18 and are lawfully able to accept these Website
                terms of conditions of use. If you are using the Website on
                behalf of any entity, you further represent and warrant that you
                are authorized to accept these Website terms and conditions on
                such entity's behalf, and that such entity agrees to indemnify
                FPB for violations of these Website terms and conditions.
              </p>
              <p>
                FPB may revise and update these TOU and TOS at any time and
                without notice. Your continued access or use of this website
                after any such changes are posted will constitute your
                acceptance of these changes and you would be bound by the
                amended TOU and TOS.
              </p>
              <h4>
                <strong>OWNERSHIP OF THE WEBSITE AND ITS CONTENTS</strong>
              </h4>
              <p>
                THIS WEBSITE IS OWNED, HOSTED AND OPERATED BY "NAKSHA BAZAAR
                (OPC) PRIVATE LIMITED" (HEREINAFTER REFERRED TO AS "FPB").
              </p>
              <p>
                Unless otherwise indicated, all the content featured or
                displayed on the Website, including, but not limited to,
                designs, layouts, floorplans, pdf-files, 2d or 3d drawings,
                images, videos, photographic or graphical images, text,
                graphics, layout of the website, sound, illustrations, logos,
                trademarks, patents, and the selection and arrangement thereof
                ("FPB Content"), is owned by NAKSHA BAZAAR (OPC) PRIVATE
                LIMITED, its affiliates, subsidiaries, its licensors,
                contributors, or its third-party visualization and imaging
                partners.
              </p>
              <h4>
                <strong>[A] TERMS OF USE (TOU)</strong>
              </h4>
              <p>
                This Website and FPB Content are intended for the customers of
                FPB. You may not use this Website or the FPB Content for any
                purpose not related to your business with FPB Content.
              </p>
              <p>You are specifically prohibited from:</p>
              <p>
                (a) downloading, copying, or re-transmitting any or all of the
                Website or FPB Content without, or in violation of, a written
                license or agreement with FPB;
              </p>
              <p>
                (b) using any data mining, robots or similar data gathering or
                extraction methods;
              </p>
              <p>
                (c) manipulating or otherwise displaying the Website or the FPB
                Content by using framing, linking, meta-tag or similar
                navigational technology;
              </p>
              <p>
                (d) registering, subscribing, unsubscribing, or attempting to
                register, subscribe, or unsubscribe any party for any FPB
                Content product or service if you are not expressly authorized
                by such party to do so;
              </p>
              <p>
                (e) using the Website or the FPB Content other than for its
                intended purpose. Such unauthorized use may also violate
                applicable laws including without limitation copyright and
                trademark laws, publicity and privacy laws, moral rights, and
                applicable communications regulations and statutes.
              </p>
              <p>
                You represent and warrant that you will comply with all
                applicable laws and regulations, including, without limitation,
                those relating to the Internet, data, e-mail, copyright,
                trademarks, patent, privacy, and the transmission of technical
                data exported from India or the country in which you reside. You
                further warrant that you will not interfere with the security
                of, or otherwise abuse this Website or any system resources,
                services or networks connected to or accessible through this
                Website. You are allowed to use this Website only for lawful
                purposes and activities.
              </p>
              <ol start="1">
                <li>
                  <strong>REGISTRATION DATA AND ACCOUNT SECURITY</strong>
                </li>
              </ol>
              <p>In consideration of your use of the Website, you agree to:</p>
              <p>
                (a) provide correct, accurate, current and complete information
                about you as may be prompted by any registration forms on the
                Website ("Registration Data");
              </p>
              <p>
                (b) maintain and promptly update the Registration Data, and any
                other information you provide to FPB, to keep it accurate,
                current and complete;
              </p>
              <p>
                (c) maintain the security of your password and identification;
              </p>
              <p>
                (d) notify FPB immediately of any unauthorized use of your
                account or other breach of security;
              </p>
              <p>
                (e) accept all responsibility for any and all activities from
                your account; and
              </p>
              <p>
                (f) accept all risks of unauthorized access to the registration
                Data and any other information you provide to FPB.
              </p>
              <ol start="2">
                <li>
                  <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
                </li>
              </ol>
              <p>
                FPB is the sole owner and lawful licensee of all the rights to
                the Website and its Content. FPB Content are the property of FPB
                and are protected by all the applicable laws, including, but not
                limited to copyright, trademark, trade-names, patents, internet
                domain names, data protection, IT Act, privacy and publicity
                rights and other similar rights and statutes. Use of any FPB
                Content without the express written permission and license to
                use is strictly prohibited. All title, ownership and
                intellectual property rights in the Website and its Content
                shall remain with FPB, its affiliates or licensor's, as the case
                may be and does not pass on to you or your representatives.
              </p>
              <p>
                <strong>
                  <u>2.1 &ndash; COPYRIGHT</u>
                </strong>
              </p>
              <p>
                FPB, its affiliates, subsidiaries, its licensors, contributors,
                or its third-party visualization and imaging partners, as the
                case may be, own all copyrights in the represented images on the
                Website. No ownership in any represented images shall to anyone
                accessing this Website and using the Content. You shall not
                remove any copyright or other proprietary rights notice
                contained in the represented Images, any related promotional
                materials provided on this Website. FPB prohibits any Content or
                any other material that infringes on any copyright, trademark,
                patent, and trade secret, right to privacy, right to publicity,
                or any other applicable law or proprietary right to be uploaded
                to the Website.
              </p>
              <p>
                FPB respects the intellectual property rights of others, and we
                expect our user(s) to do the same. FPB is not an expert in your
                intellectual property rights, and we cannot verify that the
                users, third-party partners and contributors to the Website have
                rights to the contents posted or contributed by them. If you
                believe that any material on the Website infringes upon any
                copyright that you own or control, you may notify us on our
                email: info@floorplanbazaar.com
              </p>
              <p>
                FPB may give notice of a claim of copyright infringement by
                means of a general notice on the Website, electronic mail to a
                user's or contributors e-mail address and may also remove the
                infringing content uploaded by the user or contributor. In the
                event, FPB is not removing the said Content; FPB is not
                endorsing the sale of any such content.
              </p>
              <p>
                <strong>&nbsp;</strong>
                <strong>
                  <u>2.2 &ndash; TRADEMARKS</u>
                </strong>
              </p>
              <p>
                FPB is the exclusive owner and holder of FPB
                Trademarks,&nbsp;www.floorplan bazaar.com, Floorplan Bazaar or
                any other Floorplan Bazaar&rsquo;s trade names, trademarks,
                logos or service marks and any other slogan or design contained
                in the Website and otherwise used in trade (''FPB Marks'').
              </p>
              <p>
                FPB Marks shall remain the sole property of FPB, its affiliates
                and subsidiaries, and may not be copied, imitated or used, in
                whole or in part, without the prior written permission of FPB or
                the applicable trademark holder. You are not allowed to use,
                register, or assist others in using or registering any
                trademarks, trade names, logos, internet domain names, or any
                mark or name confusingly similar to FPB Marks without FPB prior
                written consent. You will not now or in the future contest the
                validity of FPB Marks nor will oppose or assist others in
                opposing any registration of any of the FPB Marks. You are not
                allowed to use any FPB Marks in connection with any of your
                works. All goodwill accruing to the FPB Marks shall belong to
                FPB. You agree not to use FPB Marks in any manner that might
                tarnish, disparage, or reflect adversely on such trademarks or
                FPB. You agree not to use any FPB Marks or any variant thereof
                including misspellings as a domain name or as part of a domain
                name regardless of the top-level domain, or as a meta-tag,
                hidden text, keyword, or any other type of programming code or
                data. You cannot at any time, adopt or use, without FPB's prior
                written consent any word or marks which is similar to or likely
                to be confused with FPB Marks. In addition, the look and feel of
                the Website, including all page headers, custom graphics, button
                icons and scripts, is the service mark, trademark and/or trade
                dress of FPB and may not be copied, imitated or used, in whole
                or in part, without FPB&rsquo;s prior written permission. All
                other trademarks, registered trademarks, product names and
                company names or logos mentioned in the Website are the property
                of their respective owners. Reference to any products, services,
                processes or other information, by trade name, trademark,
                manufacturer, supplier, or otherwise does not constitute or
                imply endorsement, sponsorship or recommendation thereof by us.
              </p>
              <ol start="3">
                <li>
                  <strong>LINKS</strong>
                </li>
              </ol>
              <p>
                FPB makes no claim or representation regarding, and accepts no
                responsibility for, directly or indirectly, the quality,
                concept, nature or reliability of third-party websites
                accessible by hyperlink from the Website, or websites linking to
                our Website. Such sites are not under the control of FPB and FPB
                is not responsible for the contents of any linked site or any
                link contained in a link site, or any review, changes or updates
                to such sites. FPB provides these links to you only as a
                convenience, and the inclusion of any link does not imply
                affiliation, endorsement or adoption by FPB of any site or any
                information contained therein. When you leave our Website, you
                should be aware that our terms and policies no longer govern.
                You should review the terms and policies, including privacy and
                data gathering practices, of any site to which you navigate from
                the Website.
              </p>
              <p>
                Your participation, correspondence or business dealings with any
                third party found on or through the Website, regarding the
                payment and delivery of related goods and services, and any
                other terms, conditions, warranties or representations
                associated with such dealings, are solely between you and such
                third party, and you agree that FPB shall not be responsible or
                liable for any loss, damage or other matters of any sort
                incurred as the result of any such dealings.
              </p>
              <ol start="4">
                <li>
                  <strong>LIMITATION OF LIABILITY AND DISCLAIMER</strong>
                </li>
              </ol>
              <p>
                THIS WEBSITE AND FPB CONTENT ARE PROVIDED "AS IS" AND FPB AND
                ITS DIRECTORS, EMPLOYEES, CONTENT PROVIDERS, AGENTS AND
                AFFILIATES EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY
                APPLICABLE LAW, ANY WARRANTY, EXPRESS OR IMPLIED, INCLUDING,
                WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE. FPB
                WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
                USE OF THIS WEBSITE OR FPB&nbsp; CONTENT, OR THE UNAVAILABILITY
                OF THE SAME, INCLUDING, BUT NOT LIMITED TO LOST PROFITS, AND
                DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL
                DAMAGES. THE FUNCTIONS EMBODIED ON OR IN THE MATERIALS OF THIS
                WEBSITE ARE NOT WARRANTED TO BE UNINTERRUPTED OR WITHOUT ERROR.
                YOU, NOT FPB, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING,
                REPAIR OR CORRECTION DUE TO YOUR USE OF THIS WEBSITE OR THE
                FPB&nbsp; CONTENT.
              </p>
              <p>
                FPB MAKES NO WARRANTY THAT THE WEBSITE OR CONTENT IS FREE FROM
                INFECTION BY VIRUSES OR ANYTHING ELSE THAT HAS CONTAMINATING OR
                DESTRUCTIVE PROPERTIES.
              </p>
              <p>
                FPB uses reasonable efforts to ensure the accuracy, correctness
                and reliability of the information and Content, but FPB makes no
                representations or warranties as to the Content posted by the
                Contributor(s) as to its accuracy, correctness, reliability and
                any other irregularity.
              </p>
              <p>
                The information on this site has been included in good faith and
                is for general purposes only. It should not be relied upon for
                any specific purpose and no representation or warranty is given
                as regards its accuracy or completeness.
              </p>
              <p>
                IN NO EVENT SHALL FPB, ITS AFFILIATES, SUBSIDIARIES AND THEIR
                RESPECTIVE DIRECTORS, EMPLOYEES, OFFICERS, MEMBERS OR AGENTS BE
                LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL
                DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT
                LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER
                IN AN ACTION IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO
                NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED
                WITH THE USE OF THE WEBSITE, THE SERVICES, FPB IMAGE CONTENT OR
                THE MATERIALS CONTAINED IN OR ACCESSES THROUGH THE WEBSITE,
                INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING
                FROM RELIANCE BY USER ON ANY INFORMATION OBTAINED FROM FPB, OR
                THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF
                FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR
                TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT
                RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT,
                DESTRUCTION OR UNAUTHORIZED ACCESS TO FPB&rsquo;s RECORDS,
                PROGRAMS OR SERVICES. IN NO EVENT SHALL THE AGGREGATE LIABILITY
                OF FPB, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING
                NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT
                LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR
                RELATING TO THE USE OF THE SITE EXCEED ANY COMPENSATION YOU PAY,
                IF ANY, TO FPB FOR ACCESS TO OR USE OF THE WEBSITE.
              </p>
              <p>
                Some jurisdictions do not permit the exclusion or limitation of
                implied warranties or liability for certain categories of
                damages. Therefore, some or all of the limitations above may not
                apply to you to the extent they are prohibited or superseded by
                state or national provisions.
              </p>
              <ol start="5">
                <li>
                  <strong>INDEMNIFICATION</strong>
                </li>
              </ol>
              <p>
                You agree to indemnify and hold FPB, its affiliates,
                subsidiaries and their respective directors, employees, officers
                and agents harmless against all claims or liability asserted
                against FPB arising out of or in connection with any breach by
                you or anyone acting on your behalf of any of the TOU or TOS of
                this Agreement. You shall defend, indemnify and hold FPB, its
                affiliates, subsidiaries and their respective directors,
                employees, agents and officers harmless from all damages,
                liabilities and expenses (including reasonable attorney's fees
                and permitted and authorized costs) arising out of breach of
                this Agreement as a result of claims by third parties relating
                to your use and act relating to this Agreement or any breach of
                this Agreement. Such indemnification is in addition to FPB's
                right to terminate for a breach of the representations and
                warranties and is not a waiver or limitation of FPB&rsquo;s
                other rights or remedies.
              </p>
              <ol start="6">
                <li>
                  <strong>GOVERNING LAW AND JURISDICTION</strong>
                </li>
              </ol>
              <p>
                Any dispute, violation, controversy, contest or claim arising
                out of or relating to this Agreement or the breach, termination
                or validity thereof (hereinafter the "Disputes") shall be
                resolved amicably in the first instance. The Parties agree to
                meet to resolve such dispute in good faith. Should such a
                solution not be reached, within [30] days from the date of
                commencement of the dispute, difference or disagreement or such
                longer period of time as may be mutually agreed by the Parties
                in writing, such dispute, difference or disagreement shall be
                referred to Arbitration and shall be finally resolved through
                arbitration conducted by a tribunal of three arbitrators, with
                the right of each party to appoint an arbitrator, who in turn
                will appoint a Presiding Arbitrator. The Presiding Arbitrator
                shall pass a speaking award. Any order/directions/awards of the
                majority of the tribunal shall be final and binding on both the
                parties. The arbitration proceedings shall be in accordance with
                the Indian Arbitration and Conciliation Act 1996 and Rules there
                under as at present in force. The seat of arbitration shall be
                Delhi, India. The arbitration shall be administered in
                accordance with its practice, rules and regulations. If the
                award passed in the Arbitration proceeding is not acceptable to
                any of the Party, then the Party is free to approach the
                appropriate court of law as agreed herein below. This Agreement
                shall be construed in accordance with and governed by the laws
                of India without giving effect to their conflict of laws
                principles or rules. The courts at Delhi, India shall have
                exclusive Jurisdiction.
              </p>
              <ol start="7">
                <li>
                  <strong>TERMINATION</strong>
                </li>
              </ol>
              <p>
                Notwithstanding any of these Website Terms of Use (TOU) or Terms
                of Service (TOS), FPB reserves the right, without notice in its
                sole discretion, to terminate your account and/or block your use
                of the Website. Upon termination, you must destroy and cease to
                use all materials obtained from this Website, copies and related
                documentation thereof with immediate effect.
              </p>
              <ol start="8">
                <li>
                  <strong>REMEDY FOR BREACH</strong>
                </li>
              </ol>
              <p>
                You agree that any breach of this Agreement by you or your
                representatives would cause FPB irreparable harm, injury, loss
                and damage, the extent of which would be impossible to ascertain
                and for which monetary damages would not be an adequate remedy.
                Accordingly, in addition to any other remedies FPB may have at
                law or in equity, FPB shall be entitled to injunctive and other
                equitable relief in the event of any such breach of this
                Agreement by you or your representatives.
              </p>
              <ol start="9">
                <li>
                  <strong>SEVERABILITY</strong>
                </li>
              </ol>
              <p>
                If one or more of the provisions contained in this Agreement is
                found to be invalid, illegal or enforceable in any respect, the
                validity, legality and enforceability of the remaining
                provisions shall not be affected. Such provisions shall be
                revised only to the extent necessary to make them enforceable.
              </p>
              <ol start="10">
                <li>
                  <strong>WAIVER</strong>
                </li>
              </ol>
              <p>
                No action of FPB, other than express written waiver, may be
                construed as a waiver of any provision of this Agreement. A
                delay on the part of FPB of its rights or remedies will not
                operate as a waiver of such rights or remedies, and a single or
                partial exercise by a party of any such rights or remedies will
                not preclude other or further exercise of that right or remedy.
                A waiver of a right or remedy on any occasion will not be
                construed as a bar to or waiver of rights or remedies on any
                other occasion. Any rights not expressly granted herein are
                reserved.
              </p>
              <ol start="11">
                <li>
                  <strong>RIGHTS</strong>
                </li>
              </ol>
              <p>
                FPB reserves the right to change any Details, Descriptions,
                Terms of Use (TOU) or Terms of Service (TOS) contained in this
                Agreement or any policy or guidelines of any product(s) or
                service(s) of the Website, at any time and in its sole
                discretion, without a notice or notification(s). Your continued
                use of the Website as well as&nbsp;any other sub-domains or
                microsites controlled by FPB, following the posting of such
                changes will constitute your acceptance of such changes and you
                would be bound by the amended changes.
              </p>
              <h4>
                <strong>[B] TERMS OF SERVICE (TOS)</strong>
              </h4>
              <p>
                Your purchase of any product(s) or service(s) on this Website is
                governed by the following terms of service (TOS) described
                below. By paying for our product(s), offering(s) or service(s)
                provided by us, it&rsquo;s understood and believed that you have
                read our TOU and TOS carefully and you totally agree with it.
              </p>
              <p>
                The product(s) or service(s) specific modification(s) required
                in some product(s) or service(s) are also enlisted below.
              </p>
              <p>
                By using this website, you represent and warrant that you are
                over the age of 18 and are lawfully able to accept these Website
                terms of conditions of use. If you are using the Website on
                behalf of any entity, you further represent and warrant that you
                are authorized to accept these Website terms and conditions on
                such entity's behalf, and that such entity agrees to indemnify
                FPB for violations of these Website terms and conditions.
              </p>
              <p>
                FPB may revise and update these Terms of Service (TOS) at any
                time and without notice. Your continued access or use of this
                website after any such changes are posted will constitute your
                acceptance of these changes and you would be bound by the
                amended TOS.
              </p>
              <p>
                Our Website has various kind(s) of bundled, combined, grouped or
                clubbed services offered together to form a complete package of
                services called as full service package (herein referred to as
                &ldquo;Full Service Package&rdquo;)
              </p>
              <p>
                Whereas there are some product(s) and service(s) in which only
                one or single kind of service is provided or offered, such
                package of service are called single service package (herein
                referred to as &ldquo;Single Service Package&rdquo;). In such
                kind of product(s) and service(s) there will be only one service
                or one kind of service called as a package, by calling it a
                package, does not make it exactly the same or similar to a Full
                Service Package in any interpretation, or misinterpretation.
              </p>
              <p>
                On our Website we currently offer two Full Service Package and
                one Single Service Package. The &ldquo;Luxury House Design
                Package&rdquo; and &ldquo;Modern House Design Package&rdquo; are
                the two Full Service Package offered on our Website whereas the
                &ldquo;Floor Plan Package&rdquo; is a Single Service Package.
              </p>
              <p>
                The Full Service Package and Single Service Package are offered
                on our Website as service(s) and not as any physical product(s).
                The delivery of these service(s) are not physical, whereas it
                can be interpreted as an online product which includes services
                to be provided online, thru digital means of communication and
                the nature of delivery of such service(s) does not involve
                courier, shipping or any kind of physical delivery to customers
                address or addresses.&nbsp;&nbsp;
              </p>
              <ol start="12">
                <li>
                  <strong>
                    TERMS OF SERVICE FOR &ldquo;LUXURY HOUSE DESIGN
                    PACKAGE&rdquo;
                  </strong>
                </li>
              </ol>
              <p>
                Your purchase of this Full Service Package on the Website is
                governed by the following Terms of Service (TOS) described
                below. By paying for our product(s), offering(s) or service(s)
                provided by us, it&rsquo;s understood and believed that you have
                read our TOU and TOS carefully and you totally agree with it.
              </p>
              <p>
                The product(s) or service(s) specific modification(s) required
                in some product(s) or service(s) are enlisted below.
              </p>
              <p>
                The descriptions and details provided on the relevant product
                page(s) of this Website shall be considered for reference(s)
                regarding the elaborate understanding of all the details, for
                this specific Full Service Package called as &ldquo;Luxury House
                Design Package&rdquo;.&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                <strong>
                  <u>12.1 - LIST OF SERVICES PROVIDED</u>
                </strong>
              </p>
              <p>
                The concise list of services provided in this specific Full
                Service Package are described below, whereas for detailed
                information visit the relevant product page(s) of this
                product(s) or service(s) as available on the Website.
              </p>
              <ol className="subli">
                <li>
                  <strong>ARCHITECTURE FLOOR PLAN</strong> - Floor Plan layout
                  for all floors as per your plot size and requirement. Floor
                  Plan will be as per vaastu norms if required.
                </li>
                <li>
                  <strong>STRUCTURE DRAWINGS</strong> - Column center line,
                  column reduction chart, foundation layout and sections, plinth
                  beam layout and sections, beam layout, beam reduction chart,
                  beam section views, slab layout, slab section views.
                </li>{" "}
                <li>
                  <strong>SITEWORK DRAWINGS</strong> - Door-window layout
                  including designs and details. Plumbing layout including pipe
                  layout, fittings and fixtures details. Electrical layout
                  including electrical fitting and fixture details.
                  Architectural working dimensions.
                </li>{" "}
                <li>
                  <strong>3D FRONT ELEVATION &amp; VIDEO</strong> - 3d front
                  elevation designing, which can be totally customized as per
                  your requirement. We will provide one design and one
                  modification for the proposed design. If needed, we will
                  provide two different color options for the selected design.
                </li>
                <li>
                  <strong>3D INTERIOR VIEWS</strong> - Interior views with False
                  ceiling design, T.V Panel design, furniture layout and
                  decoration of Living Room, Bedroom, Kitchen, Bathroom, Dining
                  Area, Guest Room, Study Room, Temple, Etc. Image views are
                  provided from three angles &ndash; front side, right and left
                  sides. All interior work can be partially customized as per
                  your requirements.
                </li>{" "}
                <li>
                  <strong>FALSE CEILING DESIGNS</strong> - False ceiling design
                  are provided for Living Room, Bedroom, Kitchen, Dining Area,
                  Guest Room, Study Room, Etc. The designs are visible in 3d
                  interior images. The designs can be fully customized as per
                  your requirements.
                </li>
                <li>
                  <strong>INTERIOR VIDEO WALKTHRU</strong> - Video walk-thru is
                  a kind of visualization tour. It starts from the exterior of
                  the house showing the front elevation and proceeding towards
                  the interiors of the house. It showcases all the fine details
                  of exterior and interior designs in 8-10min HD video
                  walkaround.
                </li>{" "}
                <li>
                  <strong>3D FLOORPLAN</strong> - 3d floor plan layout shows
                  basic interior views like bed placement, other furniture
                  placements, walls and flooring details in a 3d realistic
                  image. Basically the 2d layout of floorplan is converted into
                  photorealistic 3d image.
                </li>
              </ol>

              <p>
                <strong>
                  <u>12.2 - TERMS OF PAYMENT AND ITS SCHEDULE</u>
                </strong>
              </p>
              <ol className="subli">
                <li>
                  <strong>FIRST PAYMENT</strong> <strong>&ndash; 15%</strong>{" "}
                  advance for starting the floor planning work.
                </li>
                <li>
                  <strong>SECOND PAYMENT</strong> <strong>&ndash; 30%</strong>{" "}
                  payment after your floor plan gets finalized. This will be the
                  advance for starting the 3d front elevation work and
                  initiating the process of making the project file, which
                  includes site work drawings and structure drawings.
                </li>{" "}
                <li>
                  <strong>THIRD PAYMENT &ndash; 30%</strong> payment after
                  receiving the project file (site work drawings and structure
                  drawings) and 3d front elevation.
                </li>{" "}
                <li>
                  <strong>FOURTH AND FINAL PAYMENT &ndash; 15%</strong> payment
                  after receiving the interior designs and before we send the
                  interior walk-around video.
                </li>
              </ol>

              <p>
                <strong>
                  <u>12.3 - SERVICE LIMITATIONS</u>
                </strong>
              </p>
              <ol className="subli">
                <li>
                  At the time of buying the package. Please select the options
                  carefully. It will be difficult to change the selections later
                  and an extra charge may occur, if any new selection causes
                  difference in pricing.
                </li>
                <li>
                  Once your architecture floor plan gets finalized and the
                  process of making project file (structure drawings,
                  door-window, electrical-plumbing or other drawings) is
                  initiated, the chances of modification in floor plan are rare.
                  If in case the project file gets delivered, then the option of
                  modification in floor plan is totally closed.
                </li>{" "}
                <li>
                  A delivered project file in its own is final and there will be
                  no changes, modifications or alterations in any drawings
                  (structure layouts, door-window, electrical-plumbing or other
                  drawings) once the project file is completed and delivered.
                </li>{" "}
                <li>
                  If your architecture floorplan layout changes on three
                  different floors then an extra charge of ₹3000 will be charged
                  for designing the floorplan, which you have to pay in the
                  beginning itself.
                </li>
                <li>
                  In case your floorplan changes on different floors, we will
                  provide you with an extra set of drawings for the modified
                  floor/floor&rsquo;s, such as modified structure layout,
                  separate door-window, electrical, plumbing and dimension
                  drawings.
                </li>
                <li>
                  If paid 15% advance. The floor plan designing work is capped
                  at 15 designs and 15 modifications in the given designs. Over
                  the capped limit you have to pay Extra Advance of ₹2000 to
                  ₹3000. The total package cost shall remain the same.
                </li>{" "}
                <li>
                  In this Luxury House Design Package, we don&rsquo;t provide 3d
                  elevation views from all sides of the building. For two side
                  (front &amp; back) road plots, the elevation view will be
                  provided from the front side only. corner road plots, the
                  elevation view will be for front side and right/left side
                  only.
                </li>
                <li>
                  3D floor plan and interior views are limited up to 6bhk layout
                  only.
                </li>{" "}
                <li>
                  Extra charges applicable for 3d designing of terrace gardens,
                  landscaping, garden/lawn designing, designing for above 6bhk
                  layout and triplex layouts.
                </li>
                <li>
                  In the Luxury House Design Package, we make designs in the
                  ratio of 1-design and 1-modifications. This ratio of work
                  applied for all 3d designing works, including interior and
                  exterior designing. You can also choose the ratio of work as
                  &ldquo;2-designs and no modification&rdquo;. For extra designs
                  and modifications, you can buy our Add-On packages, according
                  to your needs.
                </li>
                <li>
                  You can add customized text, name or logo in video walk-thru
                  with an extra charge of ₹1000.
                </li>{" "}
                <li>
                  All house plans are designed to conform to Indian National
                  Building codes along with necessary adjustments required to
                  fit into your design requirements.
                </li>
                <li>
                  The drawings will not be as per your local municipal norms or
                  state govt. bye-laws. We do not provide such sanction drawings
                  used for submission to take approval from local municipal or
                  state government offices.
                </li>
                <li>
                  Our designs and drawings are made by professional architects
                  and engineers but the architecture or structural drawing(s)
                  will not have architect&rsquo;s or engineer&rsquo;s stamp or
                  sign.
                </li>
              </ol>
              <ol start="13">
                <li>
                  <strong>
                    TERMS OF SERVICE FOR &ldquo;MODERN HOUSE DESIGN
                    PACKAGE&rdquo;
                  </strong>
                </li>
              </ol>
              <p>
                Your purchase of this Full Service Package on the Website is
                governed by the following Terms of Service (TOS) described
                below. By paying for our product(s), offering(s) or service(s)
                provided by us, it&rsquo;s understood and believed that you have
                read our TOU and TOS carefully and you totally agree with it.
              </p>
              <p>
                The product(s) or service(s) specific modification(s) required
                in some product(s) or service(s) are enlisted below.
              </p>
              <p>
                The descriptions and details provided on the relevant product
                page(s) of this Website shall be considered for reference(s)
                regarding the elaborate understanding of all the details, for
                this specific Full Service Package called as &ldquo;Modern House
                Design Package&rdquo;.&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                <strong>
                  <u>13.1 - LIST OF SERVICES PROVIDED</u>
                </strong>
              </p>
              <p>
                The concise list of services provided in this specific Full
                Service Package are described below, whereas for detailed
                information visit the relevant product page(s) of this
                product(s) or service(s) as available on the Website.
              </p>
              <ol className="subli">
                <li>
                  <strong>ARCHITECTURE FLOOR PLAN</strong> - Floor Plan layout
                  for all floors as per your plot size and requirement. Floor
                  Plan will be as per vaastu norms if required.
                </li>
                <li>
                  <strong>STRUCTURE DRAWINGS</strong> - Column center line,
                  column reduction chart, foundation layout and sections, plinth
                  beam layout and sections, beam layout, beam reduction chart,
                  beam section views, slab layout, slab section views.
                </li>{" "}
                <li>
                  <strong>SITEWORK DRAWINGS</strong> - Door-window layout
                  including designs and details. Plumbing layout including pipe
                  layout, fittings and fixtures details. Electrical layout
                  including electrical fitting and fixture details.
                  Architectural working dimensions.
                </li>{" "}
                <li>
                  <strong>3D FRONT ELEVATION DESIGN</strong> - 3d front
                  elevation designing, which can be totally customized as per
                  your requirement. We will provide one design and one
                  modification for the proposed design. If needed, we will
                  provide two different color options for the selected design.
                </li>
                <li>
                  <strong>3D FRONT ELEVATION VIDEO WALKAROUND</strong> - After
                  finalization of 3d front elevation design, we will make a 60 -
                  90 second video walkaround of the building&rsquo;s exterior
                  elevation view. This video will showcase the exterior look of
                  the building in a HD video.
                </li>
              </ol>

              <p>
                <strong>
                  <u>13.2 - TERMS OF PAYMENT AND ITS SCHEDULE</u>
                </strong>
              </p>
              <ol className="subli">
                <li>
                  <strong>FIRST PAYMENT &ndash; 25%</strong> advance for
                  starting the floor planning work.
                </li>
                <li>
                  <strong>SECOND PAYMENT &ndash; 60%</strong> payment after your
                  floor plan gets finalized. This will be the advance for
                  starting the process of making the project file, which
                  includes site work drawings and structure drawings.
                </li>{" "}
                <li>
                  <strong>THIRD AND FINAL PAYMENT &ndash; 15%</strong> payment
                  after receiving the project file (site work drawings and
                  structure drawings) and before we send the 3d front elevation
                  design.
                </li>
              </ol>
              <p>
                <strong>
                  <u>13.3 - SERVICE LIMITATIONS</u>
                </strong>
              </p>
              <ol className="subli">
                <li>
                  At the time of buying the package. Please select the options
                  carefully. It will be difficult to change the selections later
                  and an extra charge may occur, if any new selection causes
                  difference in pricing.
                </li>
                <li>
                  Once your architecture floor plan gets finalized and the
                  process of making project file (structure drawings,
                  door-window, electrical-plumbing or other drawings) is
                  initiated, the chances of modification in floor plan are rare.
                  If in case the project file gets delivered, then the option of
                  modification in floor plan is totally closed.
                </li>{" "}
                <li>
                  A delivered project file in its own is final and there will be
                  no changes, modifications or alterations in any drawings
                  (structure layouts, door-window, electrical-plumbing or other
                  drawings) once the project file is completed and delivered.
                </li>{" "}
                <li>
                  If your architecture floorplan layout changes on three
                  different floors then an extra charge of ₹3000 will be charged
                  for designing the floorplan, which you have to pay in the
                  beginning itself.
                </li>
                <li>
                  In case your floorplan changes on different floors, we will
                  provide you with an extra set of drawings for the modified
                  floor/floor&rsquo;s, such as modified structure layout,
                  separate door-window, electrical, plumbing and dimension
                  drawings.
                </li>
                <li>
                  If paid 25% advance. The architecture floor plan designing
                  work is capped at 10 designs and up to 10 modifications in the
                  given designs. Over the capped limit you have to pay Extra
                  Advance of ₹2000 to ₹3000 The total package cost shall remain
                  the same.
                </li>{" "}
                <li>
                  In this Modern House Design Package, we don&rsquo;t provide 3d
                  elevation views from all sides of the building.
                </li>
                <li>
                  For two side (front &amp; back) road plots, the elevation view
                  will be provided from the front side only. For corner road
                  plots, the elevation view will be for front side and
                  right/left side only.
                </li>{" "}
                <li>
                  In the Modern House Design Package, we make 3d front elevation
                  designs in the ratio of 1-design and 1-modification. You can
                  also choose the ratio of work as &ldquo;2-designs and no
                  modification&rdquo;
                </li>
                <li>
                  For extra designs and modifications, you can buy our Add-On
                  packages, according to your needs.
                </li>
                <li>
                  You can add customized text, name or logo in video walk-thru
                  with an extra charge of ₹1000
                </li>{" "}
                <li>
                  All house plans are designed to conform to Indian National
                  Building codes along with necessary adjustments required to
                  fit into your design requirements.
                </li>
                <li>
                  The drawings will not be as per your local municipal norms or
                  state govt. bye-laws. We do not provide such sanction drawings
                  used for submission to take approval from local municipal or
                  state government offices.
                </li>
                <li>
                  Our designs and drawings are made by professional architects
                  and engineers but the architecture or structural drawing(s)
                  will not have architect&rsquo;s or engineer&rsquo;s stamp or
                  sign.
                </li>
              </ol>
              <ol start="14">
                <li>
                  <strong>
                    TERMS OF SERVICE FOR &ldquo;FLOOR PLAN PACKAGE&rdquo;
                  </strong>
                </li>
              </ol>
              <p>
                Your purchase of this Single Service Package on the Website is
                governed by the following Terms of Service (TOS) described
                below. By paying for our product(s), offering(s) or service(s)
                provided by us, it&rsquo;s understood and believed that you have
                read our TOU and TOS carefully and you totally agree with it.
              </p>
              <p>
                The product(s) or service(s) specific modification(s) required
                in some product(s) or service(s) are enlisted below.
              </p>
              <p>
                The descriptions and details provided on the relevant product
                page(s) of this Website shall be considered for reference(s)
                regarding the elaborate understanding of all the details, for
                this specific Full Service Package called as &ldquo;Floor Plan
                Package&rdquo;.&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                <strong>
                  <u>14.1 - LIST OF SERVICES PROVIDED</u>
                </strong>
              </p>
              <p>
                The concise list of services provided in this specific Full
                Service Package are described below, whereas for detailed
                information visit the relevant product page(s) of this
                product(s) or service(s) as available on the Website.
              </p>
              <p>
                ARCHITECTURE FLOOR PLAN - Floor Plan layout for all floors as
                per your plot size and requirement. Floor Plan will be as per
                vaastu norms if required.
              </p>
              <p>
                All floor plans are made according to your requirements,
                suggestions and our expertise.
              </p>
              <p>
                <strong>
                  <u>14.2 - TERMS OF PAYMENT AND ITS SCHEDULE</u>
                </strong>
              </p>
              <p>
                FIRST PAYMENT &ndash; There will be 100% advance payment for
                starting the floor planning work. The Pricing changes as per
                your plot size and other option selections on website during the
                buying process.
              </p>
              <p>
                <strong>
                  <u>14.3 - SERVICE LIMITATIONS</u>
                </strong>
              </p>
              <ul className="subli">
                <li>
                  At the time of buying the package. Please select the options
                  carefully. It will be difficult to change the selections later
                  and an extra charge may occur, if any new selection causes
                  difference in pricing.
                </li>
                <li>
                  If your architecture floorplan layout changes on three
                  different floors then an extra charge of ₹3000 will be charged
                  for designing the floorplan, which you have to pay in the
                  beginning itself.
                </li>
                <li>
                  The architecture floor plan designing work is capped at 10
                  designs and up to 10 modifications in the given designs. Over
                  the capped limit you can purchase the package again for
                  getting more designs.
                </li>{" "}
                <li>
                  Designs are subjective in nature, the same design can be
                  appreciated by some and criticized by others. We do not claim,
                  assure or guaranteed that your floor plan will get finalized
                  in two designs or ten designs. In some cases it gets finalized
                  in two designs also and it may take more than ten designs
                  also.
                </li>
                <li>
                  In this Floor Planning Package, only architecture floor plan
                  layout is provided. There will be no furniture layout, no
                  column marking, no structure details, no doors or window
                  details.
                </li>
                <li>
                  All house plans are designed to conform to Indian National
                  Building codes along with necessary adjustments required to
                  fit into your design requirements.
                </li>{" "}
                <li>
                  The drawings will not be as per your local municipal norms or
                  state govt. bye-laws. We do not provide such sanction drawings
                  used for submission to take approval from local municipal or
                  state government offices.
                </li>
                <li>
                  Our designs and drawings are made by professional architects
                  and engineers but the architecture or structural drawing(s)
                  will not have architect&rsquo;s or engineer&rsquo;s stamp or
                  sign.
                </li>
              </ul>

              <ol start="15">
                <li>
                  <strong>OUR VAASTU POLICY</strong>
                </li>
              </ol>
              <ul className="subli">
                <li>
                  If you choose to get your house designed as per vaastu norms,
                  we try providing best possible compliance as per basic vaastu
                  principles. Despite our best efforts, due to various
                  limitations in terms of ventilation, space constraints and
                  plot size, we do not guarantee that our designs will be fully
                  vaastu compliant. A few deviations from the vaastu norms may
                  be required to ensure good ventilation and functional floor
                  plan layout.
                </li>
                <li>
                  Small plot sizes always have some limitations in designing
                  floor plans as per vaastu norms. Best possible vaastu can only
                  be achieved in a plot having setbacks from all four sides and
                  no constraints for room size and ventilation.
                </li>{" "}
                <li>
                  We design the floor plans according to basic vaastu concepts
                  as per our standard format.
                </li>
                <li>
                  In case you want advanced vaastu compliance, you may hire your
                  own vaastu consultant and provide us with the sketches for
                  making the floor plan layouts.
                </li>
              </ul>

              <ol start="16">
                <li>
                  <strong>SUBJECTIVE POLICY</strong>
                </li>
              </ol>
              <ul className="subli">
                <li>
                  Every project and all customers are important for us. We take
                  great care while designing your dream home. We ensure that
                  most of your requirements are incorporated in the design, yet
                  sometimes it&rsquo;s not possible to properly design with the
                  limitations of space, ventilation, functionality, etc.
                </li>
                <li>
                  We try our best in giving equal and best service to everyone
                  and try to satisfy your requirements, yet we do not provide
                  any guarantee that our services will fully satisfy you. Design
                  is subjective in nature, the same design may be appreciated by
                  some and criticized by others.
                </li>
                <li>
                  In such rare cases, where you find that you are not happy with
                  the design or service provided by our team, we will refund
                  your full/partial payment and would discontinue our services
                  for you.
                </li>
              </ul>
              <ol start="17">
                <li>
                  <strong>REFUND POLICY</strong>
                </li>
              </ol>
              <p>
                Certain products have limitations regarding refund policy. There
                is no direct refund policy. All orders once placed on the
                Website are final and the request for a refund is strictly
                dependent upon the stage of your work progress and the sole
                discretion of FPB. Whereas, If you are, for any reason, not
                happy with your purchase or service(s) provided by our team.
                Please email us with your concerns at info@floorplanbazaar.com
                with your project code. We may issue partial or full refund.
                Refunds are being processed within 14 days period.
              </p>
              <p>TERMS USED FOR CERTAIN STAGE(S) OF WORK PROGRESS:</p>
              <p>
                <strong>Architecture Floor Planning Stage </strong>&ndash; The
                stage of work where initial process of drafting and/or making of
                architecture floor plan(s) gets started.
              </p>
              <p>
                <strong>Project File Making Stage</strong> &ndash; The stage of
                work where initial process of drafting and/or making of
                structure drawings, door-window designs, electrical-plumbing
                layouts and other site work drawing(s) gets started.
              </p>
              <p>
                <strong>3D Visualization Stage</strong> &ndash; (a) The stage of
                work where initial process of drafting and/or making of 3d front
                elevation design(s) gets started. (b) The stage of work where
                initial process of drafting and/or making of 3d interior
                visualization design(s) gets started.
              </p>
              <p>
                REFUND CONDITIONS IN ANY PACKAGE, AT CERTAIN STAGE(S) OF WORK
                PROGRESS:
              </p>
              <ol className="subli">
                <li>
                  <strong>At Architecture Floor Planning Stage</strong> &ndash;
                  Once the architecture floor planning work gets started, there
                  is no provision of refund. You have to receive the output
                  product(s) or service(s) in lieu of the advance payment(s), if
                  any.
                </li>
                <li>
                  <strong>At Project File Making Stage</strong> &ndash; Once the
                  process of making the Project File (structure drawings,
                  door-window designs, electrical-plumbing layouts and other
                  site work drawings) gets started, there is no provision of
                  refund. You have to receive the output product(s) or
                  service(s) in lieu of the advance payment(s), if any.
                </li>{" "}
                <li>
                  <strong>At 3D Visualization Stage</strong> &ndash; Once the
                  process of 3d visualization gets started, there is no
                  provision of refund. You have to receive the output product(s)
                  or service(s) in lieu of the advance payment(s), if any.
                </li>
              </ol>
              <p>
                <strong>
                  <u>
                    17.1 - REFUND POLICY IN &ldquo;FLOOR PLAN PACKAGE&rdquo;
                  </u>
                </strong>
              </p>
              <ul className="subli">
                <li>
                  Once the architecture floor planning work gets started, there
                  is no provision of refund.
                </li>
                <li>
                  We might be providing 10 floor plan layouts and 10
                  modifications in the given designs, but in the backend we do
                  try and make, more than 10 different designs. Only those plans
                  are shared with you, which are best suitable as per your
                  requirements.
                </li>
                <li>
                  We do not claim, assure or guarantee that your architecture
                  floor plan layout will get finalized in two designs or 10
                  designs.
                </li>
                <li>
                  We do try our best to incorporate most of your requirements
                  and make your architecture floor plan best suitable as per
                  your suggested needs according to our expertise. If your floor
                  plan doesn&rsquo;t get finalized in maximum of 10 designs you
                  can upgrade to get more designs, we will not be issuing any
                  refunds at that stage.
                </li>
                <li>
                  In some cases, the architecture floor plan layout gets
                  finalized in a few designs but it may take more than 10
                  designs also. In either of the cases our charges are only for
                  making and providing you with different floor plan options.
                </li>
              </ul>
              <p>
                <strong>
                  <u>
                    17.2 - REFUND POLICY IN &ldquo;MODERN HOUSE DESIGN
                    PACKAGE&rdquo;
                  </u>
                </strong>
              </p>
              <ol className="subli">
                <li>
                  Once the architecture floor planning work gets started, there
                  is no provision of refund.
                </li>
                <li>
                  We do not claim, assure or guarantee that your architecture
                  floor plan layout will get finalized under the capped limit of
                  10 designs.
                </li>{" "}
                <li>
                  We do try our best to incorporate most of your requirements
                  and make your architecture floor plan best suitable as per
                  your suggested needs according to our expertise. If your floor
                  plan doesn&rsquo;t get finalized in maximum of 10 designs you
                  can upgrade to get more designs, we will not be issuing any
                  refunds at that stage.
                </li>{" "}
                <li>
                  In some cases, the architecture floor plan layout gets
                  finalized in a few designs but it may take more than 10
                  designs also. In either of the cases our charges are only for
                  making and providing you with different floor plan options.
                </li>
                <li>
                  Once the process of 3d visualization gets started, there is no
                  provision of refund. You have to receive the output product(s)
                  or service(s) in lieu of the advance payment(s), if any.
                </li>
                <li>
                  We do not claim, assure or guaranteed that your 3d
                  visualization work (3d front elevation design) will get
                  finalized under the default ratio of work, which is one design
                  with one modification or you can switch to two design&rsquo;s
                  with no modification.
                </li>{" "}
                <li>
                  In some cases, it gets finalized in one or two designs but it
                  may take more than two designs also depending upon your taste
                  and vision. In either of the cases our charges are only for
                  making and providing you with different 3d front elevation
                  designs. You can upgrade to get more 3d designs, we will not
                  be issuing any refunds at that stage.
                </li>
              </ol>
              <p>
                <strong>
                  <u>17.3 &ndash; REFUND POLICY IN </u>
                </strong>
                <strong>
                  <u>&ldquo;LUXURY HOUSE DESIGN PACKAGE&rdquo;</u>
                </strong>
              </p>
              <ol className="subli">
                <li>
                  Once the architecture floor planning work gets started, there
                  is no provision of refund.
                </li>
                <li>
                  We do not claim, assure or guarantee that your architecture
                  floor plan layout will get finalized in capped limit of 15
                  designs.
                </li>
                <li>
                  We do try our best to incorporate most of your requirements
                  and make your architecture floor plan best suitable as per
                  your suggested needs according to our expertise. If your floor
                  plan doesn&rsquo;t get finalized in maximum of 15 designs you
                  can upgrade to get more designs, we will not be issuing any
                  refunds at that stage.
                </li>{" "}
                <li>
                  In some cases, the architecture floor plan layout gets
                  finalized in a few designs but it may take more than 15
                  designs also. In either of the cases our charges are only for
                  making and providing you with different floor plan options.
                </li>
                <li>
                  Once the process of 3d visualization gets started, there is no
                  provision of refund. You have to receive the output product(s)
                  or service(s) in lieu of the advance payment(s), if any.
                </li>
                <li>
                  We do not claim, assure or guaranteed that your 3d
                  visualization work (3d front elevation design and/or 3d
                  interior layouts) will get finalized under the default ratio
                  of work, which is one design with one modification or you can
                  switch to two design&rsquo;s with no modification.
                </li>{" "}
                <li>
                  In some cases, it gets finalized in one or two designs but it
                  may take more than two designs also depending upon your taste
                  and vision. In either of the cases our charges are only for
                  making and providing you with different 3d front elevation
                  designs and different 3d interior layouts. You can upgrade to
                  get more 3d designs, we will not be issuing any refunds at
                  that stage.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TermAndCondition;

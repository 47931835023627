import React, { useState, useEffect, useGlobal } from "reactn";
import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonCard,
  IonInput,
  IonItem,
  IonButton,
  IonLoading,
} from "@ionic/react";
import { FaLock, FaMobileAlt, FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { getUserById, updateUserInfo, updateUser } from "./ApisUsers";
import {
  ToastDanger,
  ToastSuccess,
  ToastWarning,
} from "../../components/Toaster";
import { Redirect } from "react-router";
const EditProfile = () => {
  const [ShowPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useGlobal("userData");
  const [loading, setLoading] = useState(false);
  const [redirectPage, setRedirectPage] = useState(false);
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    secondaryNumber: "",
    password: "",
  });
  const { fullName, email, mobileNumber, secondaryNumber, password } = values;

  const initUser = () => {
    const userId = userData.user._id;
    const token = userData.token;
    setLoading(true);
    getUserById({ userId, token }).then((data) => {
      if (data.error) {
        ToastDanger(data.error, 5000);
      } else {
        setValues({
          ...values,
          fullName: data.user.fullName,
          email: data.user.email,
          mobileNumber: data.user.mobileNumber,
          secondaryNumber: data.user.secondaryNumber,
        });
        setLoading(false);
      }
    });
  };

  const onInputChnage = (name) => (event) => {
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    if (userData) {
      initUser();
    }
  }, []);

  const isValid = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const mNumber = parseInt(secondaryNumber);
    if (email.length <= 0) {
      ToastWarning("Email must be required.");
      setLoading(false);
      return false;
    } else if (email && !pattern.test(email)) {
      ToastWarning("Email must be valid.");
      setLoading(false);
      return false;
    } else if (secondaryNumber && isNaN(mNumber)) {
      ToastWarning("Secondary Mobile number must in digits.");
      setLoading(false);
      return false;
    } else if (secondaryNumber && secondaryNumber.length < 10) {
      ToastWarning("Mobile number must be valid.");
      setLoading(false);
      return false;
    } else if (password && password.length < 8) {
      ToastWarning("Password must be strong.");
      setLoading(false);
      return false;
    }
    return true;
  };

  const updateFormSubmit = (e) => {
    e.preventDefault();
    const userId = userData.user._id;
    const token = userData.token;
    setLoading(true);
    if (isValid()) {
      updateUserInfo(values, { userId, token }).then((data) => {
        if (data.error) {
          ToastDanger(data.error);
          console.log(data.error);
          setLoading(false);
        } else {
          updateUser(data, () => {
            setLoading(false);
            setRedirectPage(true);
            let newUpdatedUser = userData;
            newUpdatedUser.user = data;
            setUserData(newUpdatedUser);
            ToastSuccess("Profile is Updated", 5000);
          });
        }
      });
    }
  };

  const redirectPageHandle = () => {
    if (redirectPage) {
      return <Redirect to="/" />;
    }
  };

  return (
    <IonPage>
      {redirectPageHandle()}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" defaultHref="/" />
          </IonButtons>
          <IonTitle>Update Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Loading...."}
        />
        <div className="default-page-size">
          <div className="update-inner-box">
            <IonCard className="update-card">
              <div className="update-profile-box">
                <p>UPDATE YOUR PROFILE</p>
                <form onSubmit={updateFormSubmit}>
                  <IonItem
                    style={{ border: "solid 2px rgb(209, 209, 209)" }}
                    lines="none"
                    className="input-box"
                    id="ipbox-3"
                  >
                    <FaUserAlt className="login-logo" slot="start" />
                    <div>
                      <IonInput
                        type="text"
                        value={fullName}
                        onIonChange={onInputChnage("fullName")}
                        placeholder="Enter You Full Name"
                        className="login-input"
                      />
                    </div>
                  </IonItem>

                  <IonItem
                    style={{ border: "solid 2px rgb(209, 209, 209)" }}
                    lines="none"
                    className="input-box"
                    id="ipbox-3"
                  >
                    <MdEmail className="login-logo" slot="start" />
                    <div>
                      <IonInput
                        type="email"
                        value={email}
                        onIonChange={onInputChnage("email")}
                        placeholder="Enter Email"
                        className="login-input"
                      />
                    </div>
                  </IonItem>

                  <IonItem
                    style={{ border: "solid 2px rgb(209, 209, 209)" }}
                    lines="none"
                    className="input-box"
                    id="ipbox-3"
                  >
                    <FaMobileAlt className="login-logo" slot="start" />
                    <div>
                      <IonInput
                        type="tel"
                        maxlength={10}
                        disabled
                        value={mobileNumber}
                        placeholder="Enter Mobile No."
                        className="login-input"
                      />
                    </div>
                  </IonItem>

                  <IonItem
                    style={{ border: "solid 2px rgb(209, 209, 209)" }}
                    lines="none"
                    className="input-box"
                    id="ipbox-3"
                  >
                    <FaMobileAlt className="login-logo" slot="start" />
                    <div>
                      <IonInput
                        type="tel"
                        maxlength={10}
                        value={secondaryNumber}
                        onIonChange={onInputChnage("secondaryNumber")}
                        placeholder="Enter Secondary Mobile No."
                        className="login-input"
                      />
                    </div>
                  </IonItem>
                  <IonItem
                    style={{ border: "solid 2px rgb(209, 209, 209)" }}
                    lines="none"
                    className="input-box"
                    id="ipbox-4"
                  >
                    <FaLock className="login-logo" slot="start" />
                    <div>
                      <IonInput
                        onIonChange={onInputChnage("password")}
                        type={ShowPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        className="login-input"
                      />
                    </div>
                    <div slot="end">
                      {!ShowPassword ? (
                        <AiFillEyeInvisible
                          onClick={() => {
                            setShowPassword(true);
                          }}
                          className="password-logo"
                        />
                      ) : (
                        <AiFillEye
                          onClick={() => {
                            setShowPassword(false);
                          }}
                          className="password-logo"
                        />
                      )}
                    </div>
                  </IonItem>

                  <div style={{ marginTop: "8px" }} className="ion-text-right">
                    <IonButton type="submit" color="primary" size="small">
                      UPDATE
                    </IonButton>
                  </div>
                </form>
              </div>
            </IonCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;

import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonInput,
  IonButton,
  IonTextarea,
  IonCheckbox,
  IonIcon,
  IonLoading,
} from "@ionic/react";
import React, { useState, useGlobal, useEffect } from "reactn";
import { Link, Redirect, withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import { getCoupon } from "./ApisCore";
import { arrowForwardOutline } from "ionicons/icons";
import { AiOutlineClose } from "react-icons/ai";
import {
  ToastDanger,
  ToastSuccess,
  ToastWarning,
} from "../../components/Toaster";
import { createPaymentOrder, createOrder } from "./ApisCore";
import { RAZORPAY_KEY } from "../../Config";
const Razorpay = window.Razorpay;

const Checkout = (props) => {
  const [cartData, setCartData] = useGlobal("cartData");
  const [userData, setuserData] = useGlobal("userData");
  const [succesOrder, setSuccessOrder] = useGlobal("successOrder");

  const [loading, setLoading] = useGlobal("loading");
  const [tcChecked, settcChecked] = useState(false);
  const [noteData, setNoteData] = useState("");
  const [couponData, setCouponData] = useState({
    code: "",
    discount: 0,
    invalidCode: false,
    applied: false,
    isValid: false,
    flat: false,
  });
  const [redirectPage, setRedirectPage] = useState(false);

  const { code, discount, invalidCode, applied, isValid, flat } = couponData;
  const paymentPart = cartData && cartData.packageType;
  const offerData = cartData && cartData.packageData.packageOffer;
  const inr = "₹";
  let prices = {};

  const getPriceByPercentage = () => {
    const realPrice = cartData.packageTotalPrice;
    const offeredPrice = offerData.isValid
      ? cartData.packageTotalPrice - offerData.flatValue
      : cartData.packageTotalPrice;
    const useablePrice = applied
      ? flat
        ? offeredPrice - discount
        : offeredPrice - Math.round((discount / 100) * offeredPrice)
      : offeredPrice;

    if (paymentPart === "only-4") {
      prices = {
        realPrice,
        offeredPrice,
        useablePrice,
        firstPayment: (15 / 100) * useablePrice,
        secondPayment: (30 / 100) * useablePrice,
        thirdPayment: (40 / 100) * useablePrice,
        fourthPayment: (15 / 100) * useablePrice,
      };
      return prices;
    } else if (paymentPart === "only-3") {
      prices = {
        realPrice,
        offeredPrice,
        useablePrice,
        firstPayment: (25 / 100) * useablePrice,
        secondPayment: (60 / 100) * useablePrice,
        thirdPayment: (15 / 100) * useablePrice,
      };
      return prices;
    } else {
      prices = {
        realPrice,
        offeredPrice,
        useablePrice,
        firstPayment: useablePrice,
      };
      return prices;
    }
  };

  const removeItem = () => {
    setCartData(null);
    localStorage.removeItem("cartData");
  };
  const checkValidityCoupon = (couponDate) => {
    const todayDate = new Date();
    const couponValid = new Date(couponDate);
    if (couponValid > todayDate) {
      return true;
    } else if (couponValid < todayDate) {
      return false;
    }
  };
  const onChangeCoupon = (event) => {
    const value = event.target.value;
    setCouponData({ ...couponData, code: value });
  };
  const applyCode = () => {
    getCoupon({ code }).then((data) => {
      if (data.error) {
        ToastDanger(data.error, 5000);
      } else {
        if (data.success && data.coupon.isActive) {
          if (checkValidityCoupon(data.coupon.validity)) {
            setCouponData({
              ...couponData,
              ["discount"]: data.coupon.discount,
              ["applied"]: true,
              ["invalidCode"]: false,
              ["flat"]: data.coupon.isFlat,
            });
            ToastSuccess("Coupon is applied", 3000);
          } else {
            ToastDanger("Coupon is Expired", 4000);
            setCouponData({
              ...couponData,
              ["applied"]: false,
              ["invalidCode"]: true,
              ["flat"]: false,
            });
          }
        } else {
          setCouponData({
            ...couponData,
            ["applied"]: false,
            ["invalidCode"]: true,
            ["flat"]: false,
          });
          ToastDanger("Coupon is Invalid", 3000);
        }
      }
    });
  };

  const orderData = {
    purchasedPackage: cartData && cartData.packageData._id,
    variantSelected: {
      plotSize: cartData && cartData.variantSelected.area,
      floorCount: cartData && cartData.floorCount.floors,
      designType: cartData && cartData.floorSubCount.sectionDetails,
    },
    note: noteData,
    priceList: {
      orginalPrice: getPriceByPercentage().realPrice,
      purchasedPrice: getPriceByPercentage().useablePrice,
    },
    discountType: {
      status: applied,
      name: applied ? code : "",
    },
    paymentType: paymentPart,
    completePriceList: getPriceByPercentage(),
    orderedBy: userData && userData.user._id,
  };

  const paymentOption = (data) => {
    const options = {
      key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: data.amount ? data.amount : 50000, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Floorplan Bazaar",
      description: cartData.packageData.packageName,
      image:
        "https://res.cloudinary.com/djnv06fje/image/upload/v1598709680/Copy_of_Copy_of_A1_1_hvhw6m.png",
      order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        const userId = userData.user._id;
        const token = userData.token;
        const orderPaymentId = data.id;
        if (orderPaymentId) {
          orderData.paymentId = response.razorpay_payment_id;
          setLoading(true);
          createOrder(orderData, orderPaymentId, userId, token).then((data) => {
            if (data.error) {
              setLoading(false);
              ToastDanger(data.error, 5000);
            } else {
              if (data.success) {
                setSuccessOrder({
                  orderId: data.updateOrder._id,
                  orderStatus: data.updateOrder.status,
                  orderProjectCode: data.updateOrder.projectCode,
                });
                setLoading(false);
                setRedirectPage(true);
                setTimeout(() => {
                  setCartData(null);
                  localStorage.removeItem("cartData");
                }, 1000);
              } else {
                setLoading(false);
                ToastWarning("If order is not found contact support.", 5000);
              }
            }
          });
        } else {
          ToastDanger("Please try to buy again...", 5000);
        }
      },

      prefill: {
        name: userData && userData.user.fullName,
        email: userData && userData.user.email,
        contact: userData && userData.user.mobileNumber,
      },
      modal: {
        ondismiss: function () {
          setLoading(false);
        },
      },
      theme: {
        color: "#3399cc",
      },
    };
    return options;
  };
  const openRzrPay = (event) => {
    const payAmount = getPriceByPercentage().firstPayment;
    const userId = userData && userData.user._id;
    const token = userData && userData.token;
    setLoading(true);
    createPaymentOrder({ payAmount, userId, token }).then((data) => {
      if (data.error) {
        ToastDanger(data.error, 3000);
        console.log(data.error);
        setLoading(false);
      } else {
        setLoading(true);
        const payButton = new Razorpay(paymentOption(data.orderPayment));
        payButton.open();
        event.preventDefault();
      }
    });
  };

  useEffect(() => {}, []);

  const firstBlockCart = (item) => {
    return (
      <IonCard>
        <div className="cart-p-flex">
          <div>
            <IonAvatar>
              <img
                width="40px"
                height="40px"
                src={item.packageData.packageImage.url}
                alt={item.packageData.packageName}
              />
            </IonAvatar>
          </div>
          <div>
            <div className="cart-p-name">
              <p>{item.packageData.packageName}</p>
              <div className="sub-flex">
                <div className="offer-pill">
                  {inr} {offerData.flatValue}
                </div>
                <div className="variant-pill">
                  {item.variantSelected.area} SQ.FT
                </div>
                <div>
                  <AiOutlineClose
                    onClick={(e) => removeItem()}
                    className="remove-icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonCard>
    );
  };

  const secondBlockCart = (item) => {
    if (item.packageType === "only-4") {
      return (
        <IonCard>
          <IonCardHeader className="payment-header">
            <p>PAYMENT SCHEDULE</p>
          </IonCardHeader>
          <IonCardContent className="payment-content">
            <div style={{ paddingTop: "10px" }}>
              <div className="p-payment-box">
                <div className="left-side">COMPLETE PACKAGE</div>
                <div className="right-side">
                  <NumberFormat
                    value={getPriceByPercentage().useablePrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              <div className="p-payment-box">
                <div className="sub-left-side">
                  FIRST PAYMENT <sup>(15%) </sup>
                </div>
                <div className="sub-right-side">
                  <NumberFormat
                    value={getPriceByPercentage().firstPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              <div className="p-payment-box">
                <div className="sub-left-side">
                  SECOND PAYMENT <sup>(30%) </sup>
                </div>
                <div className="sub-right-side">
                  <NumberFormat
                    value={getPriceByPercentage().secondPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              <div className="p-payment-box">
                <div className="sub-left-side">
                  THIRD PAYMENT <sup>(40%) </sup>
                </div>
                <div className="sub-right-side">
                  <NumberFormat
                    value={getPriceByPercentage().thirdPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              <div className="p-payment-box">
                <div className="sub-left-side">
                  FOURTH PAYMENT <sup>(15%) </sup>
                </div>
                <div className="sub-right-side">
                  <NumberFormat
                    value={getPriceByPercentage().fourthPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      );
    } else if (item.packageType === "only-3") {
      return (
        <IonCard>
          <IonCardHeader className="payment-header">
            <p>PAYMENT SCHEDULE</p>
          </IonCardHeader>
          <IonCardContent className="payment-content">
            <div style={{ paddingTop: "10px" }}>
              <div className="p-payment-box">
                <div className="left-side">COMPLETE PACKAGE</div>
                <div className="right-side">
                  <NumberFormat
                    value={getPriceByPercentage().useablePrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              <div className="p-payment-box">
                <div className="sub-left-side">
                  FIRST PAYMENT <sup>(25%) </sup>
                </div>
                <div className="sub-right-side">
                  <NumberFormat
                    value={getPriceByPercentage().firstPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              <div className="p-payment-box">
                <div className="sub-left-side">
                  SECOND PAYMENT <sup>(60%) </sup>
                </div>
                <div className="sub-right-side">
                  <NumberFormat
                    value={getPriceByPercentage().secondPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              <div className="p-payment-box">
                <div className="sub-left-side">
                  THIRD PAYMENT <sup>(15%) </sup>
                </div>
                <div className="sub-right-side">
                  <NumberFormat
                    value={getPriceByPercentage().thirdPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      );
    } else {
      return (
        <IonCard>
          <IonCardHeader className="payment-header">
            <p>PAYMENT SCHEDULE</p>
          </IonCardHeader>
          <IonCardContent className="payment-content">
            <div style={{ paddingTop: "10px" }}>
              <div className="p-payment-box">
                <div className="left-side">COMPLETE PACKAGE</div>
                <div className="right-side">
                  <NumberFormat
                    value={getPriceByPercentage().useablePrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              <div className="p-payment-box">
                <div className="sub-left-side">
                  FULL PAYMENT <sup>(100%) </sup>
                </div>
                <div className="sub-right-side">
                  <NumberFormat
                    value={getPriceByPercentage().firstPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      );
    }
  };

  const thirdBlockCart = (item) => {
    return (
      <IonCard>
        <IonCardHeader className="payment-header">
          <p>PRICE SUMMARY</p>
        </IonCardHeader>
        <IonCardContent className="payment-content">
          <div className="price-summary-box">
            {item.packageType === "only-1" ? (
              <>
                <div className="ps-header">PAY FULL PAYMENT TO START WORK</div>
                <div className=" c-ps-box">
                  <div className="left-side">COMPLETE PAYMENT</div>
                  <div className="right-side">
                    <NumberFormat
                      value={getPriceByPercentage().firstPayment}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={inr}
                      renderText={(value) => <>{value}</>}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="ps-header">
                  PAY FIRST PAYMENT TO START YOUR WORK
                </div>
                <div className=" c-ps-box">
                  <div className="left-side">FIRST PAYMENT</div>
                  <div className="right-side">
                    <NumberFormat
                      value={getPriceByPercentage().firstPayment}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={inr}
                      renderText={(value) => <>{value}</>}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="ps-inner-box">
              <div className="ps-flex">
                <div className="ps-right-text">Orignal price:</div>
                <div className="ps-left-text">
                  {" "}
                  <NumberFormat
                    value={getPriceByPercentage().realPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
              {offerData.isValid ? (
                <div className="ps-flex">
                  <div className="ps-right-text">Offer Discount:</div>
                  <div className="ps-left-text">
                    {" "}
                    -{" "}
                    <NumberFormat
                      value={offerData.flatValue}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={inr}
                      renderText={(value) => <>{value}</>}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {applied ? (
                <>
                  {flat ? (
                    <div className="ps-flex">
                      <div className="ps-right-text">Coupon Discount:</div>
                      <div className="ps-left-text">
                        -{" "}
                        <NumberFormat
                          value={discount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={inr}
                          renderText={(value) => <>{value}</>}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="ps-flex">
                      <div className="ps-right-text">Coupon Discount:</div>
                      <div className="ps-left-text">
                        -{" "}
                        <NumberFormat
                          value={
                            (discount / 100) *
                            getPriceByPercentage().offeredPrice
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={inr}
                          renderText={(value) => <>{value}</>}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
              <hr
                style={{ height: "1.4px", background: "#777", margin: "2px 0" }}
              ></hr>
              <div className="ps-flex">
                <div className="ps-right-text">Final Price:</div>
                <div className="ps-left-text">
                  <NumberFormat
                    value={getPriceByPercentage().useablePrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={inr}
                    renderText={(value) => <>{value}</>}
                  />
                </div>
              </div>
            </div>
            <div className="any-note">
              <IonTextarea
                onIonChange={(e) => setNoteData(e.target.value)}
                className="any-note-input"
                rows={3}
                placeholder="Any Note ...."
              />
            </div>

            {item.packageData.paymentType === "only-1" ? (
              ""
            ) : (
              <div className="coupon-box">
                <div className="coupon-input-box">
                  <IonInput
                    onIonChange={(e) => onChangeCoupon(e)}
                    className="coupon-input"
                    type="text"
                    placeholder="Apply Coupon Code"
                  />
                  <IonButton
                    onClick={applyCode}
                    className="coupon-button"
                    size="small"
                  >
                    Apply
                  </IonButton>
                </div>
              </div>
            )}

            <div className="agree-tg-box">
              <p style={{ margin: "0", fontSize: "12px" }}>
                <IonCheckbox
                  color="secondary"
                  onIonChange={(e) => {
                    settcChecked(!tcChecked);
                  }}
                  checked={tcChecked}
                  style={{ marginBottom: "-4px", marginRight: "4px" }}
                />
                I Have read all{" "}
                <Link
                  to="/terms-and-conditions"
                  style={{
                    fontSize: "12px",
                    color: "#f5a947",
                    textDecoration: "none",
                  }}
                >
                  Terms of Use and Terms of Service
                </Link>{" "}
                carefully and I totally agree with it.
              </p>
            </div>
          </div>
        </IonCardContent>
      </IonCard>
    );
  };
  const bottomPaymentBox = (item) => {
    return (
      <div className="bottom-pay-box">
        <div className="bottom-pay-inner">
          <div className="bpay-flex">
            <div className="pay-price-text">
              <NumberFormat
                value={getPriceByPercentage().firstPayment}
                displayType={"text"}
                thousandSeparator={true}
                prefix={inr}
                renderText={(value) => <>{value}</>}
              />
            </div>
            <div>
              {userData ? (
                <button
                  className={tcChecked ? "pay-now" : "pay-now-dis"}
                  disabled={tcChecked ? false : true}
                  onClick={openRzrPay}
                >
                  PAY NOW
                </button>
              ) : (
                <Link to={`/login/${"cart"}`} className="login-redirect-cart">
                  LOGIN{" "}
                  <IonIcon
                    style={{
                      marginLeft: "3px",
                      marginBottom: "-4px",
                      fontSize: "18px",
                    }}
                    icon={arrowForwardOutline}
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const redirectToSuccess = () => {
    if (redirectPage) {
      return <Redirect to="/successfull-order" />;
    }
  };

  return (
    <div>
      {redirectToSuccess()}
      <div>
        {cartData ? (
          <div className="checkout-main-box">
            {loading ? (
              <IonLoading
                cssClass="my-custom-class"
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={"Loading...."}
              />
            ) : (
              ""
            )}

            <>
              {firstBlockCart(cartData)}
              {secondBlockCart(cartData)}
              {thirdBlockCart(cartData)}
              {bottomPaymentBox(cartData)}
            </>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default withRouter(Checkout);

import {
  IonPage,
  IonButtons,
  IonBackButton,
  IonToolbar,
  IonContent,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonImg,
  IonItem,
  IonInput,
  IonButton,
} from "@ionic/react";
import { FaLock } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import {
  ToastDanger,
  ToastSuccess,
  ToastWarning,
} from "../../components/Toaster";
import { resetPassword } from "./ApisUsers";

import React, { useState, useGlobal } from "reactn";

const ResetLink = (props) => {
  const [password, setPassword] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);

  const [loading, setLoading] = useGlobal("loading");
  const [ShowPassword, setShowPassword] = useState(false);
  const onInputChange = (event) => {
    const value = event.target.value;
    setPassword(value);
  };

  const changeback = (id) => {
    const el = document.getElementById(id);
    el.style.border = "2px #50b6cf solid";
    el.style.borderRadius = "5px";
  };
  const changebackBlur = (id) => {
    const el = document.getElementById(id);
    el.style.border = "2px rgb(209, 209, 209) solid";
    el.style.borderRadius = "5px";
  };

  const isValid = () => {
    if (password.length <= 0) {
      ToastWarning("Password must required.");
      setLoading(false);

      return false;
    } else if (password.length < 8) {
      ToastWarning("Password must be strong.");
      setLoading(false);

      return false;
    }
    return true;
  };

  const resetPasswordSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (isValid()) {
      resetPassword({
        newPassword: password,
        resetPasswordLink: props.match.params.resetPasswordToken,
      }).then((data) => {
        if (data.error) {
          ToastDanger(data.error);
          setLoading(false);
        } else {
          ToastSuccess(data.message, 4000);
          setPassword("");
          setLoading(false);
          setRedirectTo(true);
        }
      });
    }
  };

  const RedirectPage = () => {
    if (redirectTo) {
      return <Redirect to="/login" />;
    }
  };
  return (
    <IonPage>
      {RedirectPage()}
      <IonToolbar className="default-header">
        <IonButtons slot="start">
          <IonBackButton color="dark" defaultHref="/" />
        </IonButtons>
      </IonToolbar>

      <IonContent>
        {" "}
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Reseting your password..."}
        />{" "}
        <IonGrid className="login-page">
          <div className="box-1"></div>
          <div className="box-2"></div>
          <div className="box-3"></div>
          <div className="box-4"></div>
          <IonRow className="login-row">
            <IonCol className="login-col">
              <>
                <IonCard className="login-card">
                  <div
                    style={{ paddingTop: "16px" }}
                    className="ion-text-center"
                  >
                    <IonImg
                      style={{ height: "60px" }}
                      src="https://res.cloudinary.com/djnv06fje/image/upload/v1598709680/Copy_of_Copy_of_A1_1_hvhw6m.png"
                    />
                  </div>
                  <IonCardContent>
                    <div className="ion-text-left  login-header">
                      <p style={{ fontSize: "14px" }}>
                        PLEASE ENTER YOUR NEW PASSWORD
                      </p>
                    </div>
                    <form onSubmit={resetPasswordSubmit}>
                      <IonItem
                        style={{ border: "solid 2px rgb(209, 209, 209)" }}
                        lines="none"
                        className="input-box"
                        id="ipbox2"
                      >
                        <FaLock className="login-logo" slot="start" />
                        <div>
                          <IonInput
                            clear-input="true"
                            onFocus={() => changeback("ipbox2")}
                            onBlur={() => changebackBlur("ipbox2")}
                            onIonChange={(e) => onInputChange(e)}
                            type={ShowPassword ? "text" : "password"}
                            placeholder="Enter New Password"
                            className="login-input"
                          />
                        </div>
                        <div slot="end">
                          {!ShowPassword ? (
                            <AiFillEyeInvisible
                              onClick={() => {
                                setShowPassword(true);
                              }}
                              className="password-logo"
                            />
                          ) : (
                            <AiFillEye
                              onClick={() => {
                                setShowPassword(false);
                              }}
                              className="password-logo"
                            />
                          )}
                        </div>
                      </IonItem>
                      <div className="ion-text-right login-button">
                        <IonButton className="btn" expand="full" type="submit">
                          SUBMIT
                        </IonButton>
                      </div>
                    </form>
                  </IonCardContent>
                </IonCard>
              </>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ResetLink;

import {
  IonContent,
  IonModal,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useState, useGlobal, useEffect } from "reactn";
import { FaHandPointDown } from "react-icons/fa";
import { Redirect, withRouter } from "react-router";
import { AiOutlineInfoCircle } from "react-icons/ai";

const BottomPriceModal = (props) => {
  const [redirectPage, setRedirectPage] = useState(false);
  const [totalPrice, setTotalPrice] = useState();

  const [plotSelectShow, setPlotSelectShow] = useGlobal("plotSelectShow");
  const [packageData, setPackageData] = useGlobal("packageData");
  const [variantSelected, setVariantSelected] = useGlobal("variant");
  const [floorCount, setFloorCount] = useGlobal("floorCount");
  const [floorSubCount, setFloorSubCount] = useGlobal("floorSubCount");
  const [cartPackage, setCartPackage] = useGlobal("cartData");

  const priceChangeControl = () => {
    let price =
      parseInt(variantSelected.price) + parseInt(floorSubCount.extraCharge);
    setTotalPrice(price);
    let cartData = {
      packageData: packageData,
      packageType: packageData.paymentType,

      variantSelected: variantSelected,
      floorCount: floorCount,
      floorSubCount: floorSubCount,
      packageTotalPrice: parseInt(price),
    };

    setVariantSelected(null);
    setFloorCount(null);
    setFloorSubCount(null);
    setCartPackage(cartData);
    localStorage.setItem("cartData", JSON.stringify(cartData));
    props.history.push("/cart");
    setPlotSelectShow(false);
  };

  const handleRedirectCart = (selectedOption) => {
    setFloorSubCount(selectedOption);
    let price =
      parseInt(variantSelected.price) + parseInt(selectedOption.extraCharge);
    setTotalPrice(price);
    let cartData = {
      packageData: packageData,
      packageType: packageData.paymentType,
      variantSelected: variantSelected,
      floorCount: floorCount,
      floorSubCount: selectedOption,
      packageTotalPrice: parseInt(price),
    };
    setVariantSelected(null);
    setFloorCount(null);
    setFloorSubCount(null);
    setCartPackage(cartData);
    localStorage.setItem("cartData", JSON.stringify(cartData));
    props.history.push("/cart");
    setPlotSelectShow(false);
  };
  useEffect(() => {
    if (variantSelected && floorCount && floorSubCount) {
      priceChangeControl();
    }
  }, [variantSelected, floorCount]);

  const lastQuestion = (stringData) => {
    let string = stringData.split("-");
    return string[1];
  };

  const redirectToCartForGorundOnly = () => {
    props.history.push("/cart");
  };

  return (
    <IonModal
      onDidDismiss={() => setPlotSelectShow(false)}
      cssClass={!variantSelected ? "my-custom-class-3" : "my-custom-class-4"}
      isOpen={plotSelectShow}
    >
      <IonContent>
        <div className="p-modal-header">
          <div>
            <p>PLEASE SELECT OPTIONS</p>
          </div>
          <div>
            <IonIcon
              onClick={(e) => setPlotSelectShow(false)}
              className="p-close-btn"
              icon={closeOutline}
            />
          </div>
        </div>
        <div className="p-content-block">
          <div className="p-step-one" style={{ paddingBottom: "25px" }}>
            <div className="steps-heading">
              <p>Select Plot Range </p>
            </div>
            <hr></hr>

            <IonGrid>
              <IonRow>
                {packageData.variants.map((v, i) => (
                  <div style={{ margin: "0 auto" }} className="p-variant-box">
                    <IonCol key={i} size={4}>
                      <button
                        style={{
                          background:
                            variantSelected && variantSelected._id === v._id
                              ? "#f5a947"
                              : "#50b6cf",
                        }}
                        onClick={(e) => {
                          setVariantSelected(v);
                        }}
                        className="p-variant-btn"
                      >
                        {v.area} SQ.FT
                      </button>
                    </IonCol>
                  </div>
                ))}{" "}
              </IonRow>
            </IonGrid>
          </div>
          {variantSelected ? (
            <div className="p-step-two" style={{ paddingBottom: "25px" }}>
              <div className="steps-heading">
                <p>House plan for how many floors ?</p>
              </div>
              <hr></hr>
              <IonGrid>
                <IonRow>
                  {packageData.floorCounts.map((v, i) => (
                    <div style={{ margin: "0 auto" }} className="p-variant-box">
                      <IonCol key={i} size={4}>
                        <button
                          onClick={(e) => {
                            setFloorCount(v);
                          }}
                          style={{
                            background:
                              floorCount && floorCount._id === v._id
                                ? "#f5a947"
                                : "#efefef",
                            color:
                              floorCount && floorCount._id === v._id
                                ? "#fff"
                                : "#4b4b4b",
                          }}
                          className="p-question-btn"
                        >
                          {v.floors}
                        </button>
                      </IonCol>
                    </div>
                  ))}{" "}
                </IonRow>
              </IonGrid>
            </div>
          ) : (
            ""
          )}
          {floorCount ? (
            floorCount.floors === "GROUND FLOOR ONLY" ? (
              <div className="p-step-three">
                <div className="steps-heading">
                  <p>There is no further questions. Please proceed.</p>
                </div>
                <hr></hr>
                <IonGrid>
                  <IonRow>
                    {floorCount.optionSelections.map((v, i) => (
                      <div className="p-variant-box">
                        <IonCol style={{ margin: "0 auto" }} key={i} size={4}>
                          <button
                            onClick={(e) => {
                              handleRedirectCart(v);
                            }}
                            className=" buy-btn"
                          >
                            Go to Cart
                          </button>{" "}
                        </IonCol>
                      </div>
                    ))}{" "}
                  </IonRow>{" "}
                </IonGrid>
              </div>
            ) : (
              <div className="p-step-three">
                <div className="steps-heading">
                  <p>The design for all floors will be same ?</p>
                </div>
                <hr></hr>
                <IonGrid>
                  <IonRow>
                    {floorCount.optionSelections.map((v, i) => (
                      <div className="p-variant-box">
                        <IonCol style={{ margin: "0 auto" }} key={i} size={4}>
                          <button
                            style={{
                              background:
                                floorSubCount && floorSubCount._id === v._id
                                  ? "#f5a947"
                                  : "#efefef",
                              color:
                                floorSubCount && floorSubCount._id === v._id
                                  ? "#fff"
                                  : "#4b4b4b",
                            }}
                            onClick={(e) => {
                              handleRedirectCart(v);
                            }}
                            className="p-question-btn p-line-height"
                          >
                            {lastQuestion(v.sectionDetails)}
                          </button>{" "}
                        </IonCol>
                      </div>
                    ))}{" "}
                  </IonRow>{" "}
                </IonGrid>
                {/* 
                <div className="note-box">
                  <div>
                    <AiOutlineInfoCircle className="info-icon" />{" "}
                    <span>
                      If the Floor plan layout shall be of a single unit of
                      1/2/3/4/5 BHK on every floor of the building with
                      consistent design -<b> (Select YES)</b>.
                    </span>
                  </div>{" "}
                  <div>
                    <AiOutlineInfoCircle className="info-icon" />{" "}
                    <span>
                      If the Floor plan layout shall be of single/multiple units
                      of 1/2/3/4/5 BHK on every floor of the building is not
                      consistent design - <b>(Select NO)</b>.
                    </span>
                  </div>
                </div> */}
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default withRouter(BottomPriceModal);

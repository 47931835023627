import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { chevronUpOutline } from "ionicons/icons";
import React, { useRef } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div style={{ paddingBottom: "8px" }} className="footer-block">
      <div className="ion-text-center">
        <div className="toll-free">
          <h5>TOLL FREE NUMBER</h5>
          <h4>1800-419-7707</h4>
        </div>
      </div>
      <IonGrid>
        <IonRow>
          <IonCol size={4}>
            <div className="footer-heading">
              <p> INFO </p>
            </div>
            <div className="footer-flex-box">
              <Link className="footer-link" to="/about-us">
                about us
              </Link>
              <Link className="footer-link" to="/contact-us">
                contact us
              </Link>{" "}
              <Link className="footer-link" to="/">
                Career
              </Link>
            </div>
          </IonCol>
          <IonCol size={4}>
            <div className="footer-heading">
              <p>PACKAGES</p>
            </div>
            <div className="footer-flex-box">
              <Link
                className="footer-link"
                to={`/package/${"luxury house design package"}`}
              >
                luxury
              </Link>
              <Link
                className="footer-link"
                to={`/package/${"modern house design package"}`}
              >
                modern
              </Link>
              <Link
                className="footer-link"
                to={`/package/${"floor plan package"}`}
              >
                floor plan
              </Link>
            </div>
          </IonCol>
          <IonCol size={4}>
            <div className="footer-heading">
              <p>FOLLOW US</p>
            </div>
            <div className="footer-flex-box">
              <a
                className="footer-link"
                href="https://www.youtube.com/channel/UCgSvEsQxUJpx6bIswJHcQ5w?view_as=subscriber"
              >
                youtube
              </a>
              <a
                className="footer-link"
                href="https://www.instagram.com/floorplanbazaar/"
              >
                instagram
              </a>
              <a
                className="footer-link"
                href="https://www.facebook.com/floorplanbazaar/"
              >
                facebook
              </a>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="copyright ion-text-center">
        <p>
          Copyright © FloorplanBazaar.com. A division of Naksha Bazaar Pvt.Ltd.{" "}
          <br></br>All Rights Reserved.
        </p>
      </div>

      <div
        className="footer-flex-box"
        style={{ flexDirection: "row", justifyContent: "space-around" }}
      >
        <Link className="footer-link" to={`/terms-and-conditions`}>
          Term of use & Service
        </Link>

        <Link className="footer-link" to={`/privacy-policy`}>
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default Footer;

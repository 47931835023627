import { API } from "../../Config";
import Cookies from "js-cookie";

export const userRegister = (userData) => {
  return fetch(`${API}/register`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const userLogin = (userData) => {
  return fetch(`${API}/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateUser = (user, next) => {
  if (typeof window !== "undefined") {
    if (Cookies.get("userData")) {
      let auth = JSON.parse(Cookies.get("userData"));
      auth.user = user;
      Cookies.set("userData", JSON.stringify(auth), { expires: 10 });
      next();
    }
  }
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    Cookies.set("userData", JSON.stringify(data), { expires: 10 });
    next();
  }
};

export const forgotPassword = (forgetData) => {
  return fetch(`${API}/forgot-password`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ forgetData }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const resetPassword = (resetInfo) => {
  return fetch(`${API}/reset-password/`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(resetInfo),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUserById = ({ userId, token }) => {
  return fetch(`${API}/user-by-id/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateUserInfo = (userData, { userId, token }) => {
  return fetch(`${API}/update-user/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const orderLists = ({ userId, token }) => {
  return fetch(`${API}/order-by-user/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const orderById = ({ userId, orderId, token }) => {
  return fetch(`${API}/order-by-user/${userId}/${orderId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const createAddonOrder = ({ amount, userId, token }) => {
  return fetch(`${API}/create-order-payment-addon/${amount}/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const createAddons = ({
  addonData,
  userId,
  token,
  orderPaymentId,
  orderId,
}) => {
  return fetch(`${API}/add-addons/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ addonData, orderPaymentId, orderId }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const createRazorpayRemaingOrder = ({ amount, userId, token }) => {
  return fetch(`${API}/create-order-remaining-payments/${amount}/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const paymentUpdate = ({
  paymentData,
  userId,
  token,
  orderPaymentId,
  orderId,
  paymentId,
}) => {
  console.log(userId);
  return fetch(`${API}/payment-update/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ paymentData, orderPaymentId, orderId, paymentId }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const ToastWarning = (msg) => {
  const toast = document.createElement("ion-toast");
  toast.message = msg;
  toast.color = "warning";
  toast.position = "top";
  toast.style.color = "rgb(62, 62, 62)";
  toast.duration = 2000;
  document.body.appendChild(toast);
  return toast.present();
};

export const ToastSuccess = (msg, time) => {
  const toast = document.createElement("ion-toast");
  toast.message = msg;
  toast.color = "success";
  toast.position = "top";
  toast.style.color = "#fff";
  toast.duration = time || 2000;
  document.body.appendChild(toast);
  return toast.present();
};

export const ToastDanger = (msg) => {
  const toast = document.createElement("ion-toast");
  toast.message = msg;
  toast.color = "danger";
  toast.position = "top";
  toast.duration = 2000;
  document.body.appendChild(toast);
  return toast.present();
};

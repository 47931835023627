import {
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonCard,
  IonImg,
  IonLoading,
  IonCardContent,
} from "@ionic/react";
import React, { useGlobal, useEffect } from "reactn"; // <-- reactn
import { AiFillCamera } from "react-icons/ai";
import axios from "axios";
import { API } from "../../Config";
import { updateUser } from "./ApisUsers";
import {
  ToastDanger,
  ToastWarning,
  ToastSuccess,
} from "../../components/Toaster";
import { Link } from "react-router-dom";
import { logOut } from "ionicons/icons";
import Cookies from "js-cookie";

import OrderLists from "./Orders";

const Profile = () => {
  const [quote, setQuotes] = useGlobal("quote");
  const [userData, setuserData] = useGlobal("userData");
  const [loading, setLoading] = useGlobal("loading");
  const user = userData && userData.user;
  const openImageUpload = () => {
    const uploader = document.getElementById("user-photo-upload");
    uploader.click();
  };

  const handleChange = (event) => {
    let formData = new FormData();
    const file = event.target.files[0];
    formData.set("userPhoto", file);
    setLoading(true);
    const options = {
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
      url: `${API}/user-photo-upload/${userData.user._id}`,
    };
    axios
      .request(options)
      .then(function (response) {
        updateUser(response.data.user, () => {
          let newUpdatePhoto = userData;
          newUpdatePhoto.user = response.data.user;
          setuserData(newUpdatePhoto);
          setLoading(false);
        });
      })
      .catch(function (error) {
        console.log(error);
        ToastDanger(error);
        setLoading(false);
      });
  };

  const logoutHandler = () => {
    console.log("log out");
    Cookies.remove("userData");
    setuserData("");
    window.location.reload();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{ marginTop: "0px" }} className="header">
          <IonButtons slot="start">
            <IonBackButton color="dark" defaultHref="/" />
          </IonButtons>
          <IonTitle>Your's Profile</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Updating Your Profile ...."}
        />
        <div className="profile-page-box">
          <h3 className="quote-header">&rdquo;{quote && quote}&rdquo;</h3>
          <div className="pi-card-box">
            <IonCard className="pi-card">
              {user.userPhoto && user.userPhoto.url ? (
                <div className=" p-image ion-text-center">
                  <img
                    className="p-image-1"
                    alt={user.fullName}
                    src={user.userPhoto.url}
                  />
                  <input
                    onChange={(e) => handleChange(e)}
                    id="user-photo-upload"
                    type="file"
                    accept="image/*"
                  />
                  <div
                    onClick={() => {
                      openImageUpload();
                    }}
                    className="upload-logo"
                  >
                    <AiFillCamera className="camera-icon" />
                  </div>
                </div>
              ) : (
                <div className=" p-image ion-text-center">
                  <img
                    className="p-image-1"
                    alt={user.fullName}
                    src={`https://ui-avatars.com/api/?name=${user.fullName}&background=50b6cf&color=fff&bold=true&rounded=true&size=420`}
                  />
                  <input
                    onChange={(e) => handleChange(e)}
                    id="user-photo-upload"
                    type="file"
                    accept="image/*"
                  />
                  <div
                    onClick={() => {
                      openImageUpload();
                    }}
                    className="upload-logo"
                  >
                    <AiFillCamera className="camera-icon" />
                  </div>
                </div>
              )}

              <IonCardContent className="pi-card-body">
                <div className="pi-card-info-box">
                  <div className="pi-header-text">
                    <h3>Your Informations</h3>
                    <hr></hr>
                  </div>

                  <div className="pi-info-1 margin">
                    <p>
                      Full Name: <span>{user.fullName}</span>
                    </p>
                  </div>

                  <div className="pi-info-1">
                    <p>
                      User Id: <span>{user._id}</span>
                    </p>
                  </div>

                  <div className="pi-info-1">
                    <p>
                      Mobile Number: <span>{user.mobileNumber}</span>
                    </p>
                  </div>

                  <div className="pi-info-1">
                    <p>
                      Email: <span>{user.email}</span>
                    </p>
                  </div>

                  <div className="pi-info-1">
                    <p>
                      Secondary Mobile No:{" "}
                      <span>
                        {!user.secondaryNumber ? (
                          "No Number"
                        ) : (
                          <>{user.secondaryNumber}</>
                        )}
                      </span>
                    </p>
                  </div>

                  <div className="btn-flex-group">
                    <button onClick={logoutHandler} className="btn-log-out">
                      LOGOUT
                    </button>
                    <Link className="btn-update" to="/update-profile">
                      UPDATE
                    </Link>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>

            <OrderLists />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Profile;

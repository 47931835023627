import { setGlobal } from "reactn";
import addReactNDevTools from "reactn-devtools";
import Cookies from "js-cookie";

export const reactData = () => {
  const userInfoFromStorage = Cookies.get("userData")
    ? JSON.parse(Cookies.get("userData"))
    : null;
  const userCartDataFromStorage = localStorage.getItem("cartData")
    ? JSON.parse(localStorage.getItem("cartData"))
    : null;

  setGlobal({
    userData: userInfoFromStorage,
    loading: false,
    error: null,
    quote: "The way is not in the sky. The way is in the heart.",
    packageData: null,
    variant: null,
    plotSelectShow: false,
    floorCount: null,
    floorSubCount: null,
    cartData: userCartDataFromStorage,
    successOrder: null,
    orderLists: null,
    paymentPart: null,
    pageTrack: [],
  });
  addReactNDevTools();
};

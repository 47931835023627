import {
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonToolbar,
  IonImg,
  IonIcon,
} from "@ionic/react";
import React, { useGlobal, useState, useEffect, useRef } from "reactn";
import axios from "axios";
import Typed from "react-typed";
import Fade from "react-reveal/Fade";
import ReactPlayer from "react-player/youtube";
import Slider from "react-slick";
import {
  AiOutlineMessage,
  AiOutlinePhone,
  AiOutlineShoppingCart,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { Link, withRouter } from "react-router-dom";
import { chevronUpOutline } from "ionicons/icons";
import { ToastWarning } from "../../components/Toaster";
import { orderLists } from "../user/ApisUsers";
import WebsiteLogo from "../../assests/rsz_fpb_-_logo_-_hd_mogljv.png";

import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { updateTrackPage } from "./ApisCore";
const Index = () => {
  const contentRef = useRef();
  ReactGA.initialize("UA-178240108-1");
  const location = useLocation();

  const [quote, setQuotes] = useGlobal("quote");
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useGlobal("userData");
  const [orderList, setOrderList] = useGlobal("orderLists");

  const [pageTrack, setPageTrack] = useGlobal("pageTrack");

  useEffect(() => {
    if (user && user.user) {
      console.log("Current Route", location.pathname);
      const userId = user.user._id;
      const token = user.token;
      updateTrackPage({ userId, token }, location.pathname).then((data) => {
        console.log(data);
      });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    if (user) {
      getOrders();
    }
    getQuotes();
  }, []);
  const getOrders = () => {
    const userId = user.user._id;
    const token = user.token;
    orderLists({
      userId,
      token,
    }).then((data) => {
      if (data.error) {
        console.log(data.error);
        ToastWarning(data.error);
      } else {
        setOrderList(data.order);
      }
    });
  };

  const getQuotes = () => {
    const options = {
      method: "GET",
      url: "https://api.quotable.io/random?maxLength=70",
    };
    axios
      .request(options)
      .then(function (response) {
        setQuotes(response.data.content);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const playicon = () => {
    return (
      <img
        height="50px"
        src="https://res.cloudinary.com/djnv06fje/image/upload/v1595606139/play_pegoyo.png"
        alt=""
      />
    );
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    fade: true,
    adaptiveHeight: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <IonPage>
      {loading ? (
        <div className="loading-home-page">
          <div className="home-loader">
            <img className="logo-loader-blink" src={WebsiteLogo} alt="" />
          </div>
        </div>
      ) : (
        ""
      )}
      <IonHeader>
        <IonToolbar className="header">
          <div>
            <img src={WebsiteLogo} alt="" width="110" />
          </div>
          <div slot="end">
            <Link to="/cart">
              <AiOutlineShoppingCart className="cart-icon" />
            </Link>
          </div>
          <IonButtons className="menu-btn" slot="end">
            <IonMenuButton>
              <img
                src="https://res.cloudinary.com/djnv06fje/image/upload/v1611385435/menu_trdtjy.svg"
                alt=""
              />
            </IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} className="">
        <div className="home-page-main">
          <div
            style={{
              display: loading ? "none" : "block",
            }}
            className="hp-section-1"
          >
            <div className="hp-section-11">
              <h3> ARCHITECTURE SERVICES ONLINE </h3>
              <h6>
                <Typed
                  strings={[
                    "ONE STOP SOLUTION FOR ALL YOUR CONSTRUCTION PROJECT NEEDS",
                  ]}
                  typeSpeed={40}
                  backSpeed={20}
                  loop
                />
              </h6>
            </div>
            <hr className="hr-line" />
            <div className="hp-section-12">
              <Fade delay={500} bottom>
                <IonCard className="hp-section-12-card first-card">
                  <IonGrid>
                    <IonRow>
                      <IonCol size={4}>
                        <div className="card-logo-box">
                          <img
                            className="hp-section-1-logo"
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1595604329/floor-plan_pv0z5j.png"
                            alt=""
                          />
                        </div>
                      </IonCol>
                      <IonCol size={8}>
                        <div className="card-table">
                          <ul>
                            <li> Floor Planning </li>
                            <li> Electrical Layout </li>
                            <li> Plumbing Layout </li>
                            <li> Door - Window Layout </li>
                          </ul>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              </Fade>
              <Fade delay={1000} bottom>
                <IonCard className="hp-section-12-card second-card">
                  <IonGrid>
                    <IonRow>
                      <IonCol size={4}>
                        <div className="card-logo-box">
                          <img
                            className="hp-section-1-logo"
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1595604329/engineer_jyvokp.png"
                            alt=""
                          />
                        </div>
                      </IonCol>
                      <IonCol size={8}>
                        <div className="card-table">
                          <ul>
                            <li> 3 D Front Elevation </li>
                            <li> 3 D Walk - Thru Video </li>
                            <li> 3 D Interior Video </li>
                            <li> 3 D Floor Plans </li>
                            <li> Township Video Tour </li>
                            <li> Apartments Interior Tour </li>
                          </ul>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              </Fade>
              <Fade delay={1500} bottom>
                <IonCard className="hp-section-12-card third-card">
                  <IonGrid>
                    <IonRow>
                      <IonCol size={4}>
                        <div className="card-logo-box">
                          <img
                            className="hp-section-1-logo"
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1595604329/architecture_xawrng.png"
                            alt=""
                          />
                        </div>
                      </IonCol>
                      <IonCol size={8}>
                        <div className="card-table">
                          <ul>
                            <li> Structure Drawings </li>
                            <li> Column Layout </li> <li> Foundation Plan </li>
                            <li> Beam Layout </li> <li> Slab Layout </li>
                          </ul>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              </Fade>
            </div>
            <div className="hp-section-13">
              <div className="video-block">
                <Fade delay={2500} bottom>
                  <IonCard className="video-card-block">
                    <ReactPlayer
                      width="auto"
                      height="100%"
                      playing
                      controls
                      light="https://res.cloudinary.com/djnv06fje/image/upload/v1574951107/undraw_under_construction_46pa_1_luwjkr.svg"
                      playIcon={playicon()}
                      url="https://youtu.be/P93h8RIver8"
                    />
                  </IonCard>
                </Fade>
              </div>
            </div>
          </div>
          <div className="hp-section-2">
            <div className="hp-section-21">
              <Link to={`/package/${"floor plan package"}`}>
                <IonCard className="banner-card">
                  <IonImg src="https://res.cloudinary.com/djnv06fje/image/upload/v1615197548/FINAL-3_xycd1b.png" />
                </IonCard>
              </Link>
            </div>
            <div className="hp-section-22">
              <Slider {...settings}>
                <IonImg
                  className="slider-img"
                  src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243615/slider-1/1_fbtlfh.png"
                  alt=""
                />
                <IonImg
                  className="slider-img"
                  src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243616/slider-1/4_fwmnnq.png"
                  alt=""
                />
                <IonImg
                  className="slider-img"
                  src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243615/slider-1/5_ospekc.png"
                  alt=""
                />

                <IonImg
                  className="slider-img"
                  src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243616/slider-1/3_jjioze.png"
                  alt=""
                />
              </Slider>
            </div>
            <div className="hp-section-23">
              <div className="hp-section-23-inner">
                <div className="upper-package-img">
                  <IonCard className="package-card-1">
                    <Link to={`/package/${"modern house design package"}`}>
                      <IonImg src="https://res.cloudinary.com/djnv06fje/image/upload/v1612717724/4_ngipzi.png" />
                    </Link>
                  </IonCard>
                </div>
                <div className="dream-banner">
                  <IonImg src="https://res.cloudinary.com/djnv06fje/image/upload/v1608027054/bannertwo_alpgya.png" />
                </div>
                <div className="upper-package-img">
                  <IonCard className="package-card-2">
                    <Link
                      style={{
                        margin: "0",
                      }}
                      to={`/package/${"luxury house design package"}`}
                    >
                      <IonImg src="https://res.cloudinary.com/djnv06fje/image/upload/v1612717742/3_fbow5k.png" />
                    </Link>
                  </IonCard>
                </div>
              </div>
            </div>
            <div className="fourth-section">
              <div className="slider-third">
                <Slider className="slider-main" {...settings}>
                  <IonImg
                    className="slider-img"
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243658/slider-1/1_tfl68h.png"
                  />

                  <IonImg
                    className="slider-img"
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243978/slider-1/8_qzku5i.png"
                  />

                  <IonImg
                    className="slider-img"
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243978/slider-1/9_okfueu.png"
                  />

                  <IonImg
                    className="slider-img"
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243614/slider-1/7_m6p188.png"
                  />

                  <IonImg
                    className="slider-img"
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243615/slider-1/2_ivux9j.png"
                  />

                  <IonImg
                    className="slider-img"
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243616/slider-1/6_iicpjo.png"
                  />

                  <IonImg
                    className="slider-img"
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1597243658/slider-1/2_wbt05g.png"
                  />
                </Slider>
              </div>
              <div className="compare-block">
                <IonImg src="https://res.cloudinary.com/djnv06fje/image/upload/v1612717737/5_eyddsb.png" />
              </div>
            </div>
          </div>
          <div className="footer-block">
            <div className="ion-text-center">
              <IonIcon
                onClick={(e) => {
                  contentRef.current &&
                    contentRef.current.scrollToTop({
                      top: 800,
                      behavior: "smooth",
                    });
                }}
                icon={chevronUpOutline}
                className="upward-icon"
              />
              <div className="toll-free">
                <h5> TOLL FREE NUMBER </h5> <h4> 1800 - 419 - 7707 </h4>
              </div>
            </div>
            <IonGrid>
              <IonRow>
                <IonCol size={4}>
                  <div className="footer-heading">
                    <p> INFO </p>
                  </div>
                  <div className="footer-flex-box">
                    <Link className="footer-link" to="/about-us">
                      about us
                    </Link>
                    <Link className="footer-link" to="/contact-us">
                      contact us
                    </Link>{" "}
                    <Link className="footer-link" to="/">
                      Career
                    </Link>
                  </div>
                </IonCol>
                <IonCol size={4}>
                  <div className="footer-heading">
                    <p> PACKAGES </p>
                  </div>
                  <div className="footer-flex-box">
                    <Link
                      className="footer-link"
                      to={`/package/${"luxury house design package"}`}
                    >
                      luxury
                    </Link>
                    <Link
                      className="footer-link"
                      to={`/package/${"modern house design package"}`}
                    >
                      modern
                    </Link>
                    <Link
                      className="footer-link"
                      to={`/package/${"floor plan package"}`}
                    >
                      floor plan
                    </Link>
                  </div>
                </IonCol>
                <IonCol size={4}>
                  <div className="footer-heading">
                    <p> FOLLOW US </p>
                  </div>
                  <div className="footer-flex-box">
                    <a
                      className="footer-link"
                      href="https://www.youtube.com/channel/UCgSvEsQxUJpx6bIswJHcQ5w?view_as=subscriber"
                    >
                      youtube
                    </a>
                    <a
                      className="footer-link"
                      href="https://www.instagram.com/floorplanbazaar/"
                    >
                      instagram
                    </a>
                    <a
                      className="footer-link"
                      href="https://www.facebook.com/floorplanbazaar/"
                    >
                      facebook
                    </a>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="copyright ion-text-center">
              <p>
                Copyright © FloorplanBazaar.com. A division of Naksha Bazaar
                Pvt.Ltd. <br></br>All Rights Reserved.
              </p>
            </div>

            <div
              className="footer-flex-box"
              style={{ flexDirection: "row", justifyContent: "space-evenly" }}
            >
              <Link className="footer-link" to={`/terms-and-conditions`}>
                Term of use & Service
              </Link>

              <Link className="footer-link" to={`/privacy-policy`}>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <div className="hp-bottom-menu">
          <div className="hp-bottom-inner-box">
            <div className="hp-bottom-flex">
              <div>
                <a href="tel:+918800757170">
                  <AiOutlinePhone className="hp-menu-logo" />
                  <p> PHONE </p>
                </a>
              </div>
              <div>
                <a href="https://wa.me/918800757170">
                  <AiOutlineWhatsApp className="hp-menu-logo" />
                  <p> WHATSAPP </p>
                </a>
              </div>
              <div>
                <AiOutlineMessage className="hp-menu-logo" />
                <p> CHAT </p>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Index);

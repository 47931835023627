import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React from "react";
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import WebsiteLogo from "../../assests/rsz_fpb_-_logo_-_hd_mogljv.png";
import Footer from "../../components/Footer";

const AboutUs = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" defaultHref="/" />
          </IonButtons>
          <IonTitle>About Us </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="default-page-size">
          <div className="default-inner-box">
            <div className="about-us-box">
              <div className="inner-box-about-us">
                <h3>THE JOURNEY</h3>
                <q>A journey of a thousand miles begins with a single step</q>
                <p>– Lao Tzu</p>
                <div className="dotted-line-box">
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                </div>
                <h4>OUR HISTORY</h4>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol style={{ textAlign: "left" }} size={10}>
                        <p>
                          Our journey started in 2012 with a small team of
                          freelance architects. Our first venture was in New
                          Delhi with the name of “Creative Architects and
                          Designers”. We worked upon various projects of
                          designing residential apartments, builder flats,
                          private residence, luxury city homes and budget house
                          planning of small and medium scale constructions.
                        </p>
                        <p>
                          In the coming 2 - 3 years we completed over 100’s of
                          projects in Delhi-NCR. We always believed in
                          innovating our products in context to customer
                          experience.
                        </p>
                      </IonCol>
                      <IonCol size={2}>
                        <div className="text-horiz-box">
                          <p className="text-change-horxi">2012</p>
                        </div>
                      </IonCol>
                      <div style={{ textAlign: "left" }}>
                        <p>
                          Our vison was always on improving the quality of
                          drawings for its effectiveness during actual sitework.
                          The primary goal of engineering drawings is to guide
                          the construction process with a practical roadmap. The
                          drawings should be easily understandable by the
                          construction contractor and those site workers who
                          have to do the practical implementation.
                        </p>
                      </div>
                    </IonRow>
                  </IonGrid>
                </div>
                <div className="dotted-line-box">
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                </div>
                <h4>THE RESEARCH</h4>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol size={2}>
                        <div className="text-horiz-box">
                          <p className="text-change-horxi">2016</p>
                        </div>
                      </IonCol>
                      <IonCol style={{ textAlign: "left" }} size={10}>
                        <p>
                          After years of endeavor and challenges we realized,
                          that the traditional format of architectural and
                          structural drawings are normally complicated in
                          nature, which is not bad but it ultimately creates
                          confusion during the site work of residential
                          constructions.
                        </p>
                        <p>
                          A slight misinterpretation of drawings can cause huge
                          losses, in terms of time, money, aesthetics, visual
                          appearance and even structural safety. In 2016, we
                          started a research to simplify the structural and
                          architectural drawings.
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <div>
                    <IonGrid>
                      <IonRow style={{ textAlign: "left" }}>
                        <IonCol>
                          <h4>The Outcome’s</h4>
                          <p>
                            After months of extensive research and analysis we
                            came up with a special format of colour coded
                            drawings which were easier to understand by site
                            owner, contractors and site workers.
                            <br></br> Traditionally, the engineering drawings
                            are in black & white format only, but we coded our
                            drawings in different colors, so that its very easy
                            to differentiate between different structural
                            elements like columns, beams, thickness of
                            reinforcement bars and various other aspects of
                            architectural drawings.
                          </p>
                        </IonCol>
                        <IonCol>
                          <h4>The Benefit’s</h4>
                          <p>
                            Engineer & Architects will understand the drawings
                            anyway, but the drawings and project files are not
                            made to keep in drawers.
                            <br></br> We create our project files, keeping in
                            mind about the convenience of construction
                            contractor and site workers. The amount of confusion
                            calls from site contractors have reduced
                            significantly. Also the chances of mistakes during
                            the site work are bare minimum.
                          </p>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
                <div className="dotted-line-box">
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                </div>
                <h4>THE COMPANY</h4>
                <div>
                  <IonGrid>
                    <IonRow>
                      <div style={{ textAlign: "left" }}>
                        <p>
                          Our format of drawings are a great success. We are the
                          only service provider in India which provides all
                          architectural and structural drawings in colour. Also
                          the colored floor plan provided by us is best in class
                          and one of its kind in 2d drawings segment.
                        </p>
                      </div>
                      <IonCol style={{ textAlign: "left" }} size={10}>
                        <p>
                          After the completion of research, we started our
                          online venture along with a few employees having a
                          shared vision of spreading the reach of our services
                          to all major cities of India.
                        </p>
                        <p>
                          With that intent we established our company in 2017,
                          with the name NAKSHA BAZAAR Private Limited. Which has
                          been recognized as Startup by the Department for
                          Promotion of Industry and Internal Trade, part of
                          Ministry of Commerce and Industry, Government of
                          India.
                        </p>
                      </IonCol>
                      <IonCol size={2}>
                        <div className="text-horiz-box">
                          <p className="text-change-horxi">2017</p>
                        </div>
                      </IonCol>
                      <div style={{ textAlign: "left" }}>
                        <p>
                          The startup has been working towards providing quality
                          services to the people who are planning to start a new
                          construction project. It can be a dream home, a
                          builder flat, a residential apartment or a commercial
                          building. We have the solution for all your
                          construction planning needs.
                        </p>
                      </div>
                    </IonRow>
                  </IonGrid>
                </div>
                <div className="dotted-line-box">
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                </div>
                <h4>THE BRAND</h4>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol size={2}>
                        <div className="text-horiz-box">
                          <p className="text-change-horxi">2019</p>
                        </div>
                      </IonCol>
                      <IonCol style={{ textAlign: "left" }} size={10}>
                        <p>
                          It was in the mid of 2019, we introduced a brand name
                          “FLOORPLAN BAZAAR” to diversify our reach and enhance
                          brand recall. Today we are serving all across the
                          country. Providing architectural & structure planning
                          services along with interior and exterior 3d
                          visualization services thru our online platform. After
                          months of development and refinements. We launched our
                          user friendly and easy to use website:
                          www.FloorPlanBazaar.com
                        </p>
                      </IonCol>
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <img
                          width="220"
                          style={{ margin: "0 auto", textAlign: "center" }}
                          src={WebsiteLogo}
                          alt=""
                        />
                      </div>
                      <div style={{ textAlign: "left", width: "100%" }}>
                        <p>
                          Here you can explore our services, check pricing as
                          per your plot size, view the payment schedule, buy our
                          bundled packages, make online payment, track your work
                          progress, view designs, drawings and much more. You
                          can follow us on social media for latest updates and
                          designs. Visit our YouTube Channel for exciting video
                          walkaround tours of interior and 3d front elevation
                          designs.
                        </p>
                      </div>
                    </IonRow>
                  </IonGrid>
                </div>
                <div className="dotted-line-box">
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                </div>
                <h4>OUR FOOTPRINT</h4>
                <div>
                  <IonGrid>
                    <IonRow>
                      <div style={{ textAlign: "left", width: "100%" }}>
                        <p>
                          We believe in expanding our reach to all major cities
                          of the country. Currently our head office is in New
                          Delhi, India. All the work for residential projects
                          are processed from Delhi head office and sub-branches.
                        </p>
                      </div>
                      <IonCol style={{ textAlign: "left" }} size={10}>
                        <p>
                          Currently we have our backend offices present in
                          various major cities. Whereas, if an office location
                          is not available in your city, don’t worry.
                          <br></br>The process that we follow makes it
                          effortless and convenient for all customers to avail
                          our services from any part of India. Just contact us,
                          regardless of your location or the scope of work. We
                          will provide you with the best services at best rates.
                        </p>
                      </IonCol>
                      <IonCol size={2}>
                        <div className="text-horiz-box">
                          <p className="text-change-horxi">2020</p>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol style={{ textAlign: "right" }} size={2}>
                        <div className="dotted-line-box">
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                        </div>
                      </IonCol>
                      <IonCol style={{ textAlign: "left" }} size={10}>
                        <div>
                          <h4>What We Do ?</h4>
                          <p>
                            If you are planning to start a new construction
                            project, you will need an architect for floor
                            planning, an engineer for structure drawings, an
                            interior designer, a construction contractor and
                            many other consultants.<br></br> Finding out
                            consultants who can cater your needs along with
                            fitting into your budget, can take a lot of time and
                            efforts.<br></br> At floorplan bazaar, we have
                            crafted, solutions for all your needs under one
                            roof.
                          </p>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol style={{ textAlign: "right" }} size={10}>
                        <div>
                          <h4>How We Do ?</h4>
                          <p>
                            Just select one of our house designing packages, and
                            hire a local construction contractor, and you are
                            all set to start your construction work within a few
                            weeks.<br></br> We have a team of engineers and
                            architects, who are expert in accomplishing projects
                            of any scope and size.<br></br> Our drawings have
                            high level of accuracy, and are simplified and
                            colour coded in a way that everyone can easily
                            understand them during the sitework.
                          </p>
                        </div>
                      </IonCol>
                      <IonCol style={{ textAlign: "right" }} size={2}>
                        <div className="dotted-line-box">
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                          <div className="dot-main"></div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <div className="dotted-line-box">
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                </div>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <div>
                          <h4>What Do We Offer ?</h4>
                          <p style={{ textAlign: "center" }}>
                            We have two full-service packages.<br></br> MODERN
                            and LUXURY - House Design Package.<br></br>
                          </p>
                          <p style={{ textAlign: "left" }}>
                            In the Modern package we offer floor planning
                            services along with structure drawings, door-window
                            designs, electrical-plumbing layouts, 3d front
                            elevation and a free video walkthrough of exterior
                            elevation. You can buy this package by just paying
                            25% advance.
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Whereas, our flagship Luxury House Design Package is
                            the best that you can have for your construction
                            project. Along with the services provided in modern
                            package, here you will get interior designing
                            services, 3d video walkthrough, 360-degree VR tours
                            and more. You can buy this package by just paying
                            15% advance for starting the floor planning work.
                          </p>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <div className="dotted-line-box">
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                </div>
                <div>
                  <h4>Get Connected With Us ?</h4>
                  <p>
                    We are available for you incase you need any help or have
                    any enquiries.
                  </p>
                  <h5>8800-75-7170</h5>
                  <p>Call us anytime during 10AM to 6PM</p>
                  <h5>1800-419-7707</h5>
                  <p>(All India Toll Free)</p>
                  <p>Call us anytime during 10AM to 6PM</p>
                  <h5>WhatsApp us at 8800-75-7170</h5>
                  <p>
                    You can WhatsApp us anytime, If available we will reply or
                    we will reply back during the working hours.
                  </p>
                  <h5 style={{ marginTop: "15px" }}>
                    General Information and Customer Enquiries
                  </h5>
                  <p style={{ color: "#f5a947" }}>
                    Email us at: info@floorplanbazaar.com
                  </p>
                  <h5>For Sales &amp; Orders related queries</h5>
                  <p style={{ color: "#f5a947" }}>
                    Email us at: orders@floorplanbazaar.com
                  </p>
                  <h5>For working with us, visit the careers page</h5>
                  <p style={{ color: "#f5a947" }}>
                    Email your CV &amp; Portfolio at: floorplanbazaar@gmail.com
                  </p>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <h4>Lets Get Started</h4>
                  <p>Build Your Dream Home with Floorplan Bazaar</p>
                </div>
                <div className="dotted-line-box">
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                  <div className="dot-main"></div>
                </div>
                <div>
                  <h4>Follow Us</h4>

                  <div className="social-flex">
                    <a
                      href="https://www.youtube.com/channel/UCgSvEsQxUJpx6bIswJHcQ5w?view_as=subscriber"
                      target="_blank"
                    >
                      <AiFillYoutube
                        style={{ color: "#FF0000" }}
                        className="social-icons"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/floorplanbazaar/"
                      target="_blank"
                    >
                      <AiFillInstagram
                        style={{ color: "#8a3ab9	" }}
                        className="social-icons"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/floorplanbazaar/"
                      target="_blank"
                    >
                      <AiFillFacebook
                        style={{ color: "#00B2FF" }}
                        className="social-icons"
                      />
                    </a>
                  </div>
                </div>
                {/* END */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AboutUs;

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
} from "@ionic/react";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" defaultHref="/" />
          </IonButtons>
          <IonTitle>Privacy Policy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="default-page-size">
          <div className="default-inner-box">
            <div className="term-and-condition-box">
              <h4>
                <strong>Privacy and Cookies Policy</strong>
              </h4>
              <p>
                Your privacy is important to us.&nbsp; Naksha Bazaar (OPC)
                Private Limited (&ldquo;<strong>Floorplan Bazaar</strong>&rdquo;
                or &ldquo;<strong>we</strong>&rdquo;) takes the privacy of your
                information seriously. This privacy statement explains what
                personal data we collect from you, through our interactions with
                you on this website, and how we use that data.&nbsp;
              </p>
              <p>
                This privacy statement applies to the main website&nbsp;
                <strong>www.floorplanbazaar.com</strong>,&nbsp;as well
                as&nbsp;in other sub-domains (sometimes called
                &ldquo;microsites&rdquo;). Please note that these websites may
                include links to websites of third parties whose privacy
                practices differ from those of Floorplan Bazaar. If you provide
                personal data to any of those websites, then your data is
                governed by their privacy statements.
              </p>
              <ol>
                <li>
                  <strong> Definitions</strong>
                </li>
              </ol>
              <p>In this privacy policy, the following definitions are used:</p>
              <p>
                <strong>Data</strong>
                <br /> Includes information that you submit to Floorplan Bazaar
                via the website, and information which is accessed by Floorplan
                Bazaar pursuant to your visit to the website.
              </p>
              <p>
                <strong>Cookies</strong>
                <br /> A small file placed on your computer by this Website when
                you either visit, or use certain features of, the website.&nbsp;
                A cookie generally allows the website to &ldquo;remember&rdquo;
                your actions or preference for a certain period of time.
              </p>
              <p>
                <strong>Data Protection Laws</strong>
                <br /> Any applicable law relating to the processing of personal
                Data, including the GDPR and the Information Technology Act,
                2000, as amended or substituted;
              </p>
              <p>
                <strong>GDPR</strong>
                <br /> The General Data Protection Regulation (EU) 2016/679;
              </p>
              <p>
                <strong>Floorplan Bazaar or us&nbsp;</strong>
                <br /> Naksha Bazaar (OPC) Private Limited, a company
                incorporated in India whose registered office is at Palam
                Colony, New Delhi, India, 110045
              </p>
              <p>
                <strong>User or you</strong>
                <br /> The natural person who accesses the website
              </p>
              <p>
                <strong>Website</strong>
                <br /> The website that you are currently using,
                www.floorplanbazaar.com, and any sub-domains of this site,
                unless excluded by their own terms.
              </p>
              <ol start="2">
                <li>
                  <strong> Scope</strong>
                </li>
              </ol>
              <p>
                Floorplan Bazaar collects Data to operate this Website. You
                provide some of this data directly, such as when you submit an
                entry (through the general query, media query, newsletter
                subscription, application forms or such other manner specified
                on the Website).
              </p>
              <p>
                You can visit&nbsp;www.floorplanbazaar.com without telling us
                who you are. You can make choices about our collection and use
                of your data. For example, you may want to access, edit or
                remove the personal information in the Website or microsite
                account. When you are asked to provide personal data, you may
                decline.
              </p>
              <ol start="3">
                <li>
                  <strong> Data Collected</strong>
                </li>
              </ol>
              <p>
                We may collect information or pieces of information that could
                allow you to be identified, including:
              </p>
              <ol className="subli">
                <li>
                  Contact information: We collect first and last name, email
                  address, postal address, country, employer, phone number and
                  other similar contact data; and
                </li>
                <li>
                  User profile, profession, IP address and other similar profile
                  data.
                </li>
              </ol>
              <ol start="4">
                <li>
                  <strong> How we Collect Data</strong>
                </li>
              </ol>
              <p>We collect Data in the following ways:</p>

              <ol className="subli">
                <li>data is given to us by you; and</li>
                <li>
                  data is collected automatically pursuant to your visit to the
                  Website.
                </li>
              </ol>

              <ol start="5">
                <li>
                  <strong> Data shared by You</strong>
                </li>
              </ol>
              <p>
                Floorplan Bazaar may collect your Data in several ways from your
                use of this Website.&nbsp;
              </p>
              <p>For instance:</p>
              <ol className="subli">
                <li>
                  when you contact us through the website (through any form,
                  e-mail address, hyperlink or social&nbsp; media address posted
                  therein);
                </li>
                <li>
                  when you register with us to receive our products, services
                  and/or newsletters;
                </li>
                <li>when you complete surveys conducted by or for us;</li>
                <li>when you enter a competition or promotion;</li>
                <li>
                  when you elect to receive any communications (including any
                  newsletter, promotional offers) from us;
                </li>
                <li>
                  from the information gathered by your visit to our webpages;
                </li>
                <li>
                  information provided by you (such as for subscribing to our
                  newsletters, submission of applications, submission of
                  resume/cv, submission of portfolio, or other similar means)
                </li>
              </ol>
              <ol start="6">
                <li>
                  <strong> Data that is Collected Automatically</strong>
                </li>
              </ol>
              <ol className="subli">
                <li>
                  We automatically collect some information about your visit to
                  the Website. This information helps us to make improvements to
                  Website content and navigation and includes your IP address
                  and the way you use and interact with its content.
                </li>
                <li>
                  Our web servers or affiliates who provide analytics and
                  performance enhancement services collect IP address, operating
                  system details, browsing details, device details and language
                  settings. This information is aggregated to measure the number
                  of visits, average time spent on the site, pages viewed and
                  similar information. Floorplan Bazaar uses this information to
                  measure the site usage, improve content and to ensure safety
                  and security, as well enhance performance of the Website.
                </li>
                <li>
                  We may collect your Data automatically via Cookies, in line
                  with the cookie settings on your browser. For more information
                  about Cookies, please see the section below, titled
                  &ldquo;Cookies&rdquo;.
                </li>
              </ol>

              <ol start="7">
                <li>
                  <strong> Our Use of Data</strong>
                </li>
              </ol>

              <p>
                Any or all the above Data may be required by us from time to
                time to provide information relating to Floorplan Bazaar and to
                work on the experience when using our Website. Specifically,
                Data may be used by us for the following reasons:
              </p>
              <ol className="subli">
                <li>
                  improvement of our products or services, as well as of our
                  group entities;
                </li>
                <li>
                  transmission by email or any other form of communication of
                  marketing materials to you;
                </li>
                <li>
                  contact for survey or feedback which may be done using call,
                  email or mail;
                </li>
                <li>
                  to enable our group entities to reach out to you in relation
                  to their programmes managed by them / products or services
                  offered by them;
                </li>
                <li>
                  to process your requests (such as replying to your queries);
                  and
                </li>
                <li>
                  to execute other activities such as marketing campaign,
                  promotional communications for which consent is taken
                  appropriately.
                </li>
              </ol>
              <p>
                We may use your Data for the above purposes if we deem it
                necessary to do so for our legitimate interests. We may use your
                Data to show you adverts and other content on other websites. If
                you do not want us to use your data to show you adverts and
                other content on other websites, please turn off the relevant
                cookies. We use data to protect the security and safety of our
                Website.
              </p>
              <ol start="8">
                <li>
                  <strong> Who we share Data with</strong>
                </li>
              </ol>
              <p>We may share your personal Data with:</p>
              <ol className="subli">
                <li>
                  Floorplan Bazaar-controlled affiliates and subsidiaries and
                  other entities within the Naksha Bazaar (OPC) Private Limited
                  group of companies, to assist them to reach out to you in
                  relation to their programs or campaigns (including marketing
                  and sales) and to process your query / requests (such as job
                  application);
                </li>
                <li>
                  our employees, vendors, agents and professional advisors
                  working on our behalf for the purposes described in this
                  policy statement; and
                </li>
                <li>
                  service-providers who assist in protecting and securing our
                  systems and provide services to us which require the
                  processing of personal data, such as to host your information
                  or to process your information for data profiling and user
                  analysis.
                </li>
              </ol>
              <p>
                We usually do not share other personal Data collected from the
                website with other third parties. However, this may happen if:
              </p>
              <p>(a) You request or authorize us to do so;</p>
              <p>
                (b) We need to comply with applicable law or respond to valid
                legal process; or
              </p>
              <p>
                (c) We need to operate and maintain the security of this
                website, including to prevent or stop an attack on our computer
                systems or networks.
              </p>
              <ol start="9">
                <li>
                  <strong> Keeping Data secure</strong>
                </li>
              </ol>
              <p>
                We will use technical and organisational measures to safeguard
                your Data and we store your Data on secure servers.
              </p>
              <p>
                Technical and organisational measures include measures to deal
                with any suspected data breach. If you suspect any misuse or
                loss or unauthorised access to your Data, please let us know
                immediately by contacting us via e-mail, social media or
                telephone.
              </p>
              <ol start="10">
                <li>
                  <strong> Retention of Personal Data</strong>
                </li>
              </ol>
              <p>
                Floorplan Bazaar retains personal Data for as long as necessary
                to provide the access to and use of the website, or for other
                essential purposes such as complying with our legal obligations,
                resolving disputes and enforcing our agreements. Because these
                needs can vary for different data types and purposes, actual
                retention periods can vary significantly.
              </p>
              <p>
                Even if we delete your Data, it may persist on backup or
                archival media for audit, legal, tax or regulatory purposes.
              </p>
              <ol start="11">
                <li>
                  <strong> Security of Personal Data</strong>
                </li>
              </ol>
              <p>
                Floorplan Bazaar is committed to protecting the security of your
                Data. We use a variety of security technologies and procedures
                to help protect your personal data from unauthorized access, use
                or disclosure.
              </p>
              <ol start="12">
                <li>
                  <strong> Links to other websites</strong>
                </li>
              </ol>
              <p>
                This Website may, from time to time, provide links to other
                websites. We have no control over such websites and are not
                responsible for the content of these websites. This privacy
                policy does not extend to your use of such websites. You are
                advised to read the privacy policy or statement of other
                websites prior to using them.
              </p>
              <ol start="13">
                <li>
                  <strong> Cookies</strong>
                </li>
              </ol>
              <p>
                This Website may place and access certain Cookies on your
                computer. Floorplan Bazaar uses Cookies to improve your
                experience of using the Website.
              </p>
              <p>
                Before the Website places Cookies on your computer, you will be
                presented with a message bar requesting your consent to set
                those Cookies. You may, if you wish, deny consent to the placing
                of Cookies; however certain features of the Website may not
                function fully or as-intended.
              </p>
              <p>
                You can choose to enable or disable Cookies in your internet
                browser. By default, most internet browsers accept Cookies, but
                this can be changed. For further details, please consult the
                help menu in your internet browser.
              </p>
              <p>This Website may place the following Cookies:</p>
              <p>
                <strong>Type of Cookie:&nbsp;</strong>acopendivids,
                acgroupswithpersist
                <br /> <strong>Purpose:</strong>&nbsp;This cookie is necessary
                to display content in expand / collapse format on pages.
              </p>
              <p>
                <strong>Type of Cookie:</strong>&nbsp;_utma, _utmb, _utmc,
                _utmz,display_features_cookie:
                <br /> <strong>Purpose:&nbsp;</strong>Google Analytics is used
                on the Websites to track visitor traffic and site performance
                traffic by collecting anonymous information about the average
                time spent on the website, the pages viewed and other relevant
                usage statistics. To opt out of being tracked by Google
                Analytics visit: https://tools.google.com/dlpage/gaoptout&nbsp;
              </p>
              <ol start="14">
                <li>
                  <strong>
                    {" "}
                    You can choose to delete Cookies at any time;
                  </strong>
                </li>
              </ol>
              <p>
                however, you may lose any information that enables you to access
                the Website more quickly and efficiently including, but not
                limited to, personalisation settings.
              </p>
              <ol start="15">
                <li>
                  <strong>
                    {" "}
                    It is recommended that you ensure that your internet browser
                    is up-to-date
                  </strong>
                </li>
              </ol>
              <p>
                and that you consult the help and guidance provided by the
                developer of your internet browser if you are unsure about
                adjusting your privacy settings.
              </p>
              <ol start="16">
                <li>
                  <strong> General</strong>
                </li>
              </ol>
              <p>
                If any court or competent authority finds that any provision of
                this privacy policy (or part of any provision) is invalid,
                illegal or unenforceable, that provision or part-provision will,
                to the extent required, be deemed to be deleted, and the
                validity and enforceability of the other provisions of this
                privacy policy will not be affected.
              </p>
              <ol start="18">
                <li>
                  <strong> Changes to this Privacy Statement</strong>
                </li>
              </ol>
              <p>
                Floorplan Bazaar reserves the right to change this privacy
                statement as it may deem necessary from time to time or as may
                be required by law. Any changes will be immediately posted on
                the Website and you are deemed to have accepted the terms of the
                privacy statement on your first use of the Website following the
                alterations.
              </p>
              <p>
                You may contact Floorplan Bazaar
                through&nbsp;info@floorplanbazaar.com
              </p>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;

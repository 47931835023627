import React, { useEffect, useState, useGlobal } from "reactn";
import Router from "./router/index";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./index.css";
import { useMediaQuery } from "react-responsive";
import Media from "react-media";
import { Redirect, withRouter } from "react-router";

const App = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 700px)",
  });

  const DesktopSite = () => {
    return window.location.replace("https://floorplanbazaar.com");
  };

  return <>{isDesktopOrLaptop ? <>{DesktopSite()}</> : <Router />}</>;
};

export default App;

import {
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonContent,
  IonCard,
  IonIcon,
  IonCardContent,
  IonModal,
  IonItem,
  IonButton,
  IonImg,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import ReactPlayer from "react-player";
import React, { useEffect, useState, useGlobal } from "reactn";
import Collapsible from "react-collapsible";
import renderHTML from "react-render-html";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

import {
  ToastDanger,
  ToastSuccess,
  ToastWarning,
} from "../../components/Toaster";
import { singlePackage } from "./ApisCore";
import {
  addOutline,
  chevronForwardOutline,
  closeOutline,
  informationCircleOutline,
} from "ionicons/icons";

import BottomPriceModal from "./BottomPriceModal";

const DetailPackage = (props) => {
  const [packageData, setPackageData] = useState();
  const [pName, setPName] = useState();

  const [loading, setLoading] = useGlobal("loading");
  const [otherDetails, setOtherDetails] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [packageSet, setPackageSet] = useGlobal("packageData");
  const [variantSelected, setVariantSelected] = useGlobal("variant");
  const [plotSelectShow, setPlotSelectShow] = useGlobal("plotSelectShow");
  const [floorSubCount, setFloorSubCount] = useGlobal("floorSubCount");

  const loadPackage = (pName) => {
    setLoading(true);
    singlePackage(pName)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          ToastDanger(data.error, 5000);
        } else {
          setPackageData(data);
          setPackageSet(data);
          ToastSuccess("Package Loaded Successfull....", 2000);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        ToastDanger(err, 5000);
      });
  };
  const playicon = () => {
    return (
      <img
        height="50px"
        src="https://res.cloudinary.com/djnv06fje/image/upload/v1595606139/play_pegoyo.png"
        alt=""
      />
    );
  };
  const renderheader = (p) => {
    return (
      <div className="collapse-header">
        <p style={{ margin: "0", fontSize: "15px", fontWeight: "500" }}>{p}</p>
        <IonIcon
          style={{ fontSize: "20px", color: "#50b6cf" }}
          icon={addOutline}
        />
      </div>
    );
  };

  const ModalShowHandler = (itemDetails) => {
    setOtherDetails(itemDetails);
    setModalOpen(true);
  };

  useEffect(() => {
    async function fetchPackageName() {
      const packageName = await props.match.params.name;
      return packageName;
    }
    fetchPackageName().then((data) => {
      loadPackage(data);
    });
  }, []);

  const ModalContent = () => {
    return (
      <IonModal
        onDidDismiss={() => setModalOpen(false)}
        cssClass={
          otherDetails.details.length < 500
            ? "my-custom-class"
            : "my-custom-class-2"
        }
        isOpen={modalOpen}
      >
        <IonContent>
          <div className="modal-header-block">
            <IonItem className="modal-header" lines="none">
              <p>{otherDetails.heading}</p>
              <IonButton
                slot="end"
                className="plot-select"
                style={{ fontSize: "20px" }}
                onClick={() => setModalOpen(false)}
              >
                <IonIcon className="close-btn" icon={closeOutline} />
              </IonButton>
            </IonItem>
          </div>

          <div className="modal-block">
            <div>{renderHTML(otherDetails.details)}</div>
          </div>
        </IonContent>
      </IonModal>
    );
  };
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    effect: "fade",
    loop: true,
    autoplay: true,
  };

  return (
    <IonPage>
      {modalOpen ? ModalContent() : ""}

      {packageData && (
        <IonHeader>
          <IonToolbar className="header">
            <IonButtons slot="start">
              <IonBackButton color="dark" defaultHref="/" />
            </IonButtons>
            <IonTitle className="package-name">
              {packageData.packageName}
            </IonTitle>
          </IonToolbar>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{packageData.packageName}</title>
            <meta name="description" content={packageData.packageName} />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
        </IonHeader>
      )}
      {loading ? (
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Loading...."}
        />
      ) : (
        <>
          <IonContent>
            {packageData && (
              <div className="package-body">
                <div className="offer-block">
                  {packageData.packageOffer.isValid ? (
                    <img src={packageData.packageOffer.offerPhoto.url} alt="" />
                  ) : (
                    ""
                  )}
                </div>

                <div className="package-video-block">
                  {packageData.paymentType === "only-1" ? (
                    <div className="package-slider">
                      <IonSlides pager={true} options={slideOpts}>
                        <IonSlide>
                          <img
                            alt=""
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1614086588/Slide1_d9zh50.jpg"
                          />
                        </IonSlide>
                        <IonSlide>
                          <img
                            alt=""
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1614086589/P1_page-0001_nffjrs.jpg"
                          />{" "}
                        </IonSlide>{" "}
                        <IonSlide>
                          <img
                            alt=""
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1614086589/P2_page-0001_ay2ckw.jpg"
                          />{" "}
                        </IonSlide>
                        <IonSlide>
                          <img
                            alt=""
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1614086589/P3_page-0001_km20ib.jpg"
                          />{" "}
                        </IonSlide>{" "}
                        <IonSlide>
                          <img
                            alt=""
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1614086589/P4_page-0001_yv8ikj.jpg"
                          />{" "}
                        </IonSlide>
                        <IonSlide>
                          <img
                            alt=""
                            src="https://res.cloudinary.com/djnv06fje/image/upload/v1614086589/P5_page-0001_mcue80.jpg"
                          />{" "}
                        </IonSlide>
                      </IonSlides>
                    </div>
                  ) : (
                    <IonCard className="package-video-card">
                      <ReactPlayer
                        width="auto"
                        height="230px"
                        playing
                        controls
                        playIcon={playicon()}
                        light={packageData.packageImage.url}
                        url={packageData.packageVideoUrl}
                      />
                    </IonCard>
                  )}
                </div>

                <div className="package-flex-block">
                  <img src={packageData.packageFlexImage.url} alt="" />
                </div>

                {packageData.paymentType === "only-1" ? (
                  <div className="package-details-block">
                    {packageData.packageAllDetails.map((p, i) => (
                      <Collapsible
                        open={true}
                        key={i}
                        trigger={renderheader(p.heading)}
                      >
                        <div>{renderHTML(p.details)}</div>
                      </Collapsible>
                    ))}
                  </div>
                ) : (
                  <div className="package-details-block">
                    {packageData.packageAllDetails.map((p, i) => (
                      <Collapsible
                        open={true}
                        key={i}
                        trigger={renderheader(p.heading)}
                      >
                        <div>{renderHTML(p.details)}</div>
                      </Collapsible>
                    ))}
                  </div>
                )}

                <div className="package-other-block">
                  <IonCard className="other-card">
                    <IonCardContent className="other-card-body">
                      {packageData.packageOtherDetails.map((l, i) => (
                        <>
                          <p key={i}>
                            {l.heading}{" "}
                            <span>
                              <IonIcon
                                className="i-button"
                                onClick={() => ModalShowHandler(l)}
                                style={{ marginBottom: "-4px" }}
                                icon={informationCircleOutline}
                              />
                            </span>
                          </p>
                        </>
                      ))}
                    </IonCardContent>
                  </IonCard>
                </div>

                <div className="package-how-to-buy">
                  <img
                    src="https://res.cloudinary.com/djnv06fje/image/upload/v1614583786/buy-image/How_to_Buy_zjoqvm.png"
                    alt=""
                  />
                </div>

                <div className="bottom-price-block">
                  <div className="inner-price-box">
                    <div>
                      <button
                        onClick={(e) => setPlotSelectShow(true)}
                        className="btn-plot-select"
                      >
                        Please Select Plot Size{" "}
                        <IonIcon
                          className="btn-plot-i"
                          icon={chevronForwardOutline}
                        />
                      </button>
                      {/* {packageData.variants.map((v, i) => (
                        <button>{v.area}</button>
                      ))} */}
                    </div>

                    <div>
                      {floorSubCount ? (
                        <button
                          onClick={(e) => props.history.push("/cart")}
                          className="buy-btn"
                        >
                          BUY NOW
                        </button>
                      ) : (
                        <button
                          onClick={(e) =>
                            ToastWarning("Please select all options")
                          }
                          className="buy-btn"
                        >
                          BUY NOW
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <BottomPriceModal />
              </div>
            )}
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default withRouter(DetailPackage);

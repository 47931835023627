let appData = {};

const checkStatus = () => {
  if (process.env.REACT_APP_STATUS === "DEV") {
    appData = {
      API: process.env.REACT_APP_API_DEV,
      RAZORPAY_KEY: process.env.REACT_APP_RAZORPAY_KEY_TEST,
    };
    return appData;
  } else {
    appData = {
      API: process.env.REACT_APP_API_PRO,
      RAZORPAY_KEY: process.env.REACT_APP_RAZORPAY_KEY_LIVE,
    };
    return appData;
  }
};

export const API = checkStatus().API;
export const RAZORPAY_KEY = checkStatus().RAZORPAY_KEY;

import {
  IonPage,
  IonButtons,
  IonBackButton,
  IonToolbar,
  IonContent,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonImg,
  IonItem,
  IonInput,
  IonButton,
} from "@ionic/react";
import { FaMobileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  ToastDanger,
  ToastSuccess,
  ToastWarning,
} from "../../components/Toaster";
import { forgotPassword } from "./ApisUsers";

import React, { useState, useGlobal } from "reactn"; // <-- reactn

const ForgetPage = () => {
  const [loading, setLoading] = useGlobal("loading");
  const [values, setValues] = useState({
    mobileNumber: "",
    width: window.screen.width,
  });
  const { mobileNumber, width } = values;

  const onchangeInputs = (name) => (event) => {
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  };
  const changeback = (id) => {
    const el = document.getElementById(id);
    el.style.border = "2px #50b6cf solid";
    el.style.borderRadius = "5px";
  };
  const changebackBlur = (id) => {
    const el = document.getElementById(id);
    el.style.border = "2px rgb(209, 209, 209) solid";
    el.style.borderRadius = "5px";
  };

  const isValid = () => {
    const mNumber = parseInt(mobileNumber);

    if (mobileNumber.length <= 0) {
      ToastWarning("Mobile number must required.");
      setLoading(false);

      return false;
    } else if (isNaN(mNumber)) {
      ToastWarning("Mobile number must in digits.");
      setLoading(false);

      return false;
    } else if (mobileNumber.length < 10 || mobileNumber.length > 10) {
      ToastWarning("Mobile number must be of 10 digits.");
      setLoading(false);

      return false;
    }
    return true;
  };

  const forgotPasswordHandle = (e) => {
    e.preventDefault();
    setLoading(true);
    if (isValid()) {
      forgotPassword(values).then((data) => {
        if (data.error) {
          ToastDanger(data.error);
          setLoading(false);
        } else {
          ToastSuccess(data.message);
          setLoading(false);
        }
      });
    }
  };

  return (
    <IonPage>
      <IonToolbar className="default-header">
        <IonButtons slot="start">
          <IonBackButton color="dark" defaultHref="/" />
        </IonButtons>
      </IonToolbar>

      <IonContent>
        {" "}
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Sending you Reset Password Link"}
        />{" "}
        <IonGrid className="login-page">
          <div className="box-1"></div>
          <div className="box-2"></div>
          <div className="box-3"></div>
          <div className="box-4"></div>
          <IonRow className="login-row">
            <IonCol className="login-col">
              <>
                <IonCard className="login-card">
                  <div
                    style={{ paddingTop: "16px" }}
                    className="ion-text-center"
                  >
                    <IonImg
                      style={{ height: "60px" }}
                      src="https://res.cloudinary.com/djnv06fje/image/upload/v1598709680/Copy_of_Copy_of_A1_1_hvhw6m.png"
                    />
                  </div>
                  <IonCardContent>
                    <div className="ion-text-left  login-header">
                      <p style={{ fontSize: "14px" }}>
                        PLEASE ENTER YOUR REGISTER MOBILE NUMBER
                      </p>
                    </div>
                    <form onSubmit={forgotPasswordHandle}>
                      <IonItem
                        style={{ border: "solid 2px rgb(209, 209, 209)" }}
                        lines="none"
                        className="input-box"
                        id="ipbox-3"
                      >
                        <FaMobileAlt className="login-logo" slot="start" />
                        <div>
                          <IonInput
                            onFocus={() => changeback("ipbox-3")}
                            onBlur={() => changebackBlur("ipbox-3")}
                            onIonChange={onchangeInputs("mobileNumber")}
                            clear-input="true"
                            type="tel"
                            maxlength={10}
                            placeholder="Enter Mobile No."
                            className="login-input"
                          />
                        </div>
                      </IonItem>
                      <div className="ion-text-center forget-text">
                        <Link to="/forget-password">
                          Password reset link will be send on your email.After
                          verifying mobile number.
                        </Link>
                      </div>
                      <div className="ion-text-right login-button">
                        <IonButton className="btn" expand="full" type="submit">
                          SUBMIT
                        </IonButton>
                      </div>
                    </form>
                  </IonCardContent>
                </IonCard>
              </>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ForgetPage;

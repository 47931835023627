import React, { useState, useGlobal } from "reactn"; // <-- reactn
import { Link, Redirect } from "react-router-dom";
import {
  IonPage,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonItem,
  IonInput,
  IonCardContent,
  IonButton,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
} from "@ionic/react";

import { MdEmail } from "react-icons/md";
import { FaLock, FaUserAlt, FaMobileAlt } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import {
  ToastWarning,
  ToastSuccess,
  ToastDanger,
} from "../../components/Toaster";
import { userRegister } from "./ApisUsers";

const Register = (props) => {
  const [loading, setLoading] = useGlobal("loading");
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    password: "",
    redirectToReferrer: false,
  });
  const [ShowPassword, setShowPassword] = useState(false);
  const {
    fullName,
    email,
    mobileNumber,
    password,
    redirectToReferrer,
  } = values;

  const onchangeInputs = (name) => (event) => {
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  };
  const changeback = (id) => {
    const el = document.getElementById(id);
    el.style.border = "2px #50b6cf solid";
    el.style.borderRadius = "5px";
  };
  const changebackBlur = (id) => {
    const el = document.getElementById(id);
    el.style.border = "2px rgb(209, 209, 209) solid";
    el.style.borderRadius = "5px";
  };

  const isValid = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    const mNumber = parseInt(mobileNumber);
    if (fullName.length <= 0) {
      ToastWarning("Name must required.");
      setLoading(false);
      return false;
    } else if (email.length <= 0) {
      ToastWarning("Email must required.");
      setLoading(false);

      return false;
    } else if (!pattern.test(email)) {
      ToastWarning("Email must be valid.");
      setLoading(false);

      return false;
    } else if (isNaN(mNumber)) {
      ToastWarning("Mobile number must in digits.");
      setLoading(false);

      return false;
    } else if (mobileNumber.length <= 0) {
      ToastWarning("Mobile number must required.");
      setLoading(false);

      return false;
    } else if (mobileNumber.length < 5 || mobileNumber.length > 10) {
      ToastWarning("Mobile number must be valid.");
      setLoading(false);

      return false;
    } else if (password.length <= 0) {
      ToastWarning("Password must required.");
      setLoading(false);

      return false;
    } else if (password.length < 8) {
      ToastWarning("Password must be strong.");
      setLoading(false);

      return false;
    }
    return true;
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    if (isValid()) {
      userRegister(values).then((data) => {
        if (data.error) {
          ToastDanger(data.error);
          setLoading(false);
        } else {
          setLoading(false);
          setValues({
            ...values,
            redirectToReferrer: true,
          });
          ToastSuccess(data.success, 4000);
        }
      });
    }
  };

  const redirectUser = () => {
    if (redirectToReferrer) {
      if (props.match.params.optional === "cart") {
        return <Redirect to={`/login/${"cart"}`} />;
      } else {
        return <Redirect to={`/login`} />;
      }
    }
  };

  return (
    <IonPage>
      {redirectUser()}
      <IonToolbar className="default-header">
        <IonButtons slot="start">
          <IonBackButton color="dark" defaultHref="/" />
        </IonButtons>
      </IonToolbar>

      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Creating account ...."}
        />
        <IonGrid className="login-page">
          <div className="box-1"></div>
          <div className="box-2"></div>
          <div className="box-3"></div>
          <div className="box-4"></div>
          <IonRow className="login-row">
            <IonCol className="register-col">
              <>
                <IonCard className="login-card">
                  <div
                    style={{ paddingTop: "16px" }}
                    className="ion-text-center"
                  >
                    <IonImg
                      style={{ height: "50px" }}
                      src="https://res.cloudinary.com/djnv06fje/image/upload/v1598709680/Copy_of_Copy_of_A1_1_hvhw6m.png"
                    />
                  </div>
                  <IonCardContent>
                    <div className="ion-text-left login-header">
                      <p>PLEASE REGISTER HERE</p>
                    </div>
                    <form onSubmit={onSubmitForm}>
                      <IonItem
                        style={{ border: "solid 2px rgb(209, 209, 209)" }}
                        lines="none"
                        className="input-box"
                        id="ipbox-1"
                      >
                        <FaUserAlt className="login-logo" slot="start" />
                        <div>
                          <IonInput
                            onFocus={() => changeback("ipbox-1")}
                            onBlur={() => changebackBlur("ipbox-1")}
                            onIonChange={onchangeInputs("fullName")}
                            clear-input="true"
                            type="text"
                            placeholder="Enter Full Name"
                            className="login-input"
                          />
                        </div>
                      </IonItem>
                      <IonItem
                        style={{ border: "solid 2px rgb(209, 209, 209)" }}
                        lines="none"
                        className="input-box"
                        id="ipbox-2"
                      >
                        <MdEmail className="login-logo" slot="start" />
                        <div>
                          <IonInput
                            onFocus={() => changeback("ipbox-2")}
                            onBlur={() => changebackBlur("ipbox-2")}
                            onIonChange={onchangeInputs("email")}
                            clear-input="true"
                            type="email"
                            placeholder="Enter Email"
                            className="login-input"
                          />
                        </div>
                      </IonItem>

                      <IonItem
                        style={{ border: "solid 2px rgb(209, 209, 209)" }}
                        lines="none"
                        className="input-box"
                        id="ipbox-3"
                      >
                        <FaMobileAlt className="login-logo" slot="start" />
                        <div>
                          <IonInput
                            onFocus={() => changeback("ipbox-3")}
                            onBlur={() => changebackBlur("ipbox-3")}
                            onIonChange={onchangeInputs("mobileNumber")}
                            clear-input="true"
                            type="tel"
                            maxlength={10}
                            placeholder="Enter Mobile No."
                            className="login-input"
                          />
                        </div>
                      </IonItem>

                      <IonItem
                        style={{ border: "solid 2px rgb(209, 209, 209)" }}
                        lines="none"
                        className="input-box"
                        id="ipbox-4"
                      >
                        <FaLock className="login-logo" slot="start" />
                        <div>
                          <IonInput
                            clear-input="true"
                            onFocus={() => changeback("ipbox-4")}
                            onBlur={() => changebackBlur("ipbox-4")}
                            onIonChange={onchangeInputs("password")}
                            type={ShowPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            className="login-input"
                          />
                        </div>
                        <div slot="end">
                          {!ShowPassword ? (
                            <AiFillEyeInvisible
                              onClick={() => {
                                setShowPassword(true);
                              }}
                              className="password-logo"
                            />
                          ) : (
                            <AiFillEye
                              onClick={() => {
                                setShowPassword(false);
                              }}
                              className="password-logo"
                            />
                          )}
                        </div>
                      </IonItem>
                      <div className="ion-text-right forget-text">
                        <Link to="/forget-password">Forget Password ?</Link>
                      </div>

                      <div className="ion-text-right login-button">
                        <IonButton className="btn" expand="full" type="submit">
                          REGISTER
                        </IonButton>
                      </div>
                      <div className="ion-text-center already-text">
                        <p>
                          Don't have an account ?{" "}
                          <Link
                            to={
                              props.match.params.optional === "cart"
                                ? `/login/${"cart"}`
                                : `/login`
                            }
                            className="muted"
                          >
                            Login
                          </Link>{" "}
                        </p>
                      </div>
                    </form>
                  </IonCardContent>
                </IonCard>
              </>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Register;

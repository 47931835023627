import React, { useEffect } from "react";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Switch, Route, withRouter } from "react-router-dom";
// * ---- COMPONENTS ---- * //
import Menu from "../components/Menu";
// * ---- PAGES ---- * //
import Home from "../pages/core";
import Register from "../pages/user/Register";
import Login from "../pages/user/Login";
import ForgetPassword from "../pages/user/ForgetPage";
import ResetLink from "../pages/user/ResetLink";
import Profile from "../pages/user/Profile";

import DetailPackage from "../pages/core/DetailPackage";

import Cart from "../pages/core/Cart";
import SuccessfullOrder from "../pages/core/SuccessfullOrder";
import TermAndCondition from "../pages/core/TermAndCondition";
import AboutUs from "../pages/core/AboutUs";
import ContactUs from "../pages/core/ContactUs";
import EditProfile from "../pages/user/EditProfile";
import SingleOrder from "../pages/user/SingleOrder";
import PrivacyPolicy from "../pages/core/PrivacyPolicy";
import NotFound from "../pages/core/NotFound";
// * ---- ROUTES---- * //
import PrivateRoute from "./PrivateRoute";

const Index = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" component={Home} exact />
            <Route path="/register/:optional?" component={Register} exact />
            <Route path="/login/:optional?" component={Login} exact />
            <Route path="/forget-password" component={ForgetPassword} exact />
            <Route path="/reset-password" component={ResetLink} exact />
            <Route
              exact
              path="/reset-password/:resetPasswordToken"
              component={ResetLink}
            />
            <Route path="/package/:name" component={DetailPackage} exact />
            <Route path="/cart" component={Cart} exact />
            <PrivateRoute path="/profile" component={Profile} exact />
            <PrivateRoute
              path="/successfull-order"
              component={SuccessfullOrder}
              exact
            />
            <PrivateRoute
              path="/update-profile"
              component={EditProfile}
              exact
            />

            <PrivateRoute
              path="/order/:orderId"
              component={SingleOrder}
              exact
            />

            <Route
              path="/terms-and-conditions"
              component={TermAndCondition}
              exact
            />
            <Route path="/about-us" component={AboutUs} exact />
            <Route path="/contact-us" component={ContactUs} exact />
            <Route path="/privacy-policy" component={PrivacyPolicy} exact />

            <Route component={NotFound} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default Index;

import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="nf-box ion-text-center">
          <p className="nf-header">
            Not Found<br></br>404
          </p>
          <AiOutlineIssuesClose className="not-found-logo" />
          <div className="">
            <Link className="nf-btn" to="/">
              GO TO HOME
            </Link>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import { Link } from "react-router-dom";
import React, { useState, useGlobal, useEffect } from "reactn";
import Checkout from "./Checkout";

const Cart = () => {
  const [cartData, setCartData] = useGlobal("cartData");
  const [loading, setLoading] = useGlobal("loading");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" defaultHref="/" />
          </IonButtons>
          <IonTitle>Cart</IonTitle>
        </IonToolbar>
      </IonHeader>

      {!cartData && cartData === null ? (
        <>
          <IonContent>
            <div className="no-cart-page ion-text-center">
              <p>No Package in the Cart. Please add any package.</p>
              <Link to="/">ADD PACKAGE</Link>
            </div>
          </IonContent>
        </>
      ) : (
        <>
          <IonContent>
            <div style={{ background: "#fff", height: "100%" }}>
              <Checkout />
            </div>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default Cart;

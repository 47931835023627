import React, { useState, useGlobal } from "reactn"; // <-- reactn
import { Link, Redirect } from "react-router-dom";
import {
  IonPage,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonItem,
  IonInput,
  IonCardContent,
  IonButton,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
} from "@ionic/react";
import { FaLock, FaMobileAlt } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import {
  ToastWarning,
  ToastSuccess,
  ToastDanger,
} from "../../components/Toaster";
import { userLogin, authenticate } from "./ApisUsers";

const Login = (props) => {
  const [loading, setLoading] = useGlobal("loading");
  const [userData, setuserData] = useGlobal("userData");
  const [values, setValues] = useState({
    mobileNumber: "",
    password: "",
    redirectToReferrer: false,
  });
  const [ShowPassword, setShowPassword] = useState(false);
  const { mobileNumber, password, redirectToReferrer } = values;

  const onchangeInputs = (name) => (event) => {
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  };
  const changeback = (id) => {
    const el = document.getElementById(id);
    el.style.border = "2px #50b6cf solid";
    el.style.borderRadius = "5px";
  };
  const changebackBlur = (id) => {
    const el = document.getElementById(id);
    el.style.border = "2px rgb(209, 209, 209) solid";
    el.style.borderRadius = "5px";
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    userLogin(values).then((data) => {
      if (data.error) {
        ToastDanger(data.error);
        setLoading(false);
      } else {
        authenticate(data, () => {
          setuserData(data);
          ToastSuccess(`${data.user.fullName} has been logged in.`, 4000);
          setValues({
            ...values,
            redirectToReferrer: true,
          });
          setLoading(false);
        });
      }
    });
  };

  const redirectUser = () => {
    if (redirectToReferrer) {
      if (props.match.params.optional === "cart") {
        return <Redirect to="/cart" />;
      } else {
        return <Redirect to="/" />;
      }
    }
    if (userData) {
      return <Redirect to="/" />;
    }
  };

  return (
    <IonPage>
      {redirectUser()}
      <IonToolbar className="default-header">
        <IonButtons slot="start">
          <IonBackButton color="dark" defaultHref="/" />
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Logging you in your account ...."}
        />

        <IonGrid className="login-page">
          <div className="box-1"></div>
          <div className="box-2"></div>
          <div className="box-3"></div>
          <div className="box-4"></div>
          <IonRow className="login-row">
            <IonCol className="login-col">
              <>
                <IonCard className="login-card">
                  <div
                    style={{ paddingTop: "16px" }}
                    className="ion-text-center"
                  >
                    <IonImg
                      style={{ height: "60px" }}
                      src="https://res.cloudinary.com/djnv06fje/image/upload/v1598709680/Copy_of_Copy_of_A1_1_hvhw6m.png"
                    />
                  </div>
                  <IonCardContent>
                    <div className="ion-text-left  login-header">
                      <p>PLEASE LOGIN HERE</p>
                    </div>
                    <form onSubmit={onSubmitForm}>
                      <IonItem
                        style={{ border: "solid 2px rgb(209, 209, 209)" }}
                        lines="none"
                        className="input-box"
                        id="ipbox-3"
                      >
                        <FaMobileAlt className="login-logo" slot="start" />
                        <div>
                          <IonInput
                            onFocus={() => changeback("ipbox-3")}
                            onBlur={() => changebackBlur("ipbox-3")}
                            onIonChange={onchangeInputs("mobileNumber")}
                            clear-input="true"
                            type="tel"
                            maxlength={10}
                            placeholder="Enter Mobile No."
                            className="login-input"
                          />
                        </div>
                      </IonItem>

                      <IonItem
                        style={{ border: "solid 2px rgb(209, 209, 209)" }}
                        lines="none"
                        className="input-box"
                        id="ipbox2"
                      >
                        <FaLock className="login-logo" slot="start" />
                        <div>
                          <IonInput
                            clear-input="true"
                            onFocus={() => changeback("ipbox2")}
                            onBlur={() => changebackBlur("ipbox2")}
                            onIonChange={onchangeInputs("password")}
                            type={ShowPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            className="login-input"
                          />
                        </div>
                        <div slot="end">
                          {!ShowPassword ? (
                            <AiFillEyeInvisible
                              onClick={() => {
                                setShowPassword(true);
                              }}
                              className="password-logo"
                            />
                          ) : (
                            <AiFillEye
                              onClick={() => {
                                setShowPassword(false);
                              }}
                              className="password-logo"
                            />
                          )}
                        </div>
                      </IonItem>
                      <div className="ion-text-right forget-text">
                        <Link to="/forget-password">Forget Password ?</Link>
                      </div>

                      <div className="ion-text-right login-button">
                        <IonButton className="btn" expand="full" type="submit">
                          LOGIN
                        </IonButton>
                      </div>
                      <div className="ion-text-center already-text">
                        <p>
                          Don't have an account ?{" "}
                          <Link
                            to={
                              props.match.params.optional === "cart"
                                ? `/register/${"cart"}`
                                : `/register`
                            }
                            className="muted"
                          >
                            Register
                          </Link>{" "}
                        </p>
                      </div>
                    </form>
                  </IonCardContent>
                </IonCard>
              </>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;

import { API } from "../../Config";

export const singlePackage = (packageName) => {
  return fetch(`${API}/package/${packageName}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getCoupon = ({ code }) => {
  return fetch(`${API}/coupon/${code}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const createPaymentOrder = ({ payAmount, userId, token }) => {
  return fetch(`${API}/create-order-payment/${payAmount}/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const createOrder = (orderData, orderPayId, userId, token) => {
  return fetch(`${API}/create-order/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ orderData, orderPayId }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
export const updateTrackPage = ({ userId, token }, updateArray) => {
  return fetch(`${API}/update-pagetrack-user/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updateArray }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
} from "@ionic/react";
import React from "react";
import Footer from "../../components/Footer";
const ContactUs = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" defaultHref="/" />
          </IonButtons>
          <IonTitle>Contact Us</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="default-page-size">
          <div className="default-inner-box">
            <div className="first-block-support">
              <h4>CUSTOMER CARE & SUPPORT</h4>
              <p>
                We are available for you incase you need any help or have any
                enquiries.
              </p>
              <h3>
                <strong>8800-75-7170</strong>
              </h3>
              <p>Call us anytime during 10AM to 6PM</p>
              <h3>
                <strong>1800-419-7707</strong>
              </h3>
              <p>(All India Toll Free)</p>
              <p>Call us anytime during 10AM to 6PM</p>
              <h3>
                <strong>WhatsApp us at 8800-75-7170</strong>
              </h3>
              <p>
                You can WhatsApp us anytime, If available we will reply or we
                will reply back during the working hours.
              </p>
              <h4>
                <strong>General Information and Customer Enquiries</strong>
              </h4>
              <p>Email us at: info@floorplanbazaar.com</p>
              <h4>
                <strong>For Sales &amp; Orders related queries</strong>
              </h4>
              <p>Email us at: orders@floorplanbazaar.com</p>
              <h4>
                <strong>For working with us, visit the careers page</strong>
              </h4>
              <p>Email your CV &amp; Portfolio at: floorplanbazaar@gmail.com</p>

              <div style={{ marginTop: "35px" }} className="support-address">
                <h3>
                  <u>
                    <strong>HEAD OFFICE</strong>
                  </u>
                </h3>
                <p>NB-Office, Plot No. 202K, Shop No.01, Palam Colony, Near</p>
                <p>Palam Dwarka Metro Station, New Delhi &ndash; 110045</p>
                <p>
                  <strong>Call/WhatsApp: +91-8800757170</strong>
                </p>
                <p style={{ color: "#ed576b", fontSize: "11px" }}>
                  (Scheduled Meetings Only. Please, Contact for Appointment
                  Before Visiting)
                </p>
                <h3>
                  <strong>BACKEND OFFICE</strong>
                </h3>
                <p>
                  N.B Co-Working Space, Plot No. WZ-49A, 4th Floor, Palam
                  Colony,
                </p>
                <p>Near Palam Dwarka Flyover, New Delhi &ndash; 110075</p>
                <p>Mobile No: +91-9891997795</p>
                <p style={{ color: "#ed576b", fontSize: "11px" }}>
                  (No Meetings Available at Location)
                </p>
                <h3>
                  <u>
                    <strong>BRANCH/BACKEND OFFICES IN OTHER STATES</strong>
                  </u>
                </h3>
                <p style={{ color: "#ed576b", fontSize: "11px" }}>
                  (Appointment Only Meetings for Commercial and Township
                  Projects. For all Residential Projects contact us on our All
                  India Toll Free Number: 1800-419-7707 )
                </p>
                <h4>
                  <strong>LUCKNOW</strong>
                </h4>
                <p>N.B Co-Working Space, Gomti Nagar,</p>
                <p>Lucknow, Uttar Pradesh - 226010</p>
                <p>Mobile No: +91-9311687577, 1800-419-7707</p>
                <h4>
                  <strong>PATNA</strong>
                </h4>
                <p>N.B Co-Working Space, Danapur,</p>
                <p>Patna, Bihar - 801503</p>
                <p>Mobile No: +91-9717997787, 1800-419-7707</p>
                <h4>
                  <strong>AHMEDABAD</strong>
                </h4>
                <p>N.B Co-Working Space, Nehru Nagar, Ambawadi,</p>
                <p>Ahmedabad, Gujarat - 380015</p>
                <p>Mobile No: +91-9667175768, 1800-419-7707</p>
                <h4>
                  <strong>PUNE</strong>
                </h4>
                <p>N.B Co-Working Space, Balaji Nagar,</p>
                <p>Dhankawadi, Pune, Maharashtra - 411043</p>
                <p>Mobile No: +91-9953139877, 1800-419-7707</p>
                <h4>
                  <strong>HYDERABAD</strong>
                </h4>
                <p>N.B Co-Working Space, Malakpet Extension,</p>
                <p>Hyderabad, Telangana - 500036</p>
                <p>Mobile No: +91-9717683518, 1800-419-7707</p>
                <h4>
                  <strong>BANGALORE</strong>
                </h4>
                <p>N.B Co-Working Space, Sahakara Nagar,</p>
                <p>Bengaluru, Karnataka - 560092</p>
                <p>Mobile No: +91-9990770127, 1800-419-7707</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ContactUs;

import {
  IonButton,
  IonContent,
  IonImg,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonAvatar,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { Link, withRouter } from "react-router-dom";
import React, { getGlobal, useGlobal, useState, useEffect } from "reactn";
import { createOutline } from "ionicons/icons";
import OrderList from "../pages/user/Orders";
import { orderLists } from "../pages/user/ApisUsers";
import { ToastWarning } from "./Toaster";
import { MdUpdate } from "react-icons/md";
import moment from "moment";
import WebsiteLogo from "../assests/rsz_fpb_-_logo_-_hd_mogljv.png";
const Menu = () => {
  const [user, setUser] = useGlobal("userData");
  const [quote, setQuotes] = useGlobal("quote");
  const [order, setOrder] = useGlobal("orderLists");

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <div className="menu-box">
          <div className="menu-inner-box">
            <div className="w-image">
              <IonImg
                style={{ width: "150px", margin: "0 auto" }}
                src={WebsiteLogo}
              />
            </div>

            <div className="menu-content">
              <p className="ion-text-center quote">
                &ldquo;{quote && quote}&ldquo;
              </p>
              {!getGlobal().userData && (
                <div className="btn-groups">
                  <IonMenuToggle>
                    <Link className="menu-login" to="/login">
                      LOGIN
                    </Link>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <Link className="menu-register" to="/register">
                      REGISTER
                    </Link>
                  </IonMenuToggle>
                </div>
              )}

              {getGlobal().userData && user && (
                <div className="authenticated-block">
                  <div className="user-img-block ion-text-center">
                    {user.user.userPhoto && user.user.userPhoto.url ? (
                      <img
                        className="p-image"
                        src={user.user.userPhoto.url}
                        alt=""
                      />
                    ) : (
                      <img
                        className="p-image"
                        src={`https://ui-avatars.com/api/?name=${user.user.fullName}&background=50b6cf&color=fff&bold=true&rounded=true&size=110`}
                        alt=""
                      />
                    )}
                  </div>

                  <div className="user-details-block">
                    <div className="ion-text-center first-info">
                      <p>
                        Hello !! <span>{user.user.fullName}</span>
                      </p>
                    </div>
                    <hr></hr>
                    <div className="second-info">
                      <p>Register Mobile:</p>
                      <p>{user.user.mobileNumber}</p>
                    </div>
                    <div className="third-info">
                      <p>User Id:</p>
                      <p>{user.user._id}</p>
                    </div>{" "}
                    <div className="third-info">
                      <p>Email:</p>
                      <p>{user.user.email}</p>
                    </div>
                  </div>

                  <div className="btn-block ion-text-right">
                    <IonMenuToggle>
                      <Link className="btn-profile" to="/profile">
                        More
                      </Link>
                    </IonMenuToggle>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="order-listing">
          {order && (
            <>
              <IonGrid>
                <IonRow>
                  {order.map((o, i) => (
                    <IonCol key={i} size={12}>
                      <IonCard style={{ margin: "5px" }} className="o-d-card">
                        <IonCardHeader className="o-d-header">
                          <div className="o-d-flex">
                            <div>
                              <IonAvatar
                                style={{ height: "30px", width: "30px" }}
                              >
                                <img
                                  src={o.purchasedPackage.packageImage.url}
                                  alt={o.purchasedPackage.packageName}
                                />
                              </IonAvatar>
                            </div>

                            <div>
                              <p style={{ fontSize: "11px" }}>
                                {o.purchasedPackage.packageName}
                              </p>
                              <div className="o-d-flex">
                                <div
                                  style={{ fontSize: "9px" }}
                                  className="offer-pill"
                                >
                                  {o.status}
                                </div>
                                <div
                                  style={{ fontSize: "9px" }}
                                  className="variant-pill"
                                >
                                  {o.variantSelected.plotSize} SQ.FT
                                </div>
                              </div>
                            </div>
                          </div>
                        </IonCardHeader>
                        <IonCardContent className="o-d-body">
                          <div className="o-d-inner">
                            <div className="o-d-details">
                              <p style={{ fontSize: "10px" }}>
                                ORDER ID:{" "}
                                <span style={{ fontSize: "10px" }}>
                                  {o._id}
                                </span>
                              </p>
                              <p style={{ fontSize: "10px" }}>
                                PROJECT CODE:{" "}
                                <span style={{ fontSize: "10px" }}>
                                  {o.projectCode}
                                </span>
                              </p>

                              <p style={{ fontSize: "10px" }}>
                                ORDERED DATE:{" "}
                                <span style={{ fontSize: "10px" }}>
                                  {moment(o.createdAt).format("MMM Do YYYY")}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div
                            style={{
                              marginTop: "8px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            className="ion-text-right"
                          >
                            <p
                              style={{
                                margin: "0",
                                fontSize: "10px",
                                fontWeight: "500",
                              }}
                            >
                              <span>
                                <MdUpdate
                                  style={{
                                    fontSize: "16px",
                                    marginBottom: "-4px",
                                    marginRight: "3px",
                                  }}
                                />
                              </span>

                              {moment(o.updatedAt).startOf("hour").fromNow()}
                            </p>
                            <IonMenuToggle>
                              <Link
                                className="btn-o-d-track"
                                to={`/order/${o._id}`}
                                style={{ fontSize: "10px" }}
                              >
                                TRACK LIVE
                              </Link>
                            </IonMenuToggle>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </>
          )}
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
